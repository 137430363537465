import React, { DetailedHTMLProps, FC, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import './InputField.scss';
import { FieldLabel } from './FieldLabel';
import InputNumber from 'rc-input-number';
import { conditionalClass } from './element_utils';
import { Locale } from '../redux/uiStateReducer';
import { useSelector } from 'react-redux';
import { IState } from '../redux/store';

interface InputFieldProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string | React.ReactNode | undefined;
  additionalClass?: string;
  errorMessage?: string;
}

interface InputFieldNumberProps extends CommonInputFieldProps {
  integer?: boolean;
  additionalClass?: string;
  placeholder?: string;
  onChange: (s: number | null) => void;
  value: number | undefined | null;
}

interface InputFieldWrapperProps extends CommonInputFieldProps {
  children: ReactNode;
}

interface CommonInputFieldProps {
  additionalClass?: string;
  label?: string | ReactNode;
  errorMessage?: string;
}

export const AdminInputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props: InputFieldProps, ref) => {
    return (
      <InputFieldWrapper
        additionalClass={props.additionalClass}
        label={props.label}
        errorMessage={props.errorMessage}
      >
        <input
          ref={ref}
          className="input-field__field"
          type={props.type || 'text'}
          {...props}
        />
      </InputFieldWrapper>
    );
  },
);

export function InputFieldWrapper(props: InputFieldWrapperProps) {
  return (
    <div
      className={classNames(
        'input-field' + conditionalClass(props.additionalClass),
      )}
    >
      <FieldLabel>{props.label}</FieldLabel>
      {props.children}
      {props.errorMessage && (
        <div className="input-field__error">{props.errorMessage}</div>
      )}
    </div>
  );
}

export const AdminInputFieldNumber: FC<
  React.PropsWithChildren<InputFieldNumberProps>
> = forwardRef<InputNumber, InputFieldNumberProps>((props, ref) => {
  const locale =
    useSelector<IState, Locale>(s => s.authentication.locale) || Locale.DE;

  return (
    <InputFieldWrapper {...props}>
      <InputNumber
        prefixCls="input-field-number"
        decimalSeparator={locale === Locale.DE ? ',' : '.'}
        precision={props.integer ? 0 : undefined}
        {...props}
      />
    </InputFieldWrapper>
  );
});

export default AdminInputField;

import { ResultsColumnHeader } from './ResultsColumnHeader';
import React, { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConsoleItem } from './ConsoleSelectionItem';
import './ConsoleResultsColumn.scss';

export interface ConsoleResultsColumnProps {
  message?: ReactNode;
  showConsoleSelectionHint: boolean;
  loading: boolean;
  moreProfileDataNeeded: boolean;
  showMoreProfileDataNeededEmptyState?: boolean;
  content?: ReactNode[];
  label: string;
}

export default function ConsoleResults(props: ConsoleResultsColumnProps) {
  return (
    <div className="console-results">
      <div className="console-results__content">
        <ResultsColumnHeader id={props.label} />

        {props.content &&
          props.content.map((content, index) => (
            <div key={index} className="console-results__section">
              {content}
            </div>
          ))}
        {props.message}
        {props.loading && !props.showMoreProfileDataNeededEmptyState && (
          <ConsoleItem name="." selected={false} showSkeleton={true} />
        )}
        {props.showMoreProfileDataNeededEmptyState && (
          <>
            <div className="console-item console-item--empty">
              <div className="console-item__name">.</div>
              <div className="console-item__empty-state-message">
                <FormattedMessage id="PROFILE_COMBINATION_REQUIRED" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import DriveConsoleResults from './ConsoleResultsColumn/DriveConsoleResults';
import DriveConsoleDetails from './ConsoleDetailsColumn/DriveConsoleDetailsColumn';
import './ConsoleSelection.scss';
import { LockingConsoleResults } from './ConsoleResultsColumn/LockingConsoleResults';
import LockingConsoleDetails from './ConsoleDetailsColumn/LockingConsoleDetails';
import { useSelector } from 'react-redux';
import { IState } from '../../redux/store';
import { LockingDrive, TabOptions } from '../../redux/constants';
import classNames from 'classnames';
import { useSelectedDrive } from '../../hooks/selectorHooks';
import { LockingConsole } from '../../redux/admin/adminFacadeReducer';
import { Parameters } from '../Parameters/ParameterValue';

export function ConsoleSelectionColumn() {
  const hasVld = useSelector<IState, boolean>(
    state => state.parameters.lockingDrive.value === LockingDrive.VLD,
  );
  const selectedDrive = useSelectedDrive();
  return (
    <>
      <div
        className={classNames('console-selection', {
          'console-selection--without-locking-console': !hasVld,
          'console-selection--without-drive-console':
            selectedDrive?.includedConsoleSet,
        })}
      >
        {!selectedDrive?.includedConsoleSet && <DriveConsoleArea />}
        <LockingConsoleArea />
      </div>
    </>
  );
}

function DriveConsoleArea() {
  return (
    <div className="drive-console-area">
      <DriveConsoleResults />
      <DriveConsoleDetails />
    </div>
  );
}

function LockingConsoleArea() {
  const hasVld = useSelector<IState, boolean>(
    state => state.parameters.lockingDrive.value === LockingDrive.VLD,
  );
  const highlightedLockingConsole = useSelector<
    IState,
    LockingConsole | undefined
  >(s => s.ui.highlightedLockingConsole);
  const minimumProfileDataEntered = useSelector<IState, boolean>(
    s =>
      (s.parameters.profileSeries.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
        s.parameters.profileSash.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES &&
        s.parameters.profileFrame.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES) ||
      s.parameters.typeOfProfileDataInput.value ===
        TabOptions.OPTION_FREE_ENTRY,
  );

  if (!hasVld) {
    return null;
  }

  return (
    <div className="locking-console-area">
      <LockingConsoleResults />
      <LockingConsoleDetails
        console={
          minimumProfileDataEntered ? highlightedLockingConsole : undefined
        }
      />
    </div>
  );
}

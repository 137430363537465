import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { onEnter } from '../../../lib/utils';
import LoadingSkeleton from '../../../elements/LoadingSkeleton';
import OptionsMenu from '../../OptionsMenu';
import './ConsoleItem.scss';

export function ConsoleItem(props: {
  name: string;
  selected?: boolean;
  onClick?: () => void;
  showSkeleton?: boolean;
  confirmed?: boolean;
  deleteOption?: () => void;
}) {
  return (
    <div
      className={classNames('console-item', {
        'console-item--selected': props.selected,
        'console-item--confirmed': props.confirmed,
      })}
      onClick={props.onClick}
      onKeyDown={onEnter(props.onClick)}
      tabIndex={0}
    >
      {props.showSkeleton && <LoadingSkeleton />}
      {/*<div className="console-item__icon">chevron_right</div>*/}
      <div className="console-item__name" data-qa="first-console">
        {props.name}
      </div>
      {props.confirmed && props.deleteOption && (
        <div className="console-item__options-container">
          <OptionsMenu
            actions={{
              CONSOLE_COLUMN_REMOVE_CONSOLE_BUTTON: () =>
                props.deleteOption?.(),
            }}
          />
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { LockingConsole } from '../../../redux/admin/adminFacadeReducer';
import ConsoleDetails from './ConsoleDetails';
import {
  selectLockingConsole,
  switchPage,
} from '../../../redux/uiStateActions';
import { Pages } from '../../../redux/uiStateReducer';
import {
  useSelectedDrive,
  useSelectedLockingConsole,
} from '../../../hooks/selectorHooks';

function LockingConsoleDetails(props: { console: LockingConsole | undefined }) {
  const selectedLockingConsole = useSelectedLockingConsole();
  const isConsoleSetSelected = useSelector<IState, boolean>(
    s =>
      s.ui.selectedConsoleSets?.length ===
      s.staticData.suitableConsoleSets.length,
  );
  const dispatch = useDispatch();
  const selectedDrive = useSelectedDrive();

  return (
    <ConsoleDetails
      requiredSpace={
        <div>
          <div className="console-details__description-small-headline">
            <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
          </div>
          <div>{props.console?.assemblySpace} mm</div>
          <div className="console-details__description-small-headline">
            <FormattedMessage id="ASSEMBLY" />
            &nbsp;
            <div className="console-details__assembly-message">
              <FormattedMessage id={props.console?.assemblyMessage} />, &nbsp;
            </div>
            <FormattedMessage id={props.console?.assemblyOnFrame} />
          </div>
        </div>
      }
      headline={'LOCKING_CONSOLE_DOCUMENTS'}
      highlightedConsolePresent={!!props.console}
      consoleName={props.console?.name}
      buttonAction={
        !selectedLockingConsole
          ? () => {
              dispatch(selectLockingConsole(props.console!));
              if (isConsoleSetSelected || selectedDrive?.includedConsoleSet) {
                dispatch(switchPage(Pages.SELECTED_PRODUCTS));
              }
            }
          : undefined
      }
      buttonLabel={
        selectedLockingConsole
          ? 'CONSOLE_COLUMN_REMOVE_CONSOLE_BUTTON'
          : 'SELECT_CONSOLE'
      }
      qaPrefix="select-locking-console-button"
    />
  );
}

export default LockingConsoleDetails;

import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SystemDataView from '../general/SystemDataView';
import FacadeFrameProfileDataView from './FacadeFrameProfileDataView';
import FacadeSashProfileDataView from './FacadeSashProfileDataView';
import FacadeBasicProfileDataView from './FacadeBasicProfileDataView';
import FacadeExchangeProfileData from './FacadeExchangeProfileData';
import CVDataView from '../general/CVDataView';
import TestPhasesDataView from './FacadeTestPhases';
import DrivesData from './FacadeDrivesGroupDataView';
import SeriesDataView from '../general/SeriesDataView';
import ConsoleAreaConsolesDataView from './ConsoleAreaConsolesDataView';
import ConsoleAreaConsoleSetsDataView from './ConsoleAreaConsoleSetsDataView';
import ConsoleAreaConsoleSetAssignmentsDataView from './ConsoleAreaConsoleSetAssignmentsDataView';
import { RangeOfApplication } from '../../redux/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditedSeriesENMaxStrokesFacade } from '../../redux/admin/adminFacadeActions';
import { useSeriesENMaxStrokesFacade } from '../../hooks/selectorHooks';
import FacadeMaxStrokesDataView from './FacadeMaxStrokesDataView';
import { IState } from '../../redux/store';
import { LoggedInUser } from '../../redux/authenticationReducer';
import { AnyAction } from 'redux';
import { AdminThunkDispatch } from '../../redux/admin/adminStore';
import AdminNavLink from '../common/AdminNavLink';
import ConsoleAreaLockingConsolesDataView from './ConsoleAreaLockingConsolesDataView';

const TabNavFacade: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const strokes = useSeriesENMaxStrokesFacade();
  return (
    <div className="admin__tab-nav">
      <AdminNavLink to="/admin/facade/systems">Systeme</AdminNavLink>
      <AdminNavLink to="/admin/facade/series">Serien</AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles">Profile</AdminNavLink>
      <AdminNavLink to="/admin/facade/cv">CV-Kurven</AdminNavLink>
      <AdminNavLink to="/admin/facade/test-phases">Prüfphasen</AdminNavLink>
      <AdminNavLink to="/admin/facade/consoles">Konsolen</AdminNavLink>

      <AdminNavLink
        to="/admin/facade/max-strokes"
        onClick={() => dispatch(updateEditedSeriesENMaxStrokesFacade(strokes!))}
      >
        Maximale Hübe
      </AdminNavLink>
    </div>
  );
};

export default function FacadeArea() {
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  if (!currentUser?.admin) {
    return null;
  }

  return (
    <div className="admin__main">
      <TabNavFacade />
      <Routes>
        <Route path="/profiles/*" element={<FacadeProfiles />} />
        <Route path="/consoles/*" element={<FacadeConsoles />} />
      </Routes>
      <Routes>
        <Route path="/systems/*" element={<FacadeSystems />} />
        <Route path="/series/*" element={<FacadeSeries />} />
        <Route
          path="/profiles"
          element={
            <Navigate replace to="/admin/facade/profiles/frame-profiles" />
          }
        />
        <Route path="/cv/*" element={<FacadeCV />} />
        <Route path="/test-phases/*" element={<FacadeTestPhases />} />
        <Route path="/drives/*" element={<FacadeDrives />} />
        <Route
          path="/consoles/"
          element={<Navigate replace to="/admin/facade/consoles/consoles" />}
        />

        <Route path="max-strokes/*" element={<MaxStrokesFacade />} />

        <Route
          path="/consoles/consoles/*"
          element={<FacadeConsoleConsoles />}
        />
        <Route
          path="/consoles/console-sets/*"
          element={<FacadeConsoleSets />}
        />
        <Route
          path="/consoles/locking-consoles/*"
          element={<FacadeLockingConsoles />}
        />
        <Route
          path="/consoles/console-set-assignments/*"
          element={<FacadeConsoleSetAssignments />}
        />

        <Route
          path="/profiles/frame-profiles/*"
          element={<FacadeProfilesFrameProfiles />}
        />
        <Route
          path="/profiles/sash-profiles/*"
          element={<FacadeProfilesSashProfiles />}
        />
        <Route
          path="/profiles/basic-profiles/*"
          element={<FacadeProfilesBasicProfiles />}
        />
        <Route
          path="/profiles/exchange-profiles/*"
          element={<FacadeProfilesExchangeProfiles />}
        />
      </Routes>
    </div>
  );
}

export const FacadeSystems: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <SystemDataView rangeOfApplication={RangeOfApplication.FACADE} />
    </div>
  );
};

const FacadeSeries: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <SeriesDataView rangeOfApplication={RangeOfApplication.FACADE} />
    </div>
  );
};

const MaxStrokesFacade: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <FacadeMaxStrokesDataView />
    </div>
  );
};

const FacadeProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__sub-nav">
      <AdminNavLink to="/admin/facade/profiles/frame-profiles">
        Blendrahmen
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/sash-profiles">
        Flügelprofile
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/basic-profiles">
        Grundprofile
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/exchange-profiles">
        Wechselprofile
      </AdminNavLink>
    </div>
  );
};

const FacadeCV: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <CVDataView rangeOfApplication={RangeOfApplication.FACADE} />
    </div>
  );
};

export const FacadeTestPhases: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <TestPhasesDataView />
    </div>
  );
};

const FacadeDrives: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <DrivesData />
    </div>
  );
};
const FacadeConsoles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__sub-nav">
      <AdminNavLink to="/admin/facade/consoles/consoles">Konsolen</AdminNavLink>
      <AdminNavLink to="/admin/facade/consoles/console-sets">
        Konsolen-Sets
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/consoles/console-set-assignments">
        Konsolen-Set-Zuordnungen
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/consoles/locking-consoles">
        Verriegelungskonsolen
      </AdminNavLink>
    </div>
  );
};

const FacadeProfilesFrameProfiles: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__content">
      <FacadeFrameProfileDataView />
    </div>
  );
};
const FacadeProfilesSashProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <FacadeSashProfileDataView />
    </div>
  );
};

const FacadeProfilesBasicProfiles: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__content">
      <FacadeBasicProfileDataView />
    </div>
  );
};

const FacadeProfilesExchangeProfiles: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__content">
      <FacadeExchangeProfileData />
    </div>
  );
};

const FacadeConsoleConsoles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsolesDataView
        rangeOfApplication={RangeOfApplication.FACADE}
      />
    </div>
  );
};

const FacadeConsoleSets: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsoleSetsDataView
        rangeOfApplication={RangeOfApplication.FACADE}
      />
    </div>
  );
};

const FacadeConsoleSetAssignments: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsoleSetAssignmentsDataView
        rangeOfApplication={RangeOfApplication.FACADE}
      />
    </div>
  );
};

function FacadeLockingConsoles() {
  return (
    <div className="admin__content">
      <ConsoleAreaLockingConsolesDataView
        rangeOfApplication={RangeOfApplication.FACADE}
      />
    </div>
  );
}

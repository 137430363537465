import React, { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoMessage from '../../../elements/InfoMessage';

export function ConsoleRequestMessage(props: {
  helpMessage: string;
}): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className="console-results__empty-state">
      <div className="console-results__advice-container">
        <InfoMessage>
          <FormattedMessage id="CONSOLE_REQUEST_MESSAGE" />
        </InfoMessage>
        <div className="console-results__empty-state-advice">
          <FormattedMessage id="CONTACT_SUPPORT_MESSAGE" />:{' '}
          <a href="mailto:info@dh-partner.com">info@dh-partner.com</a>
        </div>
      </div>

      <div
        className="console-results__empty-state-list"
        dangerouslySetInnerHTML={{
          __html: formatMessage({
            id: props.helpMessage,
          }),
        }}
      ></div>
    </div>
  );
}

import React, { FC, ReactNode } from 'react';
import './FieldLabel.scss';
import classNames from 'classnames';

interface FieldLabelProps {
  children: ReactNode;
  disabled?: boolean;
  additionalClass?: string;
}

export function FieldLabel(props: FieldLabelProps) {
  return (
    <div
      className={classNames('field-label', props.additionalClass, {
        'field-label--disabled': props.disabled,
      })}
    >
      {props.children}
    </div>
  );
}

export default FieldLabel;

import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import './SelectField.scss';
import { FieldLabel } from './FieldLabel';
import '../style/select.scss';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';

export enum MenuPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export type SelectOption<T> = {
  value: T;
  label: string | undefined;
};

export interface SelectFieldProps<T> {
  errorMessage?: string;
  name: string;
  action: (v: SelectOption<T>) => void;
  options: SelectOption<T>[];
  searchable?: boolean;
  placeholder?: string;
  label?: ReactNode;
  displayLabel?: boolean;
  value: SelectOption<T> | null | undefined;
  additionalClass?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  mandatory?: boolean;
}

export const SelectField = <T,>(props: SelectFieldProps<T>): ReactElement => {
  function conditionalClass(
    property: string | undefined,
    prefix?: string,
  ): string {
    return property ? ` ${prefix || ''}${property}` : '';
  }

  return (
    <div
      className={classNames(
        'select-field' + conditionalClass(props.additionalClass),
        {
          'select-field--hide-label': props.hideLabel,
          'select-search-container--menu-top':
            props.menuPlacement === MenuPlacement.TOP,
        },
      )}
    >
      {props.label && (
        <FieldLabel>
          {props.label} {props.mandatory && '*'}
        </FieldLabel>
      )}
      <SelectSearch
        disabled={props.disabled}
        value={JSON.stringify(props.value?.value || '')}
        options={props.options.map(value => ({
          value: JSON.stringify(value.value),
          name: value.label || '',
        }))}
        search={true || props.searchable}
        onChange={v => {
          props.action(
            props.options.find(
              o =>
                JSON.stringify(o.value) ===
                (v as unknown as SelectedOptionValue),
            )!,
          );
        }}
        placeholder={props.placeholder || 'keine Auswahl'}
      />
      {props.errorMessage && (
        <div className="input-field__error">{props.errorMessage}</div>
      )}
    </div>
  );
};

export function selectFieldValue<T>(
  label: string | undefined,
  value: T,
): SelectOption<T> {
  return {
    label: label || '',
    value,
  };
}

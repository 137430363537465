import { Reducer } from 'redux';
import { ErrorResponse } from './httpClient';
import { StaticDataActions } from './staticDataActions';
import { Hint } from './calculationResultReducer';
import { IState } from './store';
import { DriveSeries, DriveType } from './constants';
import { LockingConsole } from './admin/adminFacadeReducer';

export enum DocumentVoltage {
  TWO_HUNDRED_THIRTY_VOLTS = '230V',
  TWENTY_FOUR_VOLTS = '24V',
  ON_24_VOLTS = 'ON (24V)',
  ALL_VOLTAGES = 'ALL_VOLTAGES',
}

export enum DocumentTypes {
  CAD = 'CAD',
  CAD_LEFT = 'CAD_LEFT',
  CAD_RIGHT = 'CAD_RIGHT',
}

export interface Document {
  fileName: string;
  type: string;
  languages?: string[];
  stroke: number;
  voltage: DocumentVoltage;
  url: string;
}

export interface ConsoleSet {
  requiredSpaceSashFrame: number;
  requiredSpaceFrame: number;
  name: string;
  itemNumber: string;
  id: number;
  descriptionKey: string;
  active: boolean;
  hints: Hint[];
  requiresVcdTailPiece: boolean;
}

export interface DefaultConsoleSetValues {
  assemblySpaceFrameProfile: number;
  assemblySpaceSashProfile: number;
}

export interface DocumentsListsResult {
  wpkDocuments: StaticDocument[];
  formDocuments: StaticDocument[];
  certificate: StaticDocument;
  calculationAids: StaticDocument[];
  certifiedAccessory: StaticDocument[];
}

export enum DocumentTypesForStaticDocuments {
  WPK_DOCUMENT = 'WPK_DOCUMENT',
  FORM = 'FORM',
  CERTIFICATE = 'CERTIFICATE',
  CALCULATION_AID = 'CALCULATION_AID',
  CERTIFIED_ACCESSORY = 'CERTIFIED_ACCESSORY',
}

export interface StaticDocument {
  id: number;
  fileName: string;
  descriptionDe: string;
  descriptionEn: string;
  downloadLink: string;
  documentType: DocumentTypesForStaticDocuments;
}

export interface StaticData {
  driveSeries: DriveSeries[];
  driveDocuments: Document[] | undefined;
  defaultConsoleSetValues?: DefaultConsoleSetValues;
  suitableConsoleSets: ConsoleSet[][];
  suitableLockingConsoles: LockingConsole[];
  // suitableConsoleSetsLeft: ConsoleSet[] | undefined;
  // suitableConsoleSetsRight: ConsoleSet[] | undefined;
  consoleDocuments: Document[][] | undefined;
  lockingDriveDocuments: Document[];
  lockingConsoleDocuments: Document[];
  nrwgManualDocuments: Document[];
  consoleNotFoundError?: ErrorResponse;
  nrwgConsoleSetCandidates: ConsoleSet[];
  documentsListsResult: Partial<DocumentsListsResult>;
}

export const INITIAL_DATA_REDUCER_STATE = {
  driveSeries: [],
  driveDocuments: undefined,
  defaultConsoleSet: undefined,
  suitableConsoleSets: [],
  suitableLockingConsoles: [],
  // suitableConsoleSetsRight: undefined,
  // suitableConsoleSetsLeft: undefined,
  consoleDocuments: [],
  lockingDriveDocuments: [],
  lockingConsoleDocuments: [],
  nrwgManualDocuments: [],
  nrwgConsoleSetCandidates: [],
  documentsListsResult: {},
};

const staticDataReducer: Reducer<StaticData | undefined, StaticDataActions> = (
  state,
  action,
): StaticData => {
  if (!state) {
    return INITIAL_DATA_REDUCER_STATE;
  }

  switch (action.type) {
    case 'SET_DOCUMENTS_LISTS_RESULT': {
      return {
        ...state,
        documentsListsResult: action.documentsListsResult,
      };
    }

    case 'RESET_DOCUMENTS': {
      return {
        ...state,
        driveDocuments: [],
        consoleDocuments: [],
        nrwgManualDocuments: [],
      };
    }

    case 'SET_DRIVE_DOCUMENTS': {
      return {
        ...state,
        driveDocuments: action.documents,
      };
    }
    case 'SET_DEFAULT_CONSOLE_SET': {
      return {
        ...state,
        defaultConsoleSetValues: action.defaultConsoleSetValues,
      };
    }
    case 'STORE_DRIVE_SERIES_ACTION': {
      return {
        ...state,
        driveSeries: action.driveSeries,
      };
    }
    case 'STORE_SUITABLE_CONSOLE_SETS': {
      return {
        ...state,
        suitableConsoleSets: action.suitableConsoleSets,
      };
    }
    case 'STORE_SUITABLE_LOCKING_CONSOLES': {
      return {
        ...state,
        suitableLockingConsoles: action.suitableLockingConsoles,
      };
    }
    case 'SET_CONSOLE_DOCUMENTS': {
      return {
        ...state,
        consoleDocuments: action.documents,
      };
    }
    case 'SET_LOCKING_DOCUMENTS': {
      return {
        ...state,
        lockingDriveDocuments: action.documents,
      };
    }

    case 'SET_LOCKING_CONSOLE_DOCUMENTS': {
      return {
        ...state,
        lockingConsoleDocuments: action.documents,
      };
    }
    case 'SET_NRWG_MANUAL_DOCUMENTS': {
      return {
        ...state,
        nrwgManualDocuments: action.documents,
      };
    }
    case 'STORE_NRWG_CONSOLE_SET_CANDICATES': {
      return {
        ...state,
        nrwgConsoleSetCandidates: action.consoleSets,
      };
    }
    default:
      return state;
  }
};

export default staticDataReducer;

export function getDriveTypeNames(s: IState, type: DriveType): string[] {
  return s.staticData.driveSeries
    .filter(driveSeries => driveSeries.driveType === type)
    .map(driveSeries => driveSeries.name);
}

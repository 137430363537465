import { IState } from './store';
import queryString from 'query-string';
import { EMPTY_PARAMETERS_STATE, ParametersState } from './parametersReducer';
import _ from 'lodash';
import ParameterValue, {
  Parameters,
  Value,
} from '../components/Parameters/ParameterValue';

import { BuildingArea, Project, Window } from './projectsReducer';
import { getMarkedDrive, getSelectedWindow } from '../hooks/selectorHooks';
import { showSnackBar } from './globalUiStateActions';

const PUT_WAIT_FOR_FOLLOWING_CALL = 1000;
const GET_WAIT_FOR_FOLLOWING_CALL = 300;

export enum RequestTypes {
  SASH_PROFILES = 'sashProfiles',
  FRAME_PROFILES = 'frameProfiles',
  SYSTEM_SERIES = 'systemSeries',
  EXCHANGE_PROFILES = 'exchangeProfiles',
  BASE_PROFILES = 'baseProfiles',
  DEFAULT_CONSOLE_SET_VALUES = 'consoleSets/defaultValues',
  CONSOLE_SETS = 'consoleSets',
  LOCKING_CONSOLES = 'lockingConsoles',
  CALCULATION = 'calculation',
  GEOMETRIC_DESMOKING = 'geometricDesmoking',
  CREATE_PROJECT = '/projects',
  PROJECTS = 'projects',
  UPDATE_PROJECT = 'projects/{id}',
  NRWG = 'nrwgTestSearchOptions',
  AERODYNAMIC_DESMOKING = 'aerodynamicDesmoking',
  NRWG_RANGES = 'test/ranges',
  NRWG_VALIDATION = 'test/validation',
  SYSTEM = '/api/admin/systems/{id}',
  SERIES = '/api/admin/series/{id}',
  PERFORMANCE_CLASSES = 'performanceClasses',
  NRWG_CONSOLE_SETS = 'consoleSets/nrwg',
  WIND_DEFLECTORS = 'windDeflectors',
  NUMBER_OF_WIND_DEFLECTORS = 'windDeflectors/numberOfWindDeflectors',
  FRAME_PROFILE_FACADE = '/api/admin/frameProfilesFacade/{id}',
  SASH_PROFILE_FACADE = '/api/admin/sashProfilesFacade/{id}',
  FRAME_PROFILE_ROOF = '/api/admin/frameProfilesRoof/{id}',
  SASH_PROFILE_ROOF = '/api/admin/sashProfilesRoof/{id}',
  LOCK_WINDOW = 'projects/{id}/windows/{windowId}/lockWindow',
  BASIC_PROFILE = '/api/admin/basicProfiles/{id}',
  EXCHANGE_PROFILE = '/api/admin/exchangeProfiles/{id}',
  LOGIN = '/login',
  DRIVE_SERIES = '/api/admin/driveSeries/{id}',
  CV_KURVE = '/api/admin/cvKurve/{id}',
  CONSOLES = '/api/admin/consoles/{id}',
  CONSOLE_SET = '/api/admin/consoleSet/{id}',
  CONSOLE_SET_ASSIGNMENT = '/api/admin/consoleSetAssignment/{id}',
  ADMIN_LOCKING_CONSOLES = '/api/admin/lockingConsoles/{id}',
  TEST_PHASES = '/api/admin/testPhases/{id}',
  TEST_PHASES_ROOF = '/api/admin/testPhasesRoof/{id}',
  CONFIG = '/config',
  COMPANIES = '/api/admin/companies/{id}',
  TEMPLATES = 'templates',
  UPDATE_TEMPLATE = 'templates/{id}',
  ADMIN_DRIVES = '/api/admin/drives',
  UPDATE_ADMIN_DRIVES = '/api/admin/drives/{id}',
  DRIVE_FAMILIES = '/api/admin/driveFamilies',
  UPDATE_DRIVE_FAMILY = '/api/admin/driveFamilies/{id}',
  MAX_STROKES_FACADE = '/api/admin/maxStrokesFacade',
  MAX_STROKES_ROOF = '/api/admin/maxStrokesRoof',
  ADMIN_WIND_DEFLECTOR = '/api/admin/windDeflectors',
  DOCUMENTS_LISTS = 'documentsLists',
  PRODUCT_DOCUMENTS = '/productDocuments/{id}',
  DOCUMENTS = 'documents/{id}',
  TRAINING_CERTIFICATE = '/api/admin/companies/{id}/trainingCertificate',
  BLUEKIT_ARTICLE_NUMBER_MAPPINGS = '/api/admin/companies/bluekitArticleNumberMappings',
  SELECT_DRIVE = '/projects/{id}/windows/{windowId}/selectDrive',
  SELECT_WIND_DEFLECTOR = '/projects/{id}/windows/{windowId}/selectWindDeflector',
  DESELECT_WIND_DEFLECTOR = '/projects/{id}/windows/{windowId}/deselectWindDeflector',
  DESELECT_DRIVE = '/projects/{id}/windows/{windowId}/deselectDrive',
  SELECT_CONSOLE_SET = '/projects/{id}/windows/{windowId}/consoleSet',
  SELECT_LOCKING_CONSOLE = '/projects/{id}/windows/{windowId}/lockingConsole',
  // DELETE_WINDOW_CONSOLE_SET = '/projects/{id}/windows/{windowId}/consoleSet',
  UPDATE_ACCEPTED_HINTS = '/projects/{id}/windows/{windowId}/acceptedHints',
  COPY_TEST_PHASE_ROOF = '/api/admin/testPhasesRoof/{id}/copy',
  COPY_TEST_PHASE_FACADE = '/api/admin/testPhases/{id}/copy',
  UPDATE_CALCULATION_PARAMETERS = '/projects/{id}/windows/{windowId}/calculationParameters',
  UPDATE_BUILDING_AREA = '/projects/{id}/buildingAreas/{buildingAreaId}',
  UPDATE_WINDOW = '/projects/{id}/windows/{windowId}',
  MOVE_WINDOW = '/projects/{id}/windows/{windowId}/move',
  CREATE_BUIlDING_AREA = '/projects/{id}/buildingAreas',
  CREATE_WINDOW = '/projects/{id}/buildingAreas/{buildingAreaId}/windows',
  PROJECT = '/projects/{id}',
  WINDOW = '/projects/{projectId}/windows/{id}',
}

export interface ErrorResponse {
  type: string;
  parameters: Record<string, string | number>;
}

export function replaceParameterValues(
  key: string,
  value: any | undefined,
): string | number | null {
  if (value && Object.keys(value).includes('value')) {
    return value.serialize();
  }
  return value;
}

export function wrapCalculationParameters(
  calcParameters: Record<string, number | string | ParameterValue>[],
): void {
  calcParameters.forEach(element => {
    if (!element) {
      return;
    }
    _.keys(EMPTY_PARAMETERS_STATE).forEach(k => {
      element[k] = EMPTY_PARAMETERS_STATE[k].copy(
        element[k] as string | number | null,
      );
    });
  });
}

export function translateServerParameterValuesToFrontendParameterValues(
  project: any,
): Project {
  if (!project.buildingAreas) {
    return project;
  }

  const calcParameters: Record<string, number | string | ParameterValue>[] =
    project.buildingAreas
      .flatMap((b: BuildingArea) => b.windows)
      .flatMap((w: Window) => w.calculationParameters);

  wrapCalculationParameters(calcParameters);

  return project;
}

export function fetchAndStore<T>(
  requestType: RequestTypes,
  updateStore: (result: T) => void,
  getState: () => IState,
  // additionalArgs?: { id?: number | number[]; args?: Record<string, any> },
  additionalArgs?: AdditionalFetchArgs<T>,
): Promise<void> {
  return new Promise(resolve => {
    clearFetchDelayTimer(requestType);

    setFetchDelaytimer(
      requestType,
      window.setTimeout(async () => {
        abortFetch(requestType);
        if (window.AbortController) {
          setAbortController(requestType, new AbortController());
        }
        const abortController = getAbortController(requestType);
        const signal = abortController && abortController?.signal;

        const fetchResult = await fetchFromServer<T>(
          requestType,
          getState(),
          signal,
          additionalArgs,
        );

        if (fetchResult === undefined || typeof fetchResult === 'string') {
          return;
        }

        await updateStore(fetchResult);
        resolve();
      }, GET_WAIT_FOR_FOLLOWING_CALL),
    );
  });
}

interface DeleteProps<T> {
  requestType: RequestTypes;
  id: number | number[];
  handleResult?: (result: T) => void;
  resultTransformCallback?: (fromServer: any) => T;
}

export function handleResponse<T>(
  url: string,
): (r: Response) => Promise<T> | undefined {
  return (r: Response) => {
    if (!r.ok) {
      r.text().then(t => logRequestError(url, r, t));
      return;
    }
    return r.json();
  };
}

export function deleteCall<T>(props: DeleteProps<T>): Promise<void> {
  return standardHeaders().then(headers => {
    const url = injectId(props.requestType, props.id);
    return window
      .fetch(url, {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })
      .then(handleResponse<T>(url))
      .then(result =>
        result && props.resultTransformCallback
          ? props.resultTransformCallback(result)
          : result,
      )
      .then(
        result => props.handleResult && result && props.handleResult(result),
      )
      .catch(e => {
        window.logger.error(e);
        return e;
      });
  });
}

export function adminDeleteCall<T>(props: DeleteProps<T>): Promise<void> {
  return standardHeaders().then(headers => {
    const url = injectId(props.requestType, props.id);
    const r = window.fetch(url, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    });

    if (!r) {
      return;
    }

    return r.then(handleResponse<void>(url)).catch(e => {
      window.logger.error(e);
      return e;
    });
  });
}

type Id = number | undefined | number[];

export async function putAndStoreWithDelay<T>(
  requestType: RequestTypes,
  updateStore: (result: T | undefined) => void,
  args: Record<string, any>,
  id?: Id,
  resultTransformCallback?: (fromServer: any) => T,
  fallbackValue?: T,
): Promise<void> {
  clearFetchDelayTimer(requestType);

  setFetchDelaytimer(
    requestType,
    window.setTimeout(async () => {
      const fetchResult = await putToServer<T>(
        requestType,
        args,
        fallbackValue,
        id,
        resultTransformCallback,
      );

      if (typeof fetchResult === 'string') {
        return;
      }

      updateStore(fetchResult);
    }, PUT_WAIT_FOR_FOLLOWING_CALL),
  );
}

export async function putAndStore<T>(
  requestType: RequestTypes,
  updateStore: (result: T | undefined) => Promise<void>,
  args: Record<string, any>,
  id?: Id,
  resultTransformCallback?: (fromServer: any) => T,
  fallbackValue?: T,
): Promise<void> {
  const fetchResult = await putToServer<T>(
    requestType,
    args,
    fallbackValue,
    id,
    resultTransformCallback,
  );

  if (typeof fetchResult === 'string') {
    return;
  }

  await updateStore(fetchResult);
}

export async function standardHeaders(): Promise<Record<string, string>> {
  const token = await window.keyCloak?.jwtToken();
  return {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  };
}

function triggerSaveFailedSnackbar(): void {
  window.adminDispatch(
    showSnackBar('Speichern fehlgeschlagen', undefined, false),
  );
}

async function putToServer<T>(
  requestType: RequestTypes,
  args: Record<string, any> | undefined,
  fallbackValue: T | undefined,
  id: Id,
  resultTransformCallback?: (fromServer: any) => T,
): Promise<T | undefined> {
  return standardHeaders().then(headers => {
    const url = injectId(requestType, id);
    const r = window.fetch(url, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(args, replaceParameterValues),
    });

    if (!r) {
      return;
    }

    return r
      .then(r => {
        if (r.ok) {
          return r.json().then(j => {
            return resultTransformCallback ? resultTransformCallback(j) : j;
          });
        } else {
          r.text().then(t =>
            window.logger.error(
              'Request Failed: ' + requestType + ' ' + r.status + ' ' + t,
            ),
          );
          if (window.location.href.match(/admin/)) {
            triggerSaveFailedSnackbar();
          }
        }
        return fallbackValue;
      })
      .catch(err => {
        window.logger.error(err);
        return fallbackValue;
      });
  });
}

export async function postAndStore<T>(
  requestType: RequestTypes,
  updateStore: (result: T | undefined) => void,
  args: Record<string, any>,
  id?: Id,
  resultTransformCallback?: (fromServer: any) => T,
  fallbackValue?: T,
  errorHandler?: () => void,
): Promise<void> {
  const fetchResult = await postToServer<T>(
    requestType,
    args || {},
    fallbackValue,
    id,
    resultTransformCallback,
    errorHandler,
  );

  if (typeof fetchResult === 'string') {
    return;
  }
  updateStore(fetchResult);
}

function injectId(
  requestType: RequestTypes,
  id: number | string | undefined | number[],
): string {
  if (!id) {
    return `${requestType}`.replace(/\/\{id\}$/, '');
  }

  if (typeof id === 'object' && id.length) {
    return (id as number[]).reduce(
      (url, idElement) => url.replace(/{.*?}/, idElement.toString()),
      `${requestType}`,
    );
  }
  return `${requestType}`.replace(
    /\{id\}/,
    id?.toString() ? id.toString() : '',
  );
}

async function postToServer<T>(
  requestType: RequestTypes,
  args: Record<string, any> | undefined,
  fallbackValue: T | undefined,
  id: Id,
  resultTransformCallback?: (fromServer: any) => T,
  errorHandler?: () => void,
): Promise<T | undefined> {
  return standardHeaders().then(headers => {
    const url = injectId(requestType, id);
    const r = window.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      method: 'POST',
      body: JSON.stringify(args, replaceParameterValues),
    });

    if (!r) {
      return;
    }

    return r
      .then(r => {
        if (r.ok) {
          return r.json().then(j => {
            return resultTransformCallback ? resultTransformCallback(j) : j;
          });
        } else {
          r.text()
            .then(t => {
              window.logger.error(
                'Request Failed: ' +
                  url +
                  ' ' +
                  r.status +
                  ' ' +
                  ' ' +
                  JSON.stringify(args) +
                  t,
              );
              return t;
            })
            .then(t => {
              return errorHandler && errorHandler();
            });

          if (window.location.href.match(/admin/)) {
            triggerSaveFailedSnackbar();
          }
        }
        return fallbackValue;
      })
      .catch(err => fallbackValue);
  });
}

function clearFetchDelayTimer(requestType: RequestTypes): void {
  if (fetchDelayTimerIds[requestType]) {
    clearTimeout(fetchDelayTimerIds[requestType]);
  }
}

function setFetchDelaytimer(requestType: RequestTypes, id: number): void {
  fetchDelayTimerIds[requestType] = id;
}

const fetchDelayTimerIds: {
  [index: string]: number;
  [RequestTypes.SASH_PROFILES]: number;
  [RequestTypes.SYSTEM_SERIES]: number;
  [RequestTypes.UPDATE_PROJECT]: number;
} = {
  [RequestTypes.SASH_PROFILES]: 0,
  [RequestTypes.SYSTEM_SERIES]: 0,
  [RequestTypes.UPDATE_PROJECT]: 0,
};

const abortControllers: {
  [index: string]: AbortController | undefined;
  [RequestTypes.SASH_PROFILES]: AbortController | undefined;
  [RequestTypes.SYSTEM_SERIES]: AbortController | undefined;
} = {
  [RequestTypes.SASH_PROFILES]: undefined,
  [RequestTypes.SYSTEM_SERIES]: undefined,
};

function abortFetch(requestType: RequestTypes): void {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  abortControllers[requestType]?.abort();
}

function setAbortController(
  requestType: RequestTypes,
  abortController: AbortController,
): void {
  abortControllers[requestType] = abortController;
}

function getAbortController(
  requestType: RequestTypes,
): AbortController | undefined {
  return abortControllers[requestType];
}

export async function fetchWithoutCalculationParameters<T>(
  url: string,
): Promise<T[]> {
  return standardHeaders().then(headers =>
    window
      .fetch(`${url}`, {
        headers: headers,
      })
      .then(handleResponse<T[]>(url))
      .then(r => r || []),
  );
}

function logRequestError(url: string, r: Response, t: string): void {
  if (r.status === 404) {
    return;
  }

  return window.logger.error(
    'Request Failed: ' + url + ' ' + r.status + ' ' + t,
  );
}

export interface AdditionalFetchArgs<T> {
  args?: Record<string, any>;
  fallbackValue?: T;
  errorHandler?: (error: ErrorResponse) => void;
  itemNumber?: string | number;
  id?: number | string | number[];
}

export async function fetchFromServer<T>(
  requestType: RequestTypes,
  state?: IState,
  signal?: AbortSignal | undefined,
  additionalArgs?: AdditionalFetchArgs<T>,
): Promise<T | string> {
  const parameterString = state
    ? `?${calcultationURLParameters(state, additionalArgs?.args)}`
    : '';
  return standardHeaders().then(headers => {
    const url = `${injectId(
      requestType,
      additionalArgs?.id,
    )}${parameterString}`;

    const fr = window.fetch(url, {
      signal,
      headers: headers,
    });

    return fr
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (additionalArgs?.errorHandler) {
          return response
            .json()
            .then(
              error =>
                additionalArgs?.errorHandler &&
                additionalArgs.errorHandler(error),
            );
        } else {
          response.text().then(text => logRequestError(url, response, text));
        }

        return additionalArgs?.fallbackValue;
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          return 'Aborted';
        } else {
          throw err;
        }
      });
  });
}

export function getAdditionalStandardParameters(
  state: IState,
  selectedWindow: Window | undefined,
): Record<string, any> {
  return {
    localization: state.authentication.locale,
    nrwg: !!selectedWindow?.nrwg,
    rwa: !!selectedWindow?.rwa,
    selectedWindowId: selectedWindow?.id,
    selectedProjectId: state.ui.selectedProject?.id,
    locked: !!selectedWindow?.locked,
    windDeflectorType: getSelectedWindow(state)?.windDeflector?.glass
      ? 'glass'
      : 'metalSheet',
    selectedDrive: (getMarkedDrive(state) || selectedWindow?.selectedDrive)
      ?.name,
    selectedDriveId: (getMarkedDrive(state) || selectedWindow?.selectedDrive)
      ?.driveId,
    selectedDriveCount: (getMarkedDrive(state) || selectedWindow?.selectedDrive)
      ?.numberOfDrives,
    selectedConsoleSetIds: (selectedWindow
      ? selectedWindow?.consoleSets
      : state.ui.selectedConsoleSets
    )
      ?.map(c => c.id)
      .join(','),
    lockingConsoleId: selectedWindow
      ? selectedWindow.selectedLockingConsole?.lockingConsoleId
      : state.ui.selectedLockingConsole?.lockingConsoleId,
    alpha: state.ui.alpha,
    // hintConfirmedForWindowWithoutProject: state.ui.hintConfirmed,
  };
}

export function calcultationURLParameters(
  state: IState,
  args?: Record<string, any> | undefined,
): string {
  const selectedWindow = getSelectedWindow(state);
  return queryString.stringify({
    ...prepareParameters(state.parameters),
    ...getAdditionalStandardParameters(state, selectedWindow),
    ...(args || {}),
  });
}

export function prepareParameters(
  parameters: ParametersState,
): Record<string, string | number> {
  const res = _.transform(
    parameters,
    (result: Record<string, Value>, value: ParameterValue, key: string) => {
      if (
        value &&
        value.value !== Parameters.ENTRY_REQUIRED &&
        value.value !== Parameters.NO_SELECTION // &&
        // value.value !== NaN
      ) {
        result[key] = value.value;
      }
    },
    {},
  );

  return res;
}

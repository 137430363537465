import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Project, ShallowProject, ShallowWindow } from './projectsReducer';
import { IState } from './store';
import {
  calculating,
  finishedCalculation,
  resetSelectedWindowCalculation,
  fetchAndSelectedWindow,
  storeSelectedProject,
} from './uiStateActions';
import {
  DEFAULT_STATES,
  EMPTY_PARAMETERS_STATES,
  ParametersState,
} from './parametersReducer';
import {
  deleteCall,
  fetchAndStore,
  getAdditionalStandardParameters,
  postAndStore,
  putAndStore,
  putAndStoreWithDelay,
  RequestTypes,
  translateServerParameterValuesToFrontendParameterValues,
} from './httpClient';
import { getSelectedWindow } from '../hooks/selectorHooks';
import { getNrwgManualDocuments } from './staticDataActions';

export interface UpdateProjects extends Action<'UPDATE_PROJECTS'> {
  type: 'UPDATE_PROJECTS';
  projects: ShallowProject[];
  totalPages: number;
  page: number;
}

export interface UpdateProject extends Action<'UPDATE_PROJECT'> {
  type: 'UPDATE_PROJECT';
  project: Project;
}

export interface UpdateProjectSearchString
  extends Action<'UPDATE_PROJECT_SEARCH_STRING'> {
  searchString: string;
}

export type PROJECT_ACTIONS = UpdateProjects | UpdateProjectSearchString;

export function updateProjects(
  projects: ShallowProject[],
  totalPages: number,
  page: number,
): UpdateProjects {
  return {
    type: 'UPDATE_PROJECTS',
    projects: projects,
    totalPages: totalPages,
    page: page,
  };
}

export function storeProject(project: Project): UpdateProject {
  return {
    type: 'UPDATE_PROJECT',
    project: project,
  };
}

export function createProject(
  newProject: Partial<Project>,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => IState,
  ): Promise<void> => {
    dispatch(calculating(RequestTypes.CREATE_PROJECT));
    postAndStore(
      RequestTypes.CREATE_PROJECT,
      (p: Project | undefined) => {
        if (p) {
          dispatch(getProjects(0));
        }
      },
      { ...newProject, locale: getState().authentication.locale },
      undefined,
    ).finally(() => {
      dispatch(finishedCalculation(RequestTypes.CREATE_PROJECT));
    });
  };
}

export function createBuildingArea(
  name: string,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.CREATE_BUIlDING_AREA));
    postAndStore(
      RequestTypes.CREATE_BUIlDING_AREA,
      (p: Project | undefined) => {
        storeUpdatedProjectData(p, dispatch);
      },
      { name },
      getState().ui.selectedProject?.id,
      translateServerParameterValuesToFrontendParameterValues,
    ).finally(() => {
      dispatch(finishedCalculation(RequestTypes.CREATE_BUIlDING_AREA));
    });
  };
}

export function createWindow(
  name: string,
  quantity: number,
  buildingAreaId: number,
  nrwg: boolean,
  rwa: boolean,
  rangeOfApplication: string,
  parametersOrWindowId: number | ParametersState | undefined,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.CREATE_WINDOW));

    function getCalculationParameters() {
      if (typeof parametersOrWindowId === 'number') {
        return undefined;
      } else if (parametersOrWindowId) {
        return parametersOrWindowId;
      } else {
        return nrwg
          ? { ...EMPTY_PARAMETERS_STATES[rangeOfApplication] }
          : { ...DEFAULT_STATES[rangeOfApplication] };
      }
    }

    const calculationParameters = getCalculationParameters();

    postAndStore(
      RequestTypes.CREATE_WINDOW,
      (p: Project | undefined) => {
        storeUpdatedProjectData(p, dispatch);
      },
      {
        name,
        quantity,
        nrwg,
        rwa,
        rangeOfApplication,
        calculationParameters,
        parameterSourceId:
          typeof parametersOrWindowId === 'number'
            ? parametersOrWindowId
            : undefined,
      },
      [getState().ui.selectedProject!.id, buildingAreaId],
      translateServerParameterValuesToFrontendParameterValues,
    ).finally(() => {
      dispatch(finishedCalculation(RequestTypes.CREATE_WINDOW));
    });
  };
}

export const deleteProject: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = (project: ShallowProject) => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_PROJECT));

    await deleteCall({
      requestType: RequestTypes.UPDATE_PROJECT,
      id: project.id!,
    })
      .then(() => {
        const updatedProjects: ShallowProject[] =
          getState().projects.projects.filter(p => p.id !== project.id!);
        dispatch(
          updateProjects(
            updatedProjects,
            getState().projects.totalPages,
            getState().projects.currentProjectsPage,
          ),
        );
      })
      .finally(() => {
        dispatch(finishedCalculation(RequestTypes.UPDATE_PROJECT));
      });
  };
};

export function updateAcceptedHintsOnServer(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const window = getSelectedWindow(getState())!;
    const project = getState().ui.selectedProject!;

    if (!window) {
      return;
    }

    postAndStore<Project>(
      RequestTypes.UPDATE_ACCEPTED_HINTS,
      project => {
        dispatch(storeProject(project!));
      },
      getState().ui.acceptedHints || [],
      [project.id, window.id!],
      translateServerParameterValuesToFrontendParameterValues,
    );
  };
}

export function saveCalculationParameters(
  parametersState: ParametersState,
  withoutDelay?: boolean,
  window?: ShallowWindow,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_CALCULATION_PARAMETERS));

    const updateAction = withoutDelay ? putAndStore : putAndStoreWithDelay;

    await updateAction(
      RequestTypes.UPDATE_CALCULATION_PARAMETERS,
      async (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
      },
      parametersState,
      [
        getState().ui.selectedProject!.id,
        window?.id || getState().ui.selectedWindow!.id!,
      ],
      translateServerParameterValuesToFrontendParameterValues,
      getState().ui.selectedProject,
    );
  };
}

export function storeUpdatedProjectData(
  updatedProject: Project | undefined,
  dispatch: Dispatch<AnyAction>,
) {
  if (!updatedProject) {
    return;
  }
  dispatch(storeSelectedProject(updatedProject));
  dispatch(finishedCalculation(RequestTypes.UPDATE_PROJECT));
}

export function updateProject(
  id: number,
  name: string,
  description: string,
  country: string,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_PROJECT));

    await putAndStoreWithDelay(
      RequestTypes.UPDATE_PROJECT,
      (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
      },
      { name, description, country },
      [id || getState().ui.selectedProject!.id],
      translateServerParameterValuesToFrontendParameterValues,
      getState().ui.selectedProject,
    );
  };
}

export function updateBuildingArea(
  id: number,
  name: string,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_PROJECT));

    await putAndStoreWithDelay(
      RequestTypes.UPDATE_BUILDING_AREA,
      (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
      },
      { name },
      [getState().ui.selectedProject!.id, id],
      translateServerParameterValuesToFrontendParameterValues,
      getState().ui.selectedProject,
    );
  };
}

export function moveWindow(
  id: number,
  buildingAreaId: number,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.MOVE_WINDOW));

    await postAndStore(
      RequestTypes.MOVE_WINDOW,
      (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
      },
      { buildingAreaId },
      [getState().ui.selectedProject!.id, id],
      translateServerParameterValuesToFrontendParameterValues,
      getState().ui.selectedProject,
    );
  };
}

export function updateWindow(
  window: ShallowWindow,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<IState, any, AnyAction>,
    getState: () => IState,
  ): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_PROJECT));

    return await putAndStore(
      RequestTypes.UPDATE_WINDOW,
      async (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);

        if (getState().ui.selectedWindow?.id === window.id) {
          await dispatch(
            fetchAndSelectedWindow(getState().ui.selectedWindow!.id),
          ).then(() => dispatch(resetSelectedWindowCalculation()));
        }
      },
      window,
      [getState().ui.selectedProject!.id, window.id!],
      translateServerParameterValuesToFrontendParameterValues,
      getState().ui.selectedProject,
    );
  };
}

function deselectCurrentWindowIfDeleted(
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => IState,
) {
  const windowIds = getState()
    .ui.selectedProject!.buildingAreas?.flatMap(b => b.windows)
    .map(w => w.id);
  const selectedWindowId = getState().ui.selectedWindow?.id;
  if (!selectedWindowId || !windowIds?.includes(selectedWindowId)) {
    dispatch(fetchAndSelectedWindow(undefined));
  }
}

export function deleteBuildingArea(
  id: number,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_PROJECT));

    await deleteCall({
      requestType: RequestTypes.UPDATE_BUILDING_AREA,
      id: [getState().ui.selectedProject!.id, id],
      handleResult: (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
        deselectCurrentWindowIfDeleted(dispatch, getState);
      },
      resultTransformCallback:
        translateServerParameterValuesToFrontendParameterValues,
    });
  };
}

export function deleteWindow(
  window: ShallowWindow,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => IState,
  ): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_WINDOW));

    await deleteCall({
      requestType: RequestTypes.UPDATE_WINDOW,
      id: [getState().ui.selectedProject!.id, window.id!],
      handleResult: (updatedProject: Project | undefined) => {
        storeUpdatedProjectData(updatedProject, dispatch);
        if (getState().ui.selectedWindow?.id === window.id) {
          dispatch(fetchAndSelectedWindow(undefined));
        }
      },
      resultTransformCallback:
        translateServerParameterValuesToFrontendParameterValues,
    });
  };
}

export function saveParameterStateInSelectedWindow(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (
    dispatch: ThunkDispatch<IState, any, AnyAction>,
    getState: () => IState,
  ): Promise<void> => {
    await dispatch(saveCalculationParameters(getState().parameters));
  };
}

function getNewDefaultParameterState(
  nrwg: boolean,
  rangeOfApplication: string,
): ParametersState {
  return nrwg
    ? {
        ...EMPTY_PARAMETERS_STATES[rangeOfApplication],
      }
    : DEFAULT_STATES[rangeOfApplication!];
}

export function updateWindowBaseData(
  name: string,
  quantity: number,
  buildingAreaId: number,
  id: number,
  nrwg: boolean,
  rwa: boolean,
  rangeOfApplication: string,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<IState, any, AnyAction>,
    getState: () => IState,
  ): Promise<void> => {
    const currentBuildingArea =
      getState().ui.selectedProject?.buildingAreas?.find(b =>
        b.windows.map(w => w.id).includes(id),
      );
    if (currentBuildingArea?.id !== buildingAreaId) {
      await dispatch(moveWindow(id, buildingAreaId));
    }
    const window = getState()
      .ui.selectedProject?.buildingAreas?.flatMap(b => b.windows)
      .find(w => w.id === id);

    if (!window) {
      throw new Error('Window not found');
    }

    if (window.rangeOfApplication !== rangeOfApplication) {
      const savedCalcParameters = {
        ...getNewDefaultParameterState(nrwg, rangeOfApplication),
      };
      await dispatch(
        saveCalculationParameters(savedCalcParameters, true, window),
      );
    }

    await dispatch(
      updateWindow({
        ...window,
        name: name,
        quantity: quantity,
        nrwg: nrwg,
        rwa: rwa,
      }),
    );

    if (getState().ui.selectedWindow?.id === id) {
      await dispatch(resetSelectedWindowCalculation());
    }
  };
}

export function lockNRWG(
  orderNumber: string,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<IState, any, AnyAction>,
    getState: () => IState,
  ): Promise<void> => {
    const selectedProject = getState().ui.selectedProject!;

    postAndStore(
      RequestTypes.LOCK_WINDOW,
      (project: Project | undefined) => {
        if (getState().ui.selectedProject?.id === project?.id) {
          const selectedProject = getState().ui.selectedProject;
          storeUpdatedProjectData(project, dispatch);
          dispatch(fetchAndSelectedWindow(getState().ui.selectedWindow!.id));
          dispatch(getNrwgManualDocuments());
        }
      },
      {
        ...getState().parameters,
        ...getAdditionalStandardParameters(
          getState(),
          getSelectedWindow(getState()),
        ),
        orderNumber: orderNumber,
      },
      [selectedProject.id!, getState().ui.selectedWindow!.id!],
      translateServerParameterValuesToFrontendParameterValues,
    );
  };
}

export function updateProjectSearchString(
  searchString: string,
): UpdateProjectSearchString {
  return {
    type: 'UPDATE_PROJECT_SEARCH_STRING',
    searchString: searchString,
  };
}

export function updateProjectSearchStringAndFetchProjects(
  searchString: string,
  page: number,
): ThunkAction<Promise<void>, IState, void, UpdateProjectSearchString> {
  return async (
    dispatch: ThunkDispatch<IState, any, UpdateProjectSearchString>,
  ): Promise<void> => {
    dispatch(updateProjectSearchString(searchString));

    dispatch(getProjects(page));
  };
}

export function getProjects(
  page: number,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.PROJECTS));

    fetchAndStore(
      RequestTypes.PROJECTS,
      (ps: { totalPages: number; resultList: ShallowProject[] }) => {
        if (page === 0) {
          dispatch(updateProjects(ps.resultList, ps.totalPages, page));
        } else {
          dispatch(
            updateProjects(
              [...getState().projects.projects, ...ps.resultList],
              ps.totalPages,
              page,
            ),
          );
        }
      },
      getState,
      {
        args: {
          scope: getState().ui.projectScope,
          page: page,
          searchString: getState().projects.searchString,
        },
      },
    ).finally(() => dispatch(finishedCalculation(RequestTypes.PROJECTS)));
  };
}

import React from 'react';
import './ConsoleResultsColumn.scss';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import {
  removePreselectedConsoleSet,
  resetConsoleSets,
  setHighlightedConsoleSet,
} from '../../../redux/uiStateActions';
import '../RecommendationsColumn/RecommendationsColumn.scss';
import ParameterValue, { Parameters } from '../../Parameters/ParameterValue';
import { Calculating } from '../../../redux/uiStateReducer';
import { RequestTypes } from '../../../redux/httpClient';
import {
  AssemblyType,
  RangeOfApplication,
  TabOptions,
} from '../../../redux/constants';
import { AnyAction } from 'redux';
import ConsoleResults from './ConsoleResults';
import { ConsoleItem } from './ConsoleSelectionItem';
import { ConsoleRequestMessage } from './ConsoleRequestMessage';

function DriveConsoleResults() {
  const suitableConsoleSets = useSelector<IState, ConsoleSet[][]>(
    s => s.staticData.suitableConsoleSets,
  );

  const calculating = useSelector<IState, Calculating>(s => s.ui.calculating);
  const typeOfProfileDataInput = useSelector<IState, ParameterValue>(
    s => s.parameters.typeOfProfileDataInput,
  );
  const highlightedConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.highlightedConsoleSet,
  );

  const highlightedConsoleSetConsolesSection = useSelector<IState, number>(
    s => s.ui.highlightedConsoleSetConsolesSection,
  );
  const minimumProfileDataEntered = useSelector<IState, boolean>(
    s =>
      s.parameters.profileSeries.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileSash.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileFrame.value !== Parameters.NO_SELECTION_SYSTEM_SERIES,
  );
  const rangeOfApplicationRoof = useSelector<IState, boolean>(
    s => s.parameters.rangeOfApplication.value === RangeOfApplication.ROOF,
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const preselectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => s.ui.preselectedConsoleSets,
  );
  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => s.ui.selectedConsoleSets,
  );
  const assemblyType = useSelector<IState, ParameterValue>(
    s => s.parameters.assemblyType,
  );

  function profileEntry(): boolean {
    return typeOfProfileDataInput.value === TabOptions.OPTION_PROFILE_ENTRY;
  }

  function loadingConsoleSets(): boolean {
    return calculating[RequestTypes.CONSOLE_SETS];
  }

  function showConsoleOnRequestMessage(): boolean {
    if (loadingConsoleSets()) {
      return false;
    }

    if (profileEntry() || rangeOfApplicationRoof) {
      if (!minimumProfileDataEntered) {
        return false;
      }
    }

    return !suitableConsoleSetsPresent();
  }

  function suitableConsoleSetsPresent(): boolean {
    return suitableConsoleSets.length !== 0; //|| suitableConsoleSetsRight?.length !== 0 || suitableConsoleSetsLeft?.length !== 0;
  }

  function showMoreProfileDataNeededEmptyState(): boolean {
    return (
      (profileEntry() || rangeOfApplicationRoof) && !minimumProfileDataEntered
    );
  }

  return (
    <ConsoleResults
      showConsoleSelectionHint={
        suitableConsoleSetsPresent() ||
        loadingConsoleSets() ||
        showMoreProfileDataNeededEmptyState()
      }
      showMoreProfileDataNeededEmptyState={showMoreProfileDataNeededEmptyState()}
      loading={loadingConsoleSets()}
      moreProfileDataNeeded={showMoreProfileDataNeededEmptyState()}
      message={
        showConsoleOnRequestMessage() && (
          <ConsoleRequestMessage
            helpMessage={
              assemblyType.value === AssemblyType.ASSEMBLY_TYPE_FRAME
                ? 'HELP_TEXT_NO_CONSOLE_FRAME_ASSEMBLY'
                : 'HELP_TEXT_NO_CONSOLE_SASH_ASSEMBLY'
            }
          />
        )
      }
      label="CONSOLE_SELECTION_HEADER"
      content={[
        <div className="console-results__section">
          {!loadingConsoleSets() && suitableConsoleSets.length > 1 && (
            <div className="console-results__sub-title">
              <FormattedMessage id="CONSOLE_RESULTS_DRIVE_ONE" />
            </div>
          )}

          {!showMoreProfileDataNeededEmptyState() &&
            !loadingConsoleSets() &&
            suitableConsoleSets &&
            suitableConsoleSets.length > 0 &&
            suitableConsoleSets[0].map(cs => (
              <ConsoleItem
                key={cs.id}
                name={cs.name}
                selected={
                  cs.id === highlightedConsoleSet?.id &&
                  highlightedConsoleSetConsolesSection === 0
                }
                onClick={() => dispatch(setHighlightedConsoleSet(cs, 0)!)}
                deleteOption={
                  suitableConsoleSets.length > 1
                    ? () => dispatch(removePreselectedConsoleSet(0))
                    : () => dispatch(resetConsoleSets())
                }
                confirmed={
                  (cs.id === preselectedConsoleSets?.[0]?.id &&
                    suitableConsoleSets.length > 1) ||
                  cs.id === selectedConsoleSets?.[0]?.id
                }
              />
            ))}
        </div>,
        <div className="console-results__section">
          {!loadingConsoleSets() && suitableConsoleSets.length > 1 && (
            <div className="console-results__sub-title">
              <FormattedMessage id="CONSOLE_RESULTS_DRIVE_TWO" />
            </div>
          )}
          {!showMoreProfileDataNeededEmptyState() &&
            !loadingConsoleSets() &&
            suitableConsoleSets[1] &&
            suitableConsoleSets[1].map(cs => (
              <ConsoleItem
                key={cs.itemNumber + cs.id}
                name={cs.name}
                selected={
                  cs.id === highlightedConsoleSet?.id &&
                  highlightedConsoleSetConsolesSection === 1
                }
                onClick={() => dispatch(setHighlightedConsoleSet(cs, 1)!)}
                deleteOption={
                  suitableConsoleSets.length > 1
                    ? () => dispatch(removePreselectedConsoleSet(1))
                    : undefined
                }
                confirmed={
                  cs.id === preselectedConsoleSets?.[1]?.id &&
                  suitableConsoleSets.length > 1
                }
              />
            ))}
        </div>,
      ]}
    />
  );
}

export default DriveConsoleResults;

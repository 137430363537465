import React, { FC, useCallback, useState } from 'react';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './CheckBox.scss';
import { conditionalClass } from './element_utils';
import { ThunkDispatch } from 'redux-thunk';
import { AssemblyType } from '../redux/constants';

interface CheckBoxProps {
  id?: string;
  label?: string;
  labelText?: string;
  onClick: () => void;
  checked?: boolean;

  additionalClass?: string;
}

const CheckBox: FC<React.PropsWithChildren<CheckBoxProps>> = (
  props: CheckBoxProps,
) => {
  const [checkBox, setCheckBox] = useState<HTMLInputElement>();
  const [form, setForm] = useState<MDCFormField>();

  const checkBoxRef = useCallback(
    (box: any) => {
      if (!box) {
        return;
      }

      const checkBox = new MDCCheckbox(box);
      setCheckBox(box);

      if (form) {
        form.input = checkBox;
      }
    },
    [form],
  );

  const formRef = useCallback(
    (formElement: any) => {
      if (!formElement) {
        return;
      }
      const localForm = new MDCFormField(formElement);
      setForm(localForm);

      if (checkBox) {
        localForm.input = new MDCCheckbox(checkBox);
      }
    },
    [checkBox],
  );

  return (
    <div
      className={classNames(
        'check-box mdc-form-field' + conditionalClass(props.additionalClass),
      )}
      ref={formRef}
    >
      <div
        className={classNames('mdc-checkbox download-dialog__checkbox', {
          'mdc-checkbox--selected': props.checked,
        })}
        ref={checkBoxRef}
      >
        <input
          type="checkbox"
          className="mdc-checkbox__native-control"
          id={props.id}
          onClick={props.onClick}
          onChange={() => 'prevent lint warning'}
          data-indeterminate={true}
          checked={props.checked}
        />
        <div className="mdc-checkbox__background download-dialog__checkbox-background download-dialog__checkbox-background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              className="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark"></div>
        </div>
        <div className="mdc-checkbox__ripple"></div>
      </div>
      {(props.label || props.labelText) && (
        <label htmlFor={props.id}>
          {props.label ? (
            <FormattedMessage id={props.label} />
          ) : (
            props.labelText
          )}
        </label>
      )}
    </div>
  );
};

export function updateCheckBoxValue<T>(
  updateAction: (v: T[]) => void,
  // isCurrentlySelected: boolean,
  value: T,
  currenctSelectedValues: T[],
) {
  return () => {
    const isCurrentlySelected = currenctSelectedValues.includes(value);
    const values = isCurrentlySelected
      ? currenctSelectedValues.filter(assemblyType => assemblyType !== value)
      : [...currenctSelectedValues, value];
    updateAction(values);
  };
}

export default CheckBox;

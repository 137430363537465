import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import './DriveDetailsColumn.scss';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import {
  Drive,
  ErrorMessage,
  Hint,
} from '../../../redux/calculationResultReducer';
import ToolTip from '../../ToolTip';
import './../../../components/ToolTip.scss';
import { drawDiagramm, setupChart } from '../../../chart/drivePerformance';
import DownloadDialogButton from '../../DownloadDialogButton';
import Footer from '../../Footer/Footer';
import InstallationDiagramDialog from '../../InstallationDiagramDialog';
import { DesmokingState } from '../../../redux/desmokingResultReducer';
import {
  selectDriveAndSaveAction,
  switchPage,
} from '../../../redux/uiStateActions';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { Pages } from '../../../redux/uiStateReducer';
import { ReactComponent as IconDrive } from '../../../assets/icons/IconDrive.svg';
import { ReactComponent as IconForce1 } from '../../../assets/icons/IconForce1.svg';
import { ReactComponent as IconForce2 } from '../../../assets/icons/IconForce2.svg';
import { ReactComponent as IconForceLine } from '../../../assets/icons/IconForceLine.svg';
import { ReactComponent as IconWarningAmber } from '../../../assets/icons/WarningAmberIcon.svg';
import {
  useSelectedConsoleSets,
  useSelectedDrive,
  useSelectedLockingConsole,
  useSelectedWindow,
} from '../../../hooks/selectorHooks';
import { RequestTypes } from '../../../redux/httpClient';
import LoadingSkeleton from '../../../elements/LoadingSkeleton';
import { ValueKey } from '../../../redux/valueKey';
import { ParametersState } from '../../../redux/parametersReducer';
import _ from 'lodash';
import {
  FillType,
  HINT_CONFIRMATION_LABELS,
  LockingDrive,
} from '../../../redux/constants';
import ErrorItem from './ErrorItem';
import { AnyAction } from 'redux';

interface DriveDetailsColumnProps {
  page: Pages;
}

function DriveDetailsColumn(props: DriveDetailsColumnProps) {
  const { formatMessage } = useIntl();
  const selectedDrive = useSelectedDrive()!;
  const isVLD = useSelector<IState, boolean>(
    s => s.parameters.lockingDrive.value === LockingDrive.VLD,
  );
  const selectedLockingConsole = useSelectedLockingConsole();

  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    (s: IState) => s.ui.selectedConsoleSets,
  );

  const [chartElement, setChartElement] = useState<SVGSVGElement | undefined>();
  const invalidParameters = useSelector(
    (state: IState) =>
      !(!state.nrwg.validation || state.nrwg.validation?.valid),
  );
  const selectedWindow = useSelectedWindow();
  const numberOfDrives = selectedDrive?.numberOfDrives;
  const calculatedForces =
    selectedDrive?.driveCalculationResult.calculatedForces;

  const chartRef = useCallback((chart: any) => {
    setupChart(chart);
    setChartElement(chart);
  }, []);

  useEffect(() => {
    if (
      selectedDrive &&
      chartElement &&
      numberOfDrives &&
      calculatedForces &&
      selectedDrive.performance
    ) {
      drawDiagramm(
        chartElement,
        calculatedForces,
        selectedDrive.performance.strokeForceData,
        formatMessage({ id: 'PERFORMANCE_CHART_STROKE_AXIS_DESCRIPTION' }),
        formatMessage({ id: 'PERFORMANCE_CHART_FORCE_AXIS_DESCRIPTION' }),
        selectedDrive.maximumPullPower,
        selectedDrive.maximumStroke,
        numberOfDrives,
        props.page,
      );
    }
  }, [
    calculatedForces,
    selectedDrive,
    formatMessage,
    numberOfDrives,
    props.page,
    chartElement,
  ]);

  if (!selectedDrive || (invalidParameters && !selectedWindow?.locked)) {
    return <div className="drive-details-column" />;
  }

  return (
    <div className="drive-details-column">
      <div className="drive-details-column__content">
        <DrivePerfomance chartRef={chartRef} />
        <div className="drive-details-column__description">
          <DriveDescription />
          <CustomMade {...selectedDrive} />
          <DriveErrors key={selectedDrive.name} />
          <InstallationDiagramDialog />
          {props.page === Pages.CALCULATION ? (
            <SelectDriveButton {...selectedDrive} />
          ) : (
            (((!selectedConsoleSets || selectedConsoleSets.length === 0) &&
              !selectedWindow?.nrwg) ||
              (isVLD && !selectedLockingConsole)) && (
              <DownloadDialogButton
                additionalClassNames="drive-details-column__download-button"
                selectedDrive={selectedDrive}
                showConsoleDocuments={false}
                showWindDeflectorDocuments={false}
              />
            )
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}

const SelectDriveButton: FC<React.PropsWithChildren<Drive>> = (
  props: Drive,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedWindow = useSelectedWindow();
  const consoleNotFoundError = useSelector<IState, boolean>(
    s => !!s.staticData.consoleNotFoundError,
  );
  const calculationResult = useSelector(
    (state: IState) => state.calculationResult,
  );
  const selectedDrive = useSelectedDrive();

  const consoleSetOrDesmokingLoading = useSelector<IState, boolean>(
    s =>
      s.ui.calculating[RequestTypes.NRWG_CONSOLE_SETS] ||
      s.ui.calculating[RequestTypes.AERODYNAMIC_DESMOKING] ||
      s.ui.calculating[RequestTypes.GEOMETRIC_DESMOKING],
  );
  const generalHints = useSelector(
    (state: IState) => state.calculationResult.generalHints,
  );
  const hideSelectDriveButton = !!generalHints?.find(
    (hint: Hint) => hint.hideSelectDriveButton,
  );

  function disableSelectDriveButton(): boolean {
    if (
      calculationResult.suitableDrives.find(d => d === selectedDrive) &&
      selectedWindow?.nrwg
    ) {
      return false;
    }

    return (
      !!(consoleSetOrDesmokingLoading && selectedWindow?.nrwg) ||
      !!(
        selectedWindow?.nrwg &&
        (consoleNotFoundError || props.errors.length > 0)
      ) ||
      hideSelectDriveButton
    );
  }

  return (
    <button
      className={classNames('drive-details-column__description-button', {
        'drive-details-column__description-button--disabled':
          disableSelectDriveButton(),
      })}
      onClick={
        !disableSelectDriveButton()
          ? () => {
              if (selectedWindow) {
                dispatch(selectDriveAndSaveAction());
              }
              selectedWindow?.nrwg
                ? dispatch(switchPage(Pages.SELECTED_PRODUCTS_NRWG))
                : dispatch(switchPage(Pages.SELECTED_PRODUCTS));
            }
          : undefined
      }
      data-qa="select-drive-button"
    >
      <FormattedMessage id="SELECT_DRIVE_BUTTON" />
      <div className="button__icon">add</div>
    </button>
  );
};

const CustomMade: FC<React.PropsWithChildren<Drive>> = (props: Drive) => {
  if (props.isCustomMade) {
    return (
      <div className="drive-details-column__drive-message">
        <div className="drive-details-column__drive-message-label">
          <FormattedMessage id="CUSTOM_MADE_DRIVE_HEADING" />
        </div>
        <div className="drive-details-column__drive-message-text">
          <FormattedMessage id="CUSTOM_MADE_DRIVE_TEXT" />
        </div>
      </div>
    );
  }
  if (props.isVentilation) {
    return (
      <div className="drive-details-column__drive-message">
        <div className="drive-details-column__drive-message-label">
          <FormattedMessage id="VENTILATION_DRIVE_HEADING" />
        </div>
        <div className="drive-details-column__drive-message-text">
          <FormattedMessage id="VENTILATION_DRIVE_TEXT" />
        </div>
      </div>
    );
  }
  return null;
};

const DriveErrors: FC<React.PropsWithChildren<unknown>> = () => {
  const [errorsExpanded, setErrorsExpanded] = useState(false);
  const { formatMessage } = useIntl();
  const generalHints = useSelector(
    (state: IState) => state.calculationResult.generalHints,
  );
  const selectedDrive = useSelectedDrive()!;
  const selectedConsoleSets = useSelectedConsoleSets();
  const driveHints = selectedDrive.hints;

  const consoleHints = useSelector((state: IState) =>
    _.uniqBy(
      selectedConsoleSets?.flatMap(consoleSet => consoleSet.hints) || [],
      (hint: Hint) => hint.type,
    ),
  );
  const activePage = useSelector((state: IState) => state.ui.activePage);

  function getHints(): Hint[] {
    return [...driveHints, ...consoleHints];
  }

  if (
    !selectedDrive.errors.length &&
    generalHints === null &&
    selectedDrive.active
  ) {
    return null;
  }

  interface Error {
    hint?: Hint;
    key: string;
    text: string;
  }

  function formatError(errorMessage: ErrorMessage): Error {
    if (errorMessage.id) {
      return {
        key: errorMessage.id,
        text: formatMessage({ id: errorMessage.id }, errorMessage.parameters!),
      };
    } else {
      return {
        key: errorMessage.legacyTranslatedMessage!,
        text: errorMessage.legacyTranslatedMessage!,
      };
    }
  }

  function allHints(): Error[] {
    const errors: Error[] = selectedDrive.errors.map(formatError);

    if (!selectedDrive.active) {
      errors.push({
        key: 'DRIVE_NOT_SOLD_ANY_MORE',
        text: formatMessage({ id: 'DRIVE_NOT_SOLD_ANY_MORE' }),
      });
    }

    getHints().forEach(hint => {
      if (hint.hideOnCalculationPage && activePage === Pages.CALCULATION) {
        return;
      }
      errors.unshift({
        key: hint.type,
        text: formatMessage({ id: hint.type }, hint.parameters),
        hint: hint,
      });
    });

    if (generalHints) {
      generalHints.forEach(hint => {
        if (hint.acceptable) {
          errors.unshift({
            key: hint.type,
            text: formatMessage({ id: hint.type }, hint.parameters),
            hint: hint,
          });
        } else {
          errors.push({
            key: hint.type,
            text: formatMessage({ id: hint.type }, hint.parameters),
          });
        }
      });
    }

    return _.uniq(errors);
  }

  return (
    <div
      className={classNames('drive-details-column__description-errors', {
        'drive-details-column__description-errors--expanded': errorsExpanded,
      })}
    >
      <div className="drive-details-column__description-errors-head-area">
        {allHints().length >= 1 && (
          <div className="drive-details-column__description-errors-headline">
            <div className="drive-details-column__description-errors-icon">
              <IconWarningAmber />
            </div>
            <FormattedMessage id="ERROR_MESSAGES" />
          </div>
        )}
        {errorsExpanded && (
          <button
            className="drive-details-column__description-errors-close-button"
            onClick={() => setErrorsExpanded(false)}
            type="button"
          >
            <FormattedMessage id="ERRORS_LESS" />
          </button>
        )}{' '}
      </div>
      {!errorsExpanded && allHints().length > 1 ? (
        <div
          onClick={() => {
            setErrorsExpanded(true);
          }}
          className={classNames('drive-details-column__errors', {
            'drive-details-column__errors--many': allHints().length > 1,
          })}
        >
          {' '}
          <div className="drive-details-column__number-remaining-errors">
            + {allHints().length - 1}
          </div>
          <div className="drive-details-column__errors-more-items"></div>
          <ErrorItem
            key={allHints()[0].key}
            hint={allHints()[0].hint}
            message={allHints()[0].text}
            labels={HINT_CONFIRMATION_LABELS}
          />
        </div>
      ) : (
        <div className="drive-details-column__errors">
          {allHints().map(message => (
            <ErrorItem
              key={message.key}
              hint={message.hint}
              message={message.text}
              labels={HINT_CONFIRMATION_LABELS}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DriveDescription: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedDrive = useSelectedDrive()!;
  const lockingDriveInformation = selectedDrive.lockingDriveInformation;
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="drive-details-column__description-heading">
        <div className="drive-details-column__description-small-headline drive-details-column__title-byline">
          <div className="drive-details-column__title-byline-property">
            <FormattedMessage id={selectedDrive.type} />
          </div>
          <div className="drive-details-column__title-byline-property">
            <FormattedMessage id={selectedDrive.voltage} />
          </div>
          <div className="drive-details-column__title-byline-property">
            {selectedDrive.numberOfDrives}{' '}
            <FormattedMessage id="PIECES_ABBREVIATION" />
          </div>
        </div>
        <div className="drive-details-column__description-name">
          {selectedDrive.name}
        </div>
      </div>
      {lockingDriveInformation && (
        <LockingDriveInformation
          lockingDrive={lockingDriveInformation.articleDrive}
          kindOfLockingDrive={lockingDriveInformation.lockingDriveType}
        />
      )}
      <div
        className="drive-details-column__description-text"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: selectedDrive.descriptionKey }),
        }}
      />
    </>
  );
};

interface LockingDriveInformationProps {
  kindOfLockingDrive: string;
  lockingDrive: string;
}

function LockingDriveInformation(props: LockingDriveInformationProps) {
  return (
    <div className="locking-drive-information">
      <div className="locking-drive-information__heading">
        <FormattedMessage id={props.kindOfLockingDrive} />
      </div>
      <div className="locking-drive-information__value">
        {props.lockingDrive}
      </div>
    </div>
  );
}

interface DrivePerfomanceProps {
  chartRef: (svg: SVGSVGElement) => void;
}

const DrivePerfomance: FC<React.PropsWithChildren<DrivePerfomanceProps>> = (
  props: DrivePerfomanceProps,
) => {
  const { formatNumber } = useIntl();
  const selectedDrive = useSelectedDrive()!;
  const parameters = useSelector<IState, ParametersState>(
    state => state.parameters,
  );
  const desmokingData = useSelector<IState, DesmokingState>(s => s.desmoking);
  const selectedWindow = useSelectedWindow();
  const consoleSetOrDesmokingLoading = useSelector<IState, boolean>(
    s =>
      s.ui.calculating[RequestTypes.NRWG_CONSOLE_SETS] ||
      s.ui.calculating[RequestTypes.AERODYNAMIC_DESMOKING] ||
      s.ui.calculating[RequestTypes.GEOMETRIC_DESMOKING],
  );

  const performance = selectedDrive.performance;
  const driveCalculationResult = selectedDrive.driveCalculationResult;

  function getCalculatedOpeningAngle(): number | undefined {
    return selectedDrive?.realOpeningAngle;
  }

  function strokeIsSelected(): boolean {
    return parameters.openingOption.value === ValueKey.VALUEKEY_OPENING_STROKE;
  }

  function getAerodynamicArea(): number {
    if (selectedWindow?.locked) {
      return selectedWindow.aerodynamicDesmokingResult?.aerodynamicArea || 0;
    }

    return desmokingData.aerodynamicDesmoking?.aerodynamicArea || 0;
  }

  function getGeometricArea(): number {
    if (selectedWindow?.locked) {
      return selectedWindow.geometricDesmokingResult?.geometricArea || 0;
    }
    return desmokingData.geometricDesmoking?.geometricArea || 0;
  }

  function getOriginalGeometricArea(): number {
    if (selectedWindow?.locked) {
      return (
        selectedWindow.geometricDesmokingResult?.originalGeometricArea || 0
      );
    }
    return desmokingData.geometricDesmoking?.originalGeometricArea || 0;
  }

  function getMaxGeometricArea(): number {
    if (selectedWindow?.locked) {
      return (
        selectedWindow.geometricDesmokingResult?.maxGeometricArea ||
        selectedWindow.aerodynamicDesmokingResult?.maxGeometricArea ||
        0
      );
    }

    return (
      desmokingData.geometricDesmoking?.maxGeometricArea ||
      desmokingData.aerodynamicDesmoking?.maxGeometricArea ||
      0
    );
  }

  function withCorrectionFactor(): boolean {
    if (parameters.correctionFactor.value! === 0) {
      return false;
    }
    return +parameters.correctionFactor.value! < 1;
  }

  const toolTipContentForCorrectionFactor: ReactNode = (
    <div className="tooltip-text-box">
      <span className="tooltip-text-box__row-cell">
        <FormattedMessage id="TOOLTIP_BOX_GEOM_AREA_WITHOUT_CORRECTION_FACTOR" />
      </span>
      <span className="tooltip-text-box__row-cell">
        {formatNumber(getOriginalGeometricArea())}
      </span>

      <span className="tooltip-text-box__row-cell">
        <FormattedMessage id="TOOLTIP_BOX_CORRECTION_FACTOR" />
      </span>
      <span className="tooltip-text-box__row-cell">
        {parameters.correctionFactor.value}
      </span>

      <span className="tooltip-text-box__row-cell tooltip-text-box__row-cell--result-cell ">
        <FormattedMessage id="TOOLTIP_BOX_RESULT" />
      </span>
      <span className="tooltip-text-box__row-cell tooltip-text-box__row-cell--result-cell ">
        {formatNumber(getGeometricArea())}
      </span>
    </div>
  );

  return (
    <div className="drive-details-column__drive-values-card">
      <div className="drive-details-column__drive-values-card-graph">
        <svg ref={props.chartRef} />
      </div>
      <div className="drive-details-column__drive-values-card-text">
        {driveCalculationResult.maximumRequiredPushPower !== 0 && (
          <div className="drive-details-column__drive-values-headline">
            <IconForce1 />
            <FormattedMessage id="DIAGRAMM_DESCRIPTION_REQUIRED_FORCE_OF_PRESSURE" />
          </div>
        )}
        {driveCalculationResult.maximumRequiredPullPower !== 0 && (
          <div className="drive-details-column__drive-values-headline">
            <IconForce2 />
            <FormattedMessage id="DIAGRAMM_DESCRIPTION_REQUIRED_TENSILE_FORCE" />
          </div>
        )}
        <div className="drive-details-column__drive-values-headline">
          <IconForceLine />
          <FormattedMessage id="DIAGRAMM_DESCRIPTION_REQUIRED_FORCE" />
        </div>

        <div className="drive-details-column__drive-values-headline drive-details-column__drive-values-headline--drive">
          <div className="drive-details-column__headline">
            <div className="drive-details-column__headline-icon">
              <IconDrive />
            </div>

            {selectedDrive?.numberOfDrives === 1 ? (
              <>
                <span>
                  <FormattedMessage id="DRIVE_PERFORMANCE_HEADING_SINGULAR" />
                </span>
                <FormattedMessage id="NEEDED" />
              </>
            ) : (
              <>
                <span>
                  <FormattedMessage id="DRIVE_PERFORMANCE_HEADING_PLURAL" />
                </span>
                <FormattedMessage id="NEEDED" />
              </>
            )}
          </div>
        </div>
        <div className="drive-details-column__drive-values-group-performance-values">
          <div className="drive-details-column__drive-values-border"></div>
          <div className="drive-details-column__drive-value-1">
            {driveCalculationResult.maximumRequiredPushPower !== 0 && (
              <PerformanceItem
                description="PUSH_FORCE_WITHOUT_UNIT"
                driveValue={performance?.pushForce || 0}
                unit="N"
                requiredValueExcess={performance?.pushForceRequired || 0}
                qaId="push-force"
              />
            )}
          </div>
          <div className="drive-details-column__drive-value-2">
            {driveCalculationResult.maximumRequiredPullPower !== 0 && (
              <PerformanceItem
                description="PULL_FORCE_WITHOUT_UNIT"
                driveValue={performance?.pullForce || 0}
                unit="N"
                requiredValueExcess={performance?.pullForceRequired || 0}
                qaId="pull-force"
              />
            )}
          </div>
          <div className="drive-details-column__drive-value-3">
            <PerformanceItem
              description="STROKE_WITHOUT_UNIT"
              driveValue={performance?.stroke || 0}
              unit="mm"
              requiredValueExcess={performance?.strokeRequired || 0}
              qaId="stroke"
            />
          </div>
          <div className="drive-details-column__drive-value-4">
            <PerformanceItem
              description="HOLDING_FORCE"
              driveValue={performance?.holdingForce || 0}
              unit="N"
              requiredValueExcess={performance?.holdingForceRequired || 0}
              qaId="holding-force"
            />
          </div>
          <div className="drive-details-column__drive-value-5">
            {performance?.lockingForceRequired ? (
              <PerformanceItem
                description="LOCKING_FORCE"
                driveValue={performance.lockingForce! || 0}
                unit="N"
                requiredValueExcess={performance.lockingForceRequired || 0}
                qaId="locking-force"
              />
            ) : (
              <div className="drive-details-column__drive-values-hint">
                {performance?.lockingForceHint && (
                  <FormattedMessage id={performance?.lockingForceHint} />
                )}
              </div>
            )}
          </div>
        </div>
        {consoleSetOrDesmokingLoading && (
          <dl className="drive-details-column__drive-values-group">
            <div className="drive-details-column__drive-values">
              <dt className="drive-details-column__drive-values-name">
                <FormattedMessage id="MAXIMUM_GEOMETRIC_AREA" />
              </dt>

              <dd className="drive-details-column__drive-values-data">
                <span className="drive-details-column__drive-values-data-loading">
                  1,597 m²
                  <LoadingSkeleton />
                </span>
              </dd>
            </div>
            {(selectedWindow?.rwa || !selectedWindow) && (
              <div className="drive-details-column__drive-values">
                <dt className="drive-details-column__drive-values-name">
                  <FormattedMessage id="CALCULATED_GEOMETRIC_AREA" />
                </dt>
                <dd className="drive-details-column__drive-values-data">
                  <span className="drive-details-column__drive-values-data-loading">
                    1,597 m²
                    <LoadingSkeleton />
                  </span>
                </dd>
              </div>
            )}
            {selectedWindow?.nrwg && (
              <div className="drive-details-column__drive-values">
                <dt className="drive-details-column__drive-values-name">
                  <FormattedMessage id="CALCULATED_AERODYNAMIC_AREA" />
                </dt>
                <dd className="drive-details-column__drive-values-data">
                  <span className="drive-details-column__drive-values-data-loading">
                    1,597 m²
                    <LoadingSkeleton />
                  </span>
                </dd>
              </div>
            )}
          </dl>
        )}
        {(desmokingData.geometricDesmoking ||
          desmokingData.aerodynamicDesmoking ||
          selectedWindow?.rwa ||
          selectedWindow?.nrwg ||
          strokeIsSelected()) &&
          !consoleSetOrDesmokingLoading && (
            <dl className="drive-details-column__drive-values-group">
              {(desmokingData.geometricDesmoking?.geometricArea ||
                desmokingData.aerodynamicDesmoking?.maxGeometricArea ||
                selectedWindow?.rwa ||
                selectedWindow?.nrwg) && (
                <div className="drive-details-column__drive-values">
                  <dt className="drive-details-column__drive-values-name">
                    <FormattedMessage id="MAXIMUM_GEOMETRIC_AREA" />
                  </dt>
                  <dd
                    className="drive-details-column__drive-values-data"
                    data-qa="max-geometric-area"
                  >
                    {formatNumber(getMaxGeometricArea())} m²
                  </dd>
                </div>
              )}
              {(desmokingData?.geometricDesmoking?.geometricArea ||
                selectedWindow?.rwa) && (
                <>
                  <div className="drive-details-column__drive-values">
                    <dt className="drive-details-column__drive-values-name">
                      <FormattedMessage id="CALCULATED_GEOMETRIC_AREA" />
                    </dt>{' '}
                    <dd className="drive-details-column__drive-values-data">
                      {withCorrectionFactor() ? (
                        <ToolTip
                          text={toolTipContentForCorrectionFactor}
                          additionalClass="tooltip__text--position-right"
                        >
                          <div className="drive-details-column__drive-value-data--with-correction-factor">
                            {formatNumber(getGeometricArea())} m²
                          </div>
                        </ToolTip>
                      ) : (
                        <div data-qa="geometric-area">
                          {formatNumber(getGeometricArea())} m²
                        </div>
                      )}
                    </dd>
                  </div>
                </>
              )}

              {(desmokingData.aerodynamicDesmoking?.aerodynamicArea !==
                undefined ||
                selectedWindow?.nrwg) && (
                <>
                  <div className="drive-details-column__drive-values">
                    <dt className="drive-details-column__drive-values-name">
                      <FormattedMessage id="CALCULATED_AERODYNAMIC_AREA" />
                    </dt>{' '}
                    <dd
                      className="drive-details-column__drive-values-data"
                      data-qa="aerodynamic-area"
                    >
                      {formatNumber(getAerodynamicArea())} m²
                    </dd>{' '}
                  </div>
                </>
              )}
              {strokeIsSelected() && (
                <div className="drive-details-column__drive-values">
                  <dt className="drive-details-column__drive-values-name">
                    <FormattedMessage id="CALCULATED_OPENING_ANGLE" />
                  </dt>{' '}
                  <dd
                    className="drive-details-column__drive-values-data"
                    data-qa="real-opening-angle"
                  >
                    {getCalculatedOpeningAngle()} ˚
                  </dd>
                </div>
              )}
              {parameters.weightOption.value !==
                ValueKey.VALUEKEY_SASH_WEIGHT && (
                <div className="drive-details-column__drive-values">
                  <dt className="drive-details-column__drive-values-name">
                    <FormattedMessage id="SASHWEIGHT" />
                  </dt>{' '}
                  <dd
                    className="drive-details-column__drive-values-data"
                    data-qa="sash-weight"
                  >
                    {Math.round(selectedDrive.sashWeight)} kg
                  </dd>
                </div>
              )}
              {parameters[ValueKey.VALUEKEY_SANDWICH_ELEMENT].value !==
                FillType.SANDWICH_FILL &&
                parameters.weightOption.value !==
                  ValueKey.VALUEKEY_GLAS_THICKNESS && (
                  <div className="drive-details-column__drive-values">
                    <dt className="drive-details-column__drive-values-name">
                      <FormattedMessage id="THICKNESS" />
                    </dt>{' '}
                    <dd
                      className="drive-details-column__drive-values-data"
                      data-qa="glass-thickness"
                    >
                      {Math.round(selectedDrive.glassThickness)} mm
                    </dd>
                  </div>
                )}
            </dl>
          )}
      </div>
    </div>
  );
};

interface PerformanceItemProps {
  qaId: string;
  description: string;
  driveValue: number;
  requiredValueExcess: number;
  unit: string;
}

const PerformanceItem: FC<React.PropsWithChildren<PerformanceItemProps>> = (
  props: PerformanceItemProps,
) => {
  function formatExcessValue(
    value: number,
    unit: string,
    drivePerfomance: number,
  ): React.ReactNode {
    return (
      <div
        className={classNames('value', {
          'value--valid': value <= drivePerfomance,
          'value--invalid': value > drivePerfomance,
        })}
        data-qa={props.qaId}
      >
        {value} {props.unit}
      </div>
    );
  }

  return (
    <div className="drive-details-column__drive-values">
      <dt className="drive-details-column__drive-values-name">
        <FormattedMessage id={props.description} />
      </dt>
      <span className="drive-details-column__drive-values-data">
        {props.driveValue} {props.unit}
      </span>
      <span className="drive-details-column__drive-values-data-calculation">
        {formatExcessValue(
          props.requiredValueExcess,
          props.unit,
          props.driveValue,
        )}
      </span>
    </div>
  );
};

export default DriveDetailsColumn;

import { FormattedMessage, useIntl } from 'react-intl';
import React, { ReactNode } from 'react';
import { Hint } from '../../../redux/calculationResultReducer';
import ErrorItem from '../DriveDetailsColumn/ErrorItem';
import { HINT_CONFIRMATION_LABELS } from '../../../redux/constants';
import { ReactComponent as IconWarningAmber } from '../../../assets/icons/WarningAmberIcon.svg';
import './ConsoleDetails.scss';

interface ConsoleDetailsProps {
  buttonAction: (() => void) | undefined;
  requiredSpace: ReactNode;
  headline: string;
  highlightedConsolePresent: boolean;
  consoleName: string | undefined;
  descriptionKey?: string;
  hints?: Hint[];
  buttonLabel?: string;
  primaryButton?: ReactNode;
  qaPrefix?: string;
}

export default function ConsoleDetails(props: ConsoleDetailsProps) {
  const { formatMessage } = useIntl();

  return (
    <div className="console-details">
      {props.highlightedConsolePresent && (
        <>
          <div className="console-details__description-title">
            <div className="console-details__description-small-headline console-details__title-byline">
              <FormattedMessage id={props.headline} />
            </div>
            <div className="console-details__description-name">
              {props.consoleName}
            </div>
          </div>
          <div className="console-details__description-text-container">
            {props.descriptionKey && (
              <div
                className="console-details__description-text"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: props.descriptionKey,
                  }),
                }}
              />
            )}
            {props.requiredSpace}

            <div className="console-details__description-errors">
              {(props.hints?.length || 0) >= 1 && (
                <div className="drive-details-column__description-errors-headline">
                  <div className="drive-details-column__description-errors-icon">
                    <IconWarningAmber />
                  </div>
                  <FormattedMessage id="ERROR_MESSAGES" />
                </div>
              )}

              <div className="drive-details-column__errors">
                {props.hints?.map(hint => (
                  <ErrorItem
                    key={hint.type}
                    hint={hint}
                    message={formatMessage({ id: hint.type }, hint.parameters)}
                    labels={HINT_CONFIRMATION_LABELS}
                  />
                ))}
              </div>
            </div>
            {props.primaryButton ||
              (props.buttonAction && (
                <button
                  className="console-details__button"
                  onClick={props.buttonAction}
                  data-qa={props.qaPrefix}
                >
                  <FormattedMessage id={props.buttonLabel} />
                  <div className="button__icon">add</div>
                </button>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

import React, { ChangeEvent, ReactElement } from 'react';
import readXlsxFile from 'read-excel-file';
import { Cell } from 'read-excel-file/types';
import TextArea from './TextArea';

export function CSVInput<T>(props: {
  disabled: boolean;
  value: string;
  onChange: (s: T[]) => void;
  arrayToObject: (values: number[]) => T;
}): ReactElement {
  function readCVS(e: ChangeEvent<HTMLInputElement>): void {
    if (!e.target.files) {
      return;
    }
    readXlsxFile(e.target.files[0]).then(rows => {
      const cvValues: T[] = [];
      rows.forEach(row => {
        if (isNumberOrStringNumber(row[0]) && isNumberOrStringNumber(row[1])) {
          cvValues.push(props.arrayToObject(row.map(el => +el)));
        }
      });
      props.onChange(cvValues);
    });
    e.target.value = '';
  }

  function isNumberOrStringNumber(value: Cell): boolean {
    if (typeof value === 'number') {
      return true;
    }

    if (typeof value === 'string' && !isNaN(+value)) {
      return true;
    }

    return false;
  }

  return (
    <div className="cv-data-dialog__values-content">
      <label
        htmlFor="cvs-file-upload"
        className="button button--frame cv-data-dialog__file-upload"
      >
        Excel-Datei hochladen
      </label>
      <input
        onChange={readCVS}
        type="file"
        id="cvs-file-upload"
        className="cv-data-dialog__input"
      />
      <TextArea
        disabled={props.disabled}
        label=""
        placeholder="Grad - Durchflusswert"
        value={props.value}
      />
    </div>
  );
}

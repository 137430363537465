import React, { FC, ReactElement, useState } from 'react';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import './GeneralDriveFamiliesDataView.scss';
import { SelectField, selectFieldValue } from '../../elements/SelectField';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';
import { Switch } from '../../elements/Switch';
import CheckBox from '../../elements/CheckBox';
import AdminListIcon from '../elements/AdminListIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  ConsoleSet,
  DriveFamily,
  EditedDriveFamily,
  NewDriveFamily,
  NumberOfDrivesConfigItem,
  ScopeOfApplication,
} from '../../redux/admin/adminFacadeReducer';
import {
  changeDriveFamily,
  createDriveFamily,
  updateEditedDriveFamily,
} from '../../redux/admin/adminFacadeActions';
import { conditionalValue, fieldsFilled } from '../general/helpers';
import Pagination from '../components/Pagination';
import { LOCKING_DRIVE_OPTIONS, UiConstants } from '../constants';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';
import { Edit } from '../../types';
import AdminNavLink from '../common/AdminNavLink';
import { Navigate, Route, Routes } from 'react-router-dom';
import Button, { ButtonType } from '../../elements/Button';
import _ from 'lodash';
import {
  DrivePosition,
  DriveSeries,
  OpeningDirection,
  OpeningType,
  RangeOfApplication,
} from '../../redux/constants';
import { ThunkDispatch } from 'redux-thunk';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

interface DriveFamiliesDialogProps {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
}

function NumberOfDrivesConfigInput(props: {
  numberOfDrivesConfig: NumberOfDrivesConfigItem;
  scopeOfApplication: ScopeOfApplication;
  property: keyof NumberOfDrivesConfigItem;
  index: number;
  updateNumberOfDrivesConfig: (
    scope: ScopeOfApplication,
    config: NumberOfDrivesConfigItem,
    index: number,
    editedDriveFamily: EditedDriveFamily,
    dispatch: AdminThunkDispatch<AnyAction>,
  ) => void;
}) {
  const editedDriveFamily = useSelector<AdminState, EditedDriveFamily>(
    state => state.adminFacade.editedDriveFamily!,
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState(
    props.numberOfDrivesConfig[props.property],
  );

  return (
    <InputFieldNumber
      value={props.numberOfDrivesConfig[props.property]}
      onBlur={() =>
        props.updateNumberOfDrivesConfig(
          props.scopeOfApplication,
          {
            ...props.numberOfDrivesConfig,
            [props.property]: value,
          },
          props.index,
          editedDriveFamily,
          dispatch,
        )
      }
      onChange={v => setValue(v)}
    />
  );
}

function NumberOfDrivesConfigRow(props: {
  numberOfDrivesConfig: NumberOfDrivesConfigItem;
  index: number;
  scopeOfApplication: ScopeOfApplication;
  updateNumberOfDrivesConfig: (
    scope: ScopeOfApplication,
    config: NumberOfDrivesConfigItem,
    index: number,
    editedDriveFamily: EditedDriveFamily,
    dispatch: AdminThunkDispatch<AnyAction>,
  ) => void;
  removeNumberOfDrivesConfigItems: (
    scope: ScopeOfApplication,
    index: number,
  ) => void;
}) {
  return (
    <tr className="table-row">
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="minSashWidth"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="maxSashWidth"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="minSashHeight"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="maxSashHeight"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="minNumberOfDrives"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="maxNumberOfDrives"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <NumberOfDrivesConfigInput
          numberOfDrivesConfig={props.numberOfDrivesConfig}
          property="maximumWindLoad"
          index={props.index}
          scopeOfApplication={props.scopeOfApplication}
          updateNumberOfDrivesConfig={props.updateNumberOfDrivesConfig}
        />
      </td>
      <td className="general-drive-families-data-dialog__config-table-cell">
        <Button
          action={() =>
            props.removeNumberOfDrivesConfigItems(
              props.scopeOfApplication,
              props.index,
            )
          }
          label="Löschen"
          type={ButtonType.SECONDARY}
        />
      </td>
    </tr>
  );
}

const DriveFamiliesDialog: FC<
  React.PropsWithChildren<DriveFamiliesDialogProps>
> = props => {
  const editedDriveFamily = useSelector<AdminState, EditedDriveFamily>(
    state => state.adminFacade.editedDriveFamily!,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const consoleSetsFacade = useSelector<AdminState, ConsoleSet[]>(
    state => state.adminFacade.consoleSets,
  );
  const allDriveSeries = useSelector<AdminState, DriveSeries[]>(
    state => state.adminFacade.driveSeries,
  );

  if (!editedDriveFamily) {
    return null;
  }

  function mandatoryFieldsFilled(): boolean {
    if (!editedDriveFamily || !editedDriveFamily.anwendungsbereiche) {
      return false;
    }

    if (
      !(editedDriveFamily.leftAvailable || editedDriveFamily.rightAvailable)
    ) {
      return false;
    }

    return fieldsFilled(
      editedDriveFamily.name,
      editedDriveFamily.antriebsfamilie,
      editedDriveFamily.driveSeries,
      conditionalValue(
        !!editedDriveFamily.supportsLockingDrive,
        editedDriveFamily.verriegelungsKuerzel,
      ),
      ...editedDriveFamily.anwendungsbereiche.flatMap(ab =>
        ab.numberOfDrivesConfig.flatMap(drivesConfig => [
          drivesConfig.maxNumberOfDrives,
          drivesConfig.minNumberOfDrives,
        ]),
      ),
    );
  }

  //like above but for DriveFamily
  function updateEditedDriveFamilyPropertyAction<Key extends keyof DriveFamily>(
    key: Key,
  ) {
    return (value: DriveFamily[Key] | null) =>
      dispatch(updateEditedDriveFamily({ ...editedDriveFamily, [key]: value }));
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        'id' in editedDriveFamily && editedDriveFamily.id
          ? 'Antriebsfamilie bearbeiten'
          : 'Antriebsfamilie  anlegen'
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              dispatch(
                'id' in editedDriveFamily && editedDriveFamily.id
                  ? changeDriveFamily(editedDriveFamily as DriveFamily)
                  : createDriveFamily(editedDriveFamily as NewDriveFamily),
              );
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          'id' in editedDriveFamily && editedDriveFamily.id
            ? 'Speichern'
            : 'Anlegen',
      }}
    >
      <div className="general-drive-families-data-dialog">
        <FormLayout additionalClass="general-drive-families-data-dialog__family-block admin__block">
          <div className="admin__block-title">Familie</div>
          <InputField
            label="Antriebsfamilie*"
            placeholder=""
            value={editedDriveFamily.antriebsfamilie}
            additionalClass="general-drive-families-data-dialog__drive-family"
            onChange={updateEditedDriveFamilyPropertyAction('antriebsfamilie')}
          />
          <InputField
            label="Name*"
            placeholder=""
            value={editedDriveFamily.name}
            additionalClass="general-drive-families-data-dialog__drive-family-name"
            onChange={updateEditedDriveFamilyPropertyAction('name')}
          />
          <SelectField
            additionalClass="general-drive-families-data-dialog__drive-series"
            label="Antriebsserie *"
            value={selectFieldValue(
              editedDriveFamily.driveSeries?.name,
              editedDriveFamily.driveSeries,
            )}
            action={v =>
              updateEditedDriveFamilyPropertyAction('driveSeries')(v.value)
            }
            options={allDriveSeries.map(v => selectFieldValue(v.name, v))}
            name="drive-series"
            searchable={true}
            placeholder={''}
          />
          <div className="general-drive-families-data-dialog__mirrored-symmetrical form-layout__sub-group form-layout__sub-group--2">
            <CheckBox
              label="Spiegelbar"
              checked={!!editedDriveFamily.spiegelbar}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('spiegelbar')(
                  !editedDriveFamily.spiegelbar,
                )
              }
            />
            <CheckBox
              label="Symmetrisch"
              checked={!!editedDriveFamily.symmetrisch}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('symmetrisch')(
                  !editedDriveFamily.symmetrisch,
                )
              }
            />
          </div>
          <div className="general-drive-families-data-dialog__left-right form-layout__sub-group form-layout__sub-group--2">
            <FormLayoutSubgroupTitle>
              mögliche Ausführungen *
            </FormLayoutSubgroupTitle>
            <CheckBox
              label="Links"
              checked={!!editedDriveFamily.leftAvailable}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('leftAvailable')(
                  !editedDriveFamily.leftAvailable,
                )
              }
            />
            <CheckBox
              label="Rechts"
              checked={!!editedDriveFamily.rightAvailable}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('rightAvailable')(
                  !editedDriveFamily.rightAvailable,
                )
              }
            />
          </div>
          <div className="general-drive-families-data-dialog__draw-bridge form-layout__sub-group form-layout__sub-group--2">
            <FormLayoutSubgroupTitle>Zugbrücke</FormLayoutSubgroupTitle>
            <CheckBox
              label="möglich"
              checked={!!editedDriveFamily.drawBridge}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('drawBridge')(
                  !editedDriveFamily.drawBridge,
                )
              }
            />
            <CheckBox
              label="-ZB anzeigen"
              checked={!!editedDriveFamily.printDrawBridgeSuffix}
              onClick={() =>
                updateEditedDriveFamilyPropertyAction('printDrawBridgeSuffix')(
                  !editedDriveFamily.printDrawBridgeSuffix,
                )
              }
            />
          </div>

          <InputField
            label="Technologie"
            placeholder=""
            value={editedDriveFamily.technologie}
            additionalClass="general-drive-families-data-dialog__technology"
            onChange={updateEditedDriveFamilyPropertyAction('technologie')}
          />
          <div className="general-drive-families-data-dialog__switch">
            <Switch
              labelText="Aktiv"
              turnedOn={!!editedDriveFamily.active}
              onChange={() =>
                updateEditedDriveFamilyPropertyAction('active')(
                  !editedDriveFamily.active,
                )
              }
            />
          </div>
        </FormLayout>

        <FormLayout additionalClass="general-drive-families-data-dialog__locking-block admin__block">
          <div className="general-drive-families-data-dialog__title admin__block-title">
            Verriegelung
          </div>
          <CheckBox
            additionalClass="general-drive-families-data-dialog__supports-locking-drive"
            labelText="Unterstützt Verriegelung"
            checked={!!editedDriveFamily.supportsLockingDrive}
            onClick={() =>
              updateEditedDriveFamilyPropertyAction('supportsLockingDrive')(
                !editedDriveFamily.supportsLockingDrive,
              )
            }
          />
          {editedDriveFamily.supportsLockingDrive && (
            <SelectField
              additionalClass="general-drive-families-data-dialog__locking-note"
              label="Meldung f. Verriegelung *"
              value={selectFieldValue(
                LOCKING_DRIVE_OPTIONS[editedDriveFamily.verriegelungsKuerzel!],
                editedDriveFamily.verriegelungsKuerzel,
              )}
              action={v =>
                updateEditedDriveFamilyPropertyAction('verriegelungsKuerzel')(
                  v.value,
                )
              }
              options={Object.entries(LOCKING_DRIVE_OPTIONS).map(([k, v]) =>
                selectFieldValue(v, k),
              )}
              name="locking-drive-option"
              searchable={true}
              placeholder={''}
            />
          )}
        </FormLayout>
        <FormLayout additionalClass="general-drive-families-data-dialog__console-set-block admin__block">
          <FormLayoutElement
            additionalClass="general-drive-families-data-dialog__title admin__block-title"
            columnStart={1}
            rowStart={1}
            columnWidth={6}
          >
            Enthaltenes Konsolenset
          </FormLayoutElement>
          <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
            <SelectField
              additionalClass="general-drive-families-data-dialog__contained-console-set"
              value={
                editedDriveFamily.consoleSetFacade
                  ? selectFieldValue(
                      LOCKING_DRIVE_OPTIONS[
                        editedDriveFamily.consoleSetFacade.name
                      ],
                      editedDriveFamily.consoleSetFacade,
                    )
                  : undefined
              }
              label="Fassade"
              action={v => {
                updateEditedDriveFamilyPropertyAction('consoleSetFacade')(
                  v?.value,
                );
              }}
              options={[
                { label: 'keine Auswahl', value: undefined },
                ...consoleSetsFacade.map(consoleSet =>
                  selectFieldValue(consoleSet.name, consoleSet),
                ),
              ]}
              name="locking-drive-option"
              searchable={true}
              placeholder={''}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
            <SelectField
              additionalClass="general-drive-families-data-dialog__contained-console-set-roof"
              value={
                editedDriveFamily.consoleSetRoof
                  ? selectFieldValue(
                      LOCKING_DRIVE_OPTIONS[
                        editedDriveFamily.consoleSetRoof.name
                      ],
                      editedDriveFamily.consoleSetRoof,
                    )
                  : undefined
              }
              label="Dach"
              action={v =>
                updateEditedDriveFamilyPropertyAction('consoleSetRoof')(
                  v?.value,
                )
              }
              options={[
                { label: 'keine Auswahl', value: undefined },
                ...consoleSetsFacade.map(consoleSet =>
                  selectFieldValue(consoleSet.name, consoleSet),
                ),
              ]}
              name="locking-drive-option"
              searchable={true}
              placeholder={''}
            />
          </FormLayoutElement>
        </FormLayout>
        <FormLayout additionalClass="general-drive-families-data-dialog__empty-block admin__block">
          <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
            <div className="admin__block-title">Anwendungsbereiche</div>
            <FormLayout>
              <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
                <FormLayoutSubgroupTitle>
                  seitliche Montage mit Kippflügel
                </FormLayoutSubgroupTitle>
              </FormLayoutElement>
              <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
                <InputFieldNumber
                  label="maximaler Öffnungswinkel"
                  placeholder=""
                  value={editedDriveFamily.maxOpeningAngleSideInstBottomHung}
                  additionalClass="general-drive-families-data-dialog__max-opening-angle-side-inst-bottom-hung"
                  onChange={updateEditedDriveFamilyPropertyAction(
                    'maxOpeningAngleSideInstBottomHung',
                  )}
                />
              </FormLayoutElement>
              <FormLayoutElement rowStart={4} columnStart={1} columnWidth={6}>
                <FormLayoutSubgroupTitle>
                  seitliche/ggü. Band Montage mit Drehflügel
                </FormLayoutSubgroupTitle>
                <CheckBox
                  label="Öffnungsseite im Antriebsnahmen anzeigen"
                  checked={!!editedDriveFamily.appendOpeningSideToName}
                  onClick={() =>
                    updateEditedDriveFamilyPropertyAction(
                      'appendOpeningSideToName',
                    )(!editedDriveFamily.appendOpeningSideToName)
                  }
                />
              </FormLayoutElement>
            </FormLayout>
          </FormLayoutElement>
        </FormLayout>

        <FormLayout
          inline={true}
          additionalClass="admin__block general-drive-families-data-dialog__installation"
        >
          <FormLayoutElement
            rowStart={1}
            columnStart={1}
            columnWidth={6}
            additionalClass="admin__block-title"
          >
            Installationsarten
          </FormLayoutElement>
          <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
            <AdminNavLink to="/admin/general-data/drive-families/facade">
              Fassade
            </AdminNavLink>
            <AdminNavLink to="/admin/general-data/drive-families/roof">
              Dach
            </AdminNavLink>
          </FormLayoutElement>

          <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
            <Routes>
              <Route
                path=""
                element={
                  <Navigate
                    replace
                    to="/admin/general-data/drive-families/facade"
                  />
                }
              />
              <Route
                path="/facade"
                element={
                  <InstallationConfigurationArea
                    position={RangeOfApplication.FACADE}
                  />
                }
              />
              <Route
                path="/roof"
                element={
                  <InstallationConfigurationArea
                    position={RangeOfApplication.ROOF}
                  />
                }
              />
            </Routes>
            {/*</Table>*/}
          </FormLayoutElement>
        </FormLayout>
      </div>
    </Dialog>
  );
};

const DriveFamiliesData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const driveFamilies = useSelector<AdminState, DriveFamily[]>(
    state => state.adminFacade.driveFamilies,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    driveFamilies,
    searchString,
    ['name', 'antriebsfamilie'],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function enableEditFamily(family: DriveFamily): void {
    setDialogIsShown(true);
    dispatch(updateEditedDriveFamily(family));
  }

  function triggerCreationMode(): void {
    const emptyScopes: ScopeOfApplication[] = [];
    dispatch(
      updateEditedDriveFamily({
        anwendungsbereiche: emptyScopes,
      } as Edit<DriveFamily>),
    );
    setDialogIsShown(true);
  }

  function getCurrentTableContent(): DriveFamily[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <DriveFamiliesDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
      />
      <div className="sub-header">
        <div className="sub-header__title">Antriebsfamilien</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Antriebsfamilie suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div className="cv-data">
        <Table>
          <TableHeader>
            <th>Name</th>
            <th>Antriebsfamilie</th>
            <th>Spiegelbar</th>
            <th>Meldung f. Verriegelung</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          {getCurrentTableContent().map(f => (
            <TableRow>
              <td>{f.name}</td>
              <td>{f.antriebsfamilie}</td>
              <td>
                <AdminListIcon checked={f.spiegelbar} />
              </td>
              <td>{LOCKING_DRIVE_OPTIONS[f.verriegelungsKuerzel]}</td>
              <td>
                <AdminListIcon checked={f.active} />
              </td>
              <td>
                <button onClick={() => enableEditFamily(f)}>
                  {UiConstants.EDIT}
                </button>
              </td>
            </TableRow>
          ))}
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

function InstallationConfigurationArea(props: {
  position: RangeOfApplication;
}): ReactElement {
  return (
    <>
      <h3 className="general-drive-families-data-dialog__opening-type-heading">
        Klappflügel
      </h3>
      <FormLayout fourColumns>
        <FormLayoutElement rowStart={1} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Std. Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KLAPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KLAPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Seitliche Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KLAPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KLAPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
      </FormLayout>
      <h3 className="general-drive-families-data-dialog__opening-type-heading">
        Kippflügel
      </h3>
      <FormLayout fourColumns>
        <FormLayoutElement rowStart={1} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Std. Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KIPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KIPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Seitliche Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KIPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_KIPP}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
      </FormLayout>
      <h3 className="general-drive-families-data-dialog__opening-type-heading">
        Drehflügel
      </h3>
      <FormLayout fourColumns>
        <FormLayoutElement rowStart={1} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Std. Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_DREH}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_DREH}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1}>
          <h4 className="general-drive-families-data-dialog__assembly-type-heading">
            Seitliche Montage
          </h4>
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_DREH}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={3} columnWidth={2}>
          <InstallationTypeConfiguration
            openingType={OpeningType.FENSTER_OEFFNUNGSART_DREH}
            position={props.position}
            assemblyType={
              DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
            }
            directions={OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS}
          />
        </FormLayoutElement>
      </FormLayout>
    </>
  );
}

function InstallationTypeConfiguration(props: {
  openingType: OpeningType;
  position: RangeOfApplication;
  assemblyType: DrivePosition;
  directions: OpeningDirection;
}): ReactElement {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedDriveFamily = useSelector<AdminState, EditedDriveFamily>(
    state => state.adminFacade.editedDriveFamily!,
  );

  const scopeOfApplication = getScopeOfApplication(
    props.openingType,
    props.position,
    props.assemblyType,
    props.directions,
    editedDriveFamily as EditedDriveFamily,
  );

  function removeNumberOfDrivesConfigItems(
    dispatch: AdminThunkDispatch<AnyAction>,
  ): (scope: ScopeOfApplication, index: number) => void {
    return (scope, index) => {
      const scopeOfApplication = editedDriveFamily.anwendungsbereiche!.find(
        s => s === scope,
      )!;
      scopeOfApplication.numberOfDrivesConfig = [
        ...scopeOfApplication.numberOfDrivesConfig.slice(0, index),
        ...scopeOfApplication.numberOfDrivesConfig.slice(index + 1),
      ];

      dispatch(updateEditedDriveFamily(_.cloneDeep(editedDriveFamily)));
    };
  }

  return (
    <div>
      <CheckBox
        label={props.directions}
        checked={!!scopeOfApplication}
        onClick={() =>
          toggleScopeOfApplication(
            props.openingType,
            props.position,
            props.assemblyType,
            props.directions,
            editedDriveFamily,
            dispatch,
          )
        }
      />
      {scopeOfApplication && (
        <>
          <FormLayout
            inline
            additionalClass="general-drive-families-data-dialog__scope-of-application-general-data"
          >
            <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
              <InputFieldNumber
                label="minimaler Abstand vom Drehband"
                placeholder=""
                value={scopeOfApplication.minimalDistanceToHinge}
                onChange={v =>
                  updateScopeOfApplication(
                    scopeOfApplication,
                    {
                      minimalDistanceToHinge: v,
                    },
                    editedDriveFamily,
                    dispatch,
                  )
                }
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={1} columnStart={3} columnWidth={2}>
              <InputFieldNumber
                label="maximaler Öffnungswinkel"
                placeholder=""
                value={scopeOfApplication.maximumOpeningAngle}
                onChange={v =>
                  updateScopeOfApplication(
                    scopeOfApplication,
                    {
                      maximumOpeningAngle: v,
                    },
                    editedDriveFamily,
                    dispatch,
                  )
                }
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={1} columnStart={5} columnWidth={2}>
              <InputFieldNumber
                label="maximaler Öffnungshub"
                placeholder=""
                value={scopeOfApplication.maximumOpeningStroke}
                onChange={v =>
                  updateScopeOfApplication(
                    scopeOfApplication,
                    {
                      maximumOpeningStroke: v,
                    },
                    editedDriveFamily,
                    dispatch,
                  )
                }
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
              <CheckBox
                onClick={() => {
                  updateScopeOfApplication(
                    scopeOfApplication,
                    {
                      needsAtLeastOneGasSpring:
                        !scopeOfApplication.needsAtLeastOneGasSpring,
                    },
                    editedDriveFamily,
                    dispatch,
                  );
                }}
                checked={scopeOfApplication.needsAtLeastOneGasSpring}
                label="mind. 1 Gasdruckfeder"
              />
            </FormLayoutElement>
          </FormLayout>
          <div className="general-drive-families-data-dialog__config-table-heading">
            Antriebskonfiguration
          </div>
          <table className="general-drive-families-data-dialog__config-table">
            <thead>
              <tr className="table-header">
                <th className="general-drive-families-data-dialog__config-table-cell">
                  min. Breite
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  max. Breite
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  min. Höhe
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  max. Höhe
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  min. Antriebe*
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  max. Antriebe*
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell">
                  {'<'} Windlast
                </th>
                <th className="general-drive-families-data-dialog__config-table-cell"></th>
              </tr>
            </thead>
            <tbody>
              {scopeOfApplication.numberOfDrivesConfig.map(
                (numberOfDrivesConfig, index) => (
                  <NumberOfDrivesConfigRow
                    key={index}
                    numberOfDrivesConfig={numberOfDrivesConfig}
                    index={index}
                    scopeOfApplication={scopeOfApplication}
                    updateNumberOfDrivesConfig={updateNumberOfDrivesConfig}
                    removeNumberOfDrivesConfigItems={removeNumberOfDrivesConfigItems(
                      dispatch,
                    )}
                  />
                ),
              )}
            </tbody>
          </table>
          <div className="general-drive-families-data-dialog__new-entry">
            <Button
              action={() =>
                addNewNumberOfDrivesConfig(
                  scopeOfApplication,
                  editedDriveFamily,
                  dispatch,
                )
              }
              label={UiConstants.NEW_ENTRY}
              type={ButtonType.SECONDARY}
            />
          </div>
        </>
      )}
    </div>
  );
}

function getScopeOfApplication(
  openingType: OpeningType,
  rangeOfApplication: RangeOfApplication,
  drivePosition: DrivePosition,
  openingDirection: OpeningDirection,
  editedDriveFamily: EditedDriveFamily,
): ScopeOfApplication | undefined {
  return editedDriveFamily?.anwendungsbereiche?.find(
    ab =>
      ab.openingType === openingType &&
      ab.rangeOfApplication === rangeOfApplication &&
      ab.drivePosition === drivePosition &&
      ab.openingDirection === openingDirection,
  );
}

function toggleScopeOfApplication(
  openingType: OpeningType,
  position: RangeOfApplication,
  drivePosition: DrivePosition,
  direction: OpeningDirection,
  editedDriveFamily: EditedDriveFamily,
  dispatch: AdminThunkDispatch<AnyAction>,
): void {
  const scope = getScopeOfApplication(
    openingType,
    position,
    drivePosition,
    direction,
    editedDriveFamily as DriveFamily,
  );

  if (!editedDriveFamily) {
    return;
  }

  if (scope) {
    dispatch(
      updateEditedDriveFamily({
        ...editedDriveFamily,
        anwendungsbereiche: [
          ...(editedDriveFamily.anwendungsbereiche ?? []).filter(
            a => a !== scope,
          ),
        ],
      }),
    );
  } else {
    dispatch(
      updateEditedDriveFamily({
        ...editedDriveFamily,
        anwendungsbereiche: [
          ...(editedDriveFamily.anwendungsbereiche ?? []),
          {
            openingType: openingType,
            rangeOfApplication: position,
            drivePosition: drivePosition,
            openingDirection: direction,
            numberOfDrivesConfig: [
              createNewNumberOfDrivesConfig(drivePosition),
            ],
            needsAtLeastOneGasSpring: false,
          },
        ],
      }),
    );
  }
}

function addNewNumberOfDrivesConfig(
  scope: ScopeOfApplication,
  editedDriveFamily: EditedDriveFamily,
  dispatch: ThunkDispatch<any, any, any>,
): void {
  const scopeOfApplication = editedDriveFamily.anwendungsbereiche!.find(
    s => s === scope,
  )!;
  scopeOfApplication.numberOfDrivesConfig = [
    ...scopeOfApplication.numberOfDrivesConfig,
    createNewNumberOfDrivesConfig(scopeOfApplication.drivePosition),
  ];

  dispatch(updateEditedDriveFamily(_.cloneDeep(editedDriveFamily)));
}

function updateScopeOfApplication(
  scope: ScopeOfApplication,
  updatedValues: Partial<ScopeOfApplication>,
  editedDriveFamily: EditedDriveFamily,
  dispatch: ThunkDispatch<any, any, any>,
): void {
  if (!editedDriveFamily) {
    return;
  }

  dispatch(
    updateEditedDriveFamily({
      ...editedDriveFamily,
      anwendungsbereiche: [
        ...(editedDriveFamily.anwendungsbereiche ?? []).filter(
          a =>
            a.openingType !== scope.openingType ||
            a.rangeOfApplication !== scope.rangeOfApplication ||
            a.drivePosition !== scope.drivePosition ||
            a.openingDirection !== scope.openingDirection,
        ),
        { ...scope, ...updatedValues },
      ],
    }),
  );
}

function createNewNumberOfDrivesConfig(drivePosition: DrivePosition) {
  return {
    minNumberOfDrives:
      drivePosition === DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
        ? 2
        : 1,
    maxNumberOfDrives:
      drivePosition === DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
        ? 2
        : 8,
    minSashWidth: null,
    maxSashWidth: null,
    maxSashHeight: null,
    minSashHeight: null,
    maximumWindLoad: null,
  };
}

function updateNumberOfDrivesConfig(
  scope: ScopeOfApplication,
  config: NumberOfDrivesConfigItem,
  index: number,
  editedDriveFamily: EditedDriveFamily,
  dispatch: AdminThunkDispatch<AnyAction>,
) {
  const newNumberOfDrivesConfig = [
    ...scope.numberOfDrivesConfig.slice(0, index),
    config,
    ...scope.numberOfDrivesConfig.slice(index + 1),
  ];
  updateScopeOfApplication(
    scope,
    {
      numberOfDrivesConfig: newNumberOfDrivesConfig,
    },
    editedDriveFamily,
    dispatch,
  );
}

export default DriveFamiliesData;

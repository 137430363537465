import React, { FC, ReactElement, useCallback, useState } from 'react';
import Dialog from '../../components/Dialog';
import SearchField from '../../elements/SearchField';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import AdminListIcon from '../elements/AdminListIcon';
import { ReactComponent as DragAndDropIcon } from './DragAndDropIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  conditionalValue,
  fieldsFilled,
  findCountryNameByAlpha2Id,
} from '../general/helpers';
import {
  CompanyBluekitDhdeMontageCalculationOptions,
  CompanyBluekitPriceListOptions,
  UiConstants,
} from '../constants';
import Pagination from '../components/Pagination';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import { Switch } from '../../elements/Switch';
import './CompanyDataView.scss';
import {
  CertificationBody,
  Company,
  CompanyWithOptionalId,
  Series,
} from '../../redux/admin/adminFacadeReducer';

import { useEditedCompany } from '../../hooks/selectorHooks';
import {
  createCompany,
  saveCompany,
} from '../../redux/admin/adminFacadeActions';
import {
  MenuPlacement,
  SelectField,
  selectFieldValue,
} from '../../elements/SelectField';
import { countryOptionToSelectOption } from '../../components/Projects/ProjectDialog';
import { CountryOption, LANGUAGE_LISTS } from '../../redux/constants';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import Button, { ButtonType } from '../../elements/Button';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedDownloadLink from '../../elements/ProtectedDownloadLink';
import { downloadListAsCSV } from '../common/download';
import {
  initializeNewEditedCompany,
  selectEditedCompany,
  storeDeleteTrainingCertificateOnSave,
  storeTrainingCertificateToUpload,
  updateEditedCompany,
} from '../../redux/admin/user-management/editedUserAndCompanyActions';
import classNames from 'classnames';
import _ from 'lodash';
import FieldLabel from '../../elements/FieldLabel';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import AdminRadioButton from '../../elements/RadioButton';
import { updateEditedTestPhaseRoof } from '../../redux/admin/editedTestPhaseActionsRoof';

export const BLUEKIT_DISCOUNT_CODE = [
  '0% (PA)',
  '20% (DS)',
  '25%',
  '26%',
  '27%',
  '28%',
  '29%',
  '30%',
  '31%',
  '32%',
  '33%',
  '34%',
  '35% (SME)',
  '36%',
  '37%',
  '38%',
  '39%',
  '40% (SMEPLUS)',
  '41%',
  '42%',
  '43%',
  '44%',
  '45% (OEM)',
  '46%',
  '47%',
  '48%',
  '49% (OEMPLUS)',
  '53,5%',
];

const COMPANIES_CSV_EXPORT_FILE_PREFIX = 'Firmenliste';

const CompanyDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const companies = useSelector<AdminState, Company[]>(
    state => state.adminFacade.companies,
  );
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    companies,
    searchString,
    ['name', 'id', 'city'],
    (company: Company) => [findCountryNameByAlpha2Id(company.country)],
  );

  function triggerCreationMode(): void {
    dispatch(initializeNewEditedCompany());
    setDialogIsShown(true);
  }

  function getCurrentTableContent(): Company[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminCompanyDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
      />
      <div className="sub-header">
        <div className="sub-header__title">Firma</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Firma suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              companies,
              ';',
              [],
              COMPANIES_CSV_EXPORT_FILE_PREFIX,
              { series: (s: Series) => s.name },
              {},
            )
          }
        >
          {UiConstants.DOWNLOAD_COMPANY_LIST}
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neue Firma
        </button>
      </div>
      <div className="company-data">
        <Table>
          <TableHeader>
            <th>Name</th>
            <th>Firmen ID</th>
            <th>Straße</th>
            <th>Nr.</th>
            <th>PLZ</th>
            <th>Ort</th>
            <th>Land</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(company => (
              <TableRow key={company.id}>
                <td>{company.name}</td>
                <td>{company.id || ''}</td>
                <td>{company.street}</td>
                <td>{company.number}</td>
                <td>{company.zip}</td>
                <td>{company.city}</td>
                <td>{findCountryNameByAlpha2Id(company.country)}</td>
                <td>
                  <AdminListIcon checked={company.active} />
                </td>
                <td>
                  <button
                    onClick={() => {
                      dispatch(selectEditedCompany(company));
                      setDialogIsShown(true);
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

interface AdminCompanyDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

function MyCalcData(props: {
  myCalcEnabled: boolean;
  certificateToUpload: File | undefined;
}): ReactElement {
  const dispatch = useDispatch();
  const editedCompany = useEditedCompany()!;

  return (
    <>
      {!props.myCalcEnabled && (
        <Navigate replace to="/admin/companies-management/companies/" />
      )}
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
          <InputField
            label="CPR-Nummer Fassade"
            placeholder="CPR-Nummer für die Fassade falls notwendig"
            value={editedCompany.cprNumberFacade}
            additionalClass="company-data-view-dialog__cpr-facade"
            onChange={cprFacade =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  cprNumberFacade: cprFacade,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={1} columnStart={4} columnWidth={3}>
          <InputField
            label="CPR-Nummer Dach"
            placeholder="CPR-Nummer für das Dach falls notwendig"
            value={editedCompany.cprNumberRoof}
            additionalClass="company-data-view-dialog__cpr-roof"
            onChange={cprRoof =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  cprNumberRoof: cprRoof,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={3}>
          <InputField
            label="CPR-Nummer Fassade Stahl"
            placeholder="CPR-Nummer für die Fassade Stahl falls notwendig"
            value={editedCompany.cprNumberFacadeSteel}
            additionalClass="company-data-view-dialog__cpr-facade-steel"
            onChange={cprFacadeSteel =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  cprNumberFacadeSteel: cprFacadeSteel,
                }),
              )
            }
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
          <div className="company-data-view-dialog__certificate">
            <CertificateUpload
              deleteCallback={() => {
                dispatch(storeDeleteTrainingCertificateOnSave(true));
                dispatch(storeTrainingCertificateToUpload(undefined));
              }}
              newFileAddedCallback={(file: File) => {
                dispatch(storeTrainingCertificateToUpload(file));
                dispatch(storeDeleteTrainingCertificateOnSave(false));
              }}
              certificateName="Schulungs-Zertifikat"
              certificateToUpload={props.certificateToUpload}
              deleteCertificateOnSave={state =>
                state.adminFacade.deleteTrainingCertificateOnSave
              }
              extractFilename={company => company.trainingCertificateFileName}
            />
          </div>
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={6}>
          <FormLayoutSubgroupTitle>
            zertifizierende Stelle *
          </FormLayoutSubgroupTitle>
          <div className="test-phases__certified_body_container">
            <AdminRadioButton
              checked={
                editedCompany.certificationBody === CertificationBody.IFI
              }
              name={CertificationBody.IFI}
              onChange={() =>
                dispatch(
                  updateEditedCompany({
                    ...editedCompany,
                    certificationBody: CertificationBody.IFI,
                  }),
                )
              }
            />
            <AdminRadioButton
              checked={
                editedCompany.certificationBody === CertificationBody.VDS
              }
              name={CertificationBody.VDS}
              onChange={() =>
                dispatch(
                  updateEditedCompany({
                    ...editedCompany,
                    certificationBody: CertificationBody.VDS,
                  }),
                )
              }
            />
          </div>
        </FormLayoutElement>
      </FormLayout>
    </>
  );
}

interface CertificateUploadProps {
  deleteCallback: () => void;
  newFileAddedCallback: (file: File) => void;
  extractFilename: (company: CompanyWithOptionalId) => string | undefined;
  deleteCertificateOnSave: (state: AdminState) => boolean;
  certificateName: string;
  certificateToUpload: File | undefined;
}

const CertificateUpload: FC<
  React.PropsWithChildren<CertificateUploadProps>
> = props => {
  const editedCompany = useEditedCompany();
  const deleteCertificateOnSave = useSelector<AdminState, boolean>(
    props.deleteCertificateOnSave,
  );
  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.newFileAddedCallback(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 10_000_000,
  } as unknown as DropzoneOptions);

  if (!editedCompany) {
    return null;
  }

  function noFileSelectedOrAlreadyUploaded(): boolean {
    return (
      deleteCertificateOnSave ||
      (!props.extractFilename(editedCompany!) && !props.certificateToUpload)
    );
  }

  const linkLabel =
    props.certificateToUpload?.name ||
    props.extractFilename(editedCompany) ||
    '';

  const fileIsDownloadable = props.extractFilename(editedCompany);

  return (
    <section>
      {noFileSelectedOrAlreadyUploaded() ? (
        <div {...getRootProps({ className: 'dropzone' })}>
          <div className="dropzone__icon">
            <DragAndDropIcon />
          </div>
          <input
            {...(getInputProps() as React.InputHTMLAttributes<HTMLInputElement>)}
          />
          <p>{props.certificateName} hier ablegen oder clicken</p>
        </div>
      ) : (
        <div className="dropzone__content">
          {fileIsDownloadable ? (
            <ProtectedDownloadLink
              link={`/certificates/trainingCertificate?companyId=${editedCompany.id}`}
              label={linkLabel}
            />
          ) : (
            <div className="dropzone__content-title">{linkLabel}</div>
          )}
          <Button
            action={props.deleteCallback}
            type={ButtonType.FRAME}
            labelText="Löschen"
          />
        </div>
      )}
    </section>
  );
};

function BluekitData(props: { bluekitEnabled: boolean }): ReactElement {
  const dispatch = useDispatch();
  const editedCompany = useEditedCompany()!;
  const articleNumberMappings = _.sortBy(
    editedCompany.parentCompany?.bluekitArticleNumberMappings ||
      editedCompany.bluekitArticleNumberMappings,
    ['sourceArticleNumber'],
  );

  const bluekitSourceArticleNumbers = useSelector<AdminState, string[]>(state =>
    _.uniq(
      state.adminFacade.bluekitArticleNumberMappings.map(
        mapping => mapping.sourceArticleNumber,
      ) || [],
    ),
  );

  function addOrUpdatebluekitArticleNumberMappping(
    bluekitSourceArticleNumber: string,
    newDestinationArticleNumber: string,
  ): void {
    let editedMapping = _.clone(
      editedCompany!.bluekitArticleNumberMappings.find(
        mapping => mapping.sourceArticleNumber === bluekitSourceArticleNumber,
      ),
    );

    if (editedMapping) {
      editedMapping.destinationArticleNumber =
        newDestinationArticleNumber.trim();
    } else {
      editedMapping = {
        sourceArticleNumber: bluekitSourceArticleNumber,
        destinationArticleNumber: newDestinationArticleNumber.trim(),
        companyId: editedCompany!.id,
      };
    }

    const mappingsWithOutExisting =
      editedCompany!.bluekitArticleNumberMappings.filter(
        mapping => mapping.sourceArticleNumber !== bluekitSourceArticleNumber,
      );

    dispatch(
      updateEditedCompany({
        ...editedCompany!,
        bluekitArticleNumberMappings: _.sortBy(
          [...mappingsWithOutExisting, editedMapping],
          ['sourceArticleNumber'],
          ['asc'],
        ),
      }),
    );
  }

  return (
    <>
      {!props.bluekitEnabled && (
        <Navigate replace to="/admin/companies-management/companies/" />
      )}
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
          <SelectField
            label="Discount Code *"
            disabled={!!editedCompany.parentCompany}
            value={
              editedCompany
                ? {
                    value:
                      editedCompany.parentCompany?.bluekitDiscountCode ||
                      editedCompany.bluekitDiscountCode,
                    label:
                      editedCompany.parentCompany?.bluekitDiscountCode ||
                      editedCompany.bluekitDiscountCode,
                  }
                : undefined
            }
            action={v =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  bluekitDiscountCode: v.value,
                }),
              )
            }
            options={BLUEKIT_DISCOUNT_CODE.map(code =>
              selectFieldValue(code, code),
            )}
            name="bluekit-discount-code"
            searchable={true}
            placeholder=""
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={1} columnStart={4} columnWidth={3}>
          <SelectField
            label="Preisliste"
            disabled={!!editedCompany.parentCompany}
            value={
              editedCompany
                ? {
                    value:
                      editedCompany.parentCompany?.bluekitPriceList ||
                      editedCompany.bluekitPriceList,
                    label:
                      editedCompany.parentCompany?.bluekitPriceList ||
                      editedCompany.bluekitPriceList,
                  }
                : undefined
            }
            action={v =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  bluekitPriceList: v.value,
                }),
              )
            }
            options={Object.values(CompanyBluekitPriceListOptions).map(code =>
              selectFieldValue(code, code),
            )}
            name="bluekit-price-list"
            searchable={true}
            placeholder=""
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={4} columnWidth={3}>
          <SelectField
            label="Montagekostenrechnung"
            disabled={!!editedCompany.parentCompany}
            value={
              editedCompany
                ? {
                    value:
                      editedCompany.parentCompany
                        ?.bluekitDhdeMontageCalculation ||
                      editedCompany.bluekitDhdeMontageCalculation,
                    label:
                      editedCompany.parentCompany?.bluekitPriceList ||
                      editedCompany.bluekitDhdeMontageCalculation,
                  }
                : undefined
            }
            action={v =>
              dispatch(
                updateEditedCompany({
                  ...editedCompany,
                  bluekitDhdeMontageCalculation: v.value,
                }),
              )
            }
            options={Object.values(
              CompanyBluekitDhdeMontageCalculationOptions,
            ).map(code => selectFieldValue(code, code))}
            name="bluekit-montage-calculation"
            searchable={true}
            placeholder=""
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
          <FieldLabel>Artikelnummern</FieldLabel>
          <Table>
            <tbody>
              {articleNumberMappings.length ===
              bluekitSourceArticleNumbers.length
                ? articleNumberMappings.map(mappings => (
                    <tr>
                      <td>{mappings.sourceArticleNumber}</td>
                      <td>
                        <InputField
                          disabled={!!editedCompany?.parentCompany}
                          placeholder="123.456.345"
                          value={mappings.destinationArticleNumber}
                          onChange={newDestinationArticleNumber =>
                            addOrUpdatebluekitArticleNumberMappping(
                              mappings.sourceArticleNumber,
                              newDestinationArticleNumber,
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))
                : bluekitSourceArticleNumbers.map(
                    bluekitSourceArticleNumber => (
                      <tr>
                        <td>{bluekitSourceArticleNumber}</td>
                        <td>
                          <InputField
                            placeholder="123.456.345"
                            value={
                              editedCompany.bluekitArticleNumberMappings?.find(
                                mapping =>
                                  mapping.sourceArticleNumber ===
                                  bluekitSourceArticleNumber,
                              )?.destinationArticleNumber || ''
                            }
                            onChange={newDestinationArticleNumber =>
                              addOrUpdatebluekitArticleNumberMappping(
                                bluekitSourceArticleNumber,
                                newDestinationArticleNumber,
                              )
                            }
                          />
                        </td>
                      </tr>
                    ),
                  )}
            </tbody>
          </Table>
        </FormLayoutElement>
      </FormLayout>
    </>
  );
}

const AdminCompanyDialog: FC<
  React.PropsWithChildren<AdminCompanyDialogProps>
> = (props: AdminCompanyDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const trainingCertificateToUpload = useSelector<AdminState, File | undefined>(
    state => state.adminFacade.trainingCertificateToUpload,
  );
  const editedCompany = useEditedCompany();
  const bluekitSourceArticleNumbers = useSelector<AdminState, string[]>(state =>
    _.uniq(
      state.adminFacade.bluekitArticleNumberMappings.map(
        mapping => mapping.sourceArticleNumber,
      ) || [],
    ),
  );
  const otherCompanies = useSelector<AdminState, Company[]>(state =>
    state.adminFacade.companies.filter(
      company => company.id !== editedCompany?.id,
    ),
  );

  if (!editedCompany) {
    return null;
  }

  function generateCompany(): void {
    dispatch(createCompany());
  }

  function editCompany(company: Company): void {
    dispatch(saveCompany(company));
  }

  function mandatoryFieldsFilled(): boolean {
    if (!editedCompany) {
      return false;
    }

    const bluekitFieldsFilledIfRequired =
      editedCompany.parentCompany ||
      !editedCompany?.bluekit ||
      fieldsFilled(editedCompany.bluekitDiscountCode);

    return (
      bluekitFieldsFilledIfRequired &&
      fieldsFilled(
        editedCompany.name,
        editedCompany.street,
        editedCompany.number,
        editedCompany.zip,
        editedCompany.city,
        editedCompany.country,
        conditionalValue(
          editedCompany.bluekit,
          editedCompany.bluekitDiscountCode,
        ),
      )
    );
  }

  const noParentCompanySelectValue = selectFieldValue(
    'kein Muttergesellschaft',
    null,
  );

  function propertyEnabled(property: 'bluekit' | 'mycalc'): boolean {
    if (!editedCompany) {
      return false;
    }

    if (editedCompany.parentCompany) {
      return editedCompany.parentCompany[property];
    } else {
      return editedCompany[property];
    }
  }

  function bluekitEnabled(): boolean {
    return propertyEnabled('bluekit');
  }

  function myCalcEnabled(): boolean {
    return propertyEnabled('mycalc');
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={editedCompany.id ? `Firma bearbeiten` : 'Neue Firma anlegen'}
      componentClass={''}
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
          dispatch(storeTrainingCertificateToUpload(undefined));
          dispatch(storeDeleteTrainingCertificateOnSave(false));
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              editedCompany.id
                ? editCompany(editedCompany as Company)
                : generateCompany();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: UiConstants.SAVE,
      }}
    >
      <div className="admin__tab-nav">
        <AdminNavLink to="/admin/companies-management/companies/baseData">
          Stammdaten
        </AdminNavLink>
        <AdminNavLink
          to="/admin/companies-management/companies/mycalc-data"
          additionalClassName={classNames('admin__tab-nav-item', {
            'admin__tab-nav-item--inactive': !myCalcEnabled(),
          })}
          onClick={!myCalcEnabled() ? e => e.preventDefault() : undefined}
        >
          myCalc Daten
        </AdminNavLink>
        <AdminNavLink
          to="/admin/companies-management/companies/bluekit-data"
          additionalClassName={classNames('admin__tab-nav-item', {
            'admin__tab-nav-item--inactive': !bluekitEnabled(),
          })}
          onClick={!bluekitEnabled() ? e => e.preventDefault() : undefined}
        >
          Bluekit Daten
        </AdminNavLink>
      </div>
      <Routes>
        <Route
          path="/mycalc-data"
          element={
            <MyCalcData
              myCalcEnabled={myCalcEnabled()}
              certificateToUpload={trainingCertificateToUpload}
            />
          }
        />
        <Route
          path="/bluekit-data"
          element={<BluekitData bluekitEnabled={bluekitEnabled()} />}
        />
        <Route
          path="/baseData"
          element={
            <FormLayout additionalClass="company-data-view-dialog">
              <SelectField
                menuPlacement={MenuPlacement.BOTTOM}
                name="parent-company"
                additionalClass="company-data-view-dialog__parent-company"
                action={v =>
                  dispatch(
                    updateEditedCompany({
                      ...editedCompany,
                      parentCompany: otherCompanies.find(
                        company => company.id === v.value,
                      ),
                    }),
                  )
                }
                options={[
                  noParentCompanySelectValue,
                  ...otherCompanies.map(company =>
                    selectFieldValue(company.name, company.id),
                  ),
                ]}
                placeholder="D+H Mechatronic AG"
                label="Muttergesellschaft"
                value={
                  editedCompany.parentCompany
                    ? selectFieldValue(
                        editedCompany.parentCompany.name,
                        editedCompany.parentCompany.id,
                      )
                    : noParentCompanySelectValue
                }
              />
              <InputField
                label="Firmenname *"
                placeholder="z.B. D&H Mechatronics"
                value={editedCompany.name}
                additionalClass="company-data-view-dialog__name"
                onChange={name =>
                  dispatch(
                    updateEditedCompany({ ...editedCompany, name: name }),
                  )
                }
              />
              <InputField
                label="Kürzel"
                placeholder="z.B. D&H_M"
                value={editedCompany.nameShort}
                additionalClass="company-data-view-dialog__shortname"
                onChange={nameShort =>
                  dispatch(
                    updateEditedCompany({
                      ...editedCompany,
                      nameShort: nameShort,
                    }),
                  )
                }
              />
              <InputField
                label="Straße *"
                placeholder="z.B. Georg-Sasse-Strasse"
                value={editedCompany.street}
                additionalClass="company-data-view-dialog__street"
                onChange={street =>
                  dispatch(
                    updateEditedCompany({ ...editedCompany, street: street }),
                  )
                }
              />
              <InputField
                label="Nr. *"
                placeholder="z.B. 28-32"
                value={editedCompany.number}
                additionalClass="company-data-view-dialog__house-number"
                onChange={number =>
                  dispatch(
                    updateEditedCompany({ ...editedCompany, number: number }),
                  )
                }
              />
              <InputField
                label="PLZ *"
                placeholder="z.B. D-22949"
                value={editedCompany.zip}
                additionalClass="company-data-view-dialog__zip"
                onChange={zip =>
                  dispatch(updateEditedCompany({ ...editedCompany, zip: zip }))
                }
              />
              <InputField
                label="Ort *"
                placeholder="z.B. Ammersbek"
                value={editedCompany.city}
                additionalClass="company-data-view-dialog__city"
                onChange={city =>
                  dispatch(
                    updateEditedCompany({ ...editedCompany, city: city }),
                  )
                }
              />
              <SelectField<string>
                additionalClass="company-data-view-dialog__country"
                label={'Land *'}
                value={countryOptionToSelectOption(
                  LANGUAGE_LISTS.de.find(
                    l => l.alpha2 === editedCompany.country,
                  ) as CountryOption,
                )}
                action={newValue => {
                  dispatch(
                    updateEditedCompany({
                      ...editedCompany,
                      country: newValue.value,
                    }),
                  );
                }}
                options={LANGUAGE_LISTS.de.map(countryOptionToSelectOption)}
                name="country"
                searchable={true}
              />
              <div className="company-data-view-dialog__mycalc-option">
                <Switch
                  labelText="myCalc"
                  disabled={!!editedCompany.parentCompany}
                  turnedOn={myCalcEnabled()}
                  onChange={myCalc =>
                    dispatch(
                      updateEditedCompany({ ...editedCompany, mycalc: myCalc }),
                    )
                  }
                />
              </div>
              <div className="company-data-view-dialog__bluekit-option">
                <Switch
                  labelText="Bluekit"
                  disabled={!!editedCompany.parentCompany}
                  turnedOn={bluekitEnabled()}
                  onChange={bluekit => {
                    dispatch(
                      updateEditedCompany({
                        ...editedCompany,
                        bluekit: bluekit,
                      }),
                    );
                  }}
                />
              </div>
              <InputField
                label="Debitorennummer"
                placeholder="1234"
                value={editedCompany.debtorNumber}
                additionalClass="company-data-view-dialog__debtor-number"
                onChange={debtorNumber =>
                  dispatch(
                    updateEditedCompany({
                      ...editedCompany,
                      debtorNumber: debtorNumber,
                    }),
                  )
                }
              />
              <InputField
                label="Art der Firma"
                placeholder="z.B. Partner"
                value={editedCompany.companyType}
                additionalClass="company-data-view-dialog__type"
                onChange={companyType =>
                  dispatch(
                    updateEditedCompany({
                      ...editedCompany,
                      companyType: companyType,
                    }),
                  )
                }
              />
              <div className="company-data-view-dialog__switch">
                <Switch
                  labelText="aktiv"
                  turnedOn={editedCompany.active}
                  onChange={active =>
                    dispatch(
                      updateEditedCompany({ ...editedCompany, active: active }),
                    )
                  }
                />
              </div>
              <div className="company-data-view-dialog__show-drive-performances-in-label-switch">
                <Switch
                  labelText="Leistungsdaten in NRWG Label anzeigen"
                  turnedOn={editedCompany.showPerformanceDataInNrwgLabel}
                  onChange={showDrivePerformancesInLabel =>
                    dispatch(
                      updateEditedCompany({
                        ...editedCompany,
                        showPerformanceDataInNrwgLabel:
                          showDrivePerformancesInLabel,
                      }),
                    )
                  }
                />
              </div>
            </FormLayout>
          }
        />
      </Routes>
    </Dialog>
  );
};

export default CompanyDataView;

import React, { ReactElement } from 'react';
import { RequestTypes } from '../../../redux/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { Calculating } from '../../../redux/uiStateReducer';
import { LockingConsole } from '../../../redux/admin/adminFacadeReducer';
import { RangeOfApplication, TabOptions } from '../../../redux/constants';
import ParameterValue, { Parameters } from '../../Parameters/ParameterValue';
import { AnyAction } from 'redux';
import {
  deSelectLockingConsole,
  setHighlightedLockingConsole,
} from '../../../redux/uiStateActions';
import ConsoleResults from './ConsoleResults';
import { ConsoleItem } from './ConsoleSelectionItem';

import { ConsoleRequestMessage } from './ConsoleRequestMessage';
import {
  getSelectedWindow,
  useSelectedLockingConsole,
} from '../../../hooks/selectorHooks';

export function LockingConsoleResults() {
  const calculating = useSelector<IState, Calculating>(s => s.ui.calculating);
  const suitableLockingConsoles = useSelector<IState, LockingConsole[]>(
    s => s.staticData.suitableLockingConsoles,
  );
  const rangeOfApplicationRoof = useSelector<IState, boolean>(
    s => s.parameters.rangeOfApplication.value === RangeOfApplication.ROOF,
  );
  const typeOfProfileDataInput = useSelector<IState, ParameterValue>(
    s => s.parameters.typeOfProfileDataInput,
  );
  const minimumProfileDataEntered = useSelector<IState, boolean>(
    s =>
      s.parameters.profileSeries.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileSash.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileFrame.value !== Parameters.NO_SELECTION_SYSTEM_SERIES,
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const highlightedLockingConsole = useSelector<
    IState,
    LockingConsole | undefined
  >(s => s.ui.highlightedLockingConsole);
  const selectedLockingConsole = useSelectedLockingConsole();

  function suitableLockingConsolesPresent(): boolean {
    return suitableLockingConsoles.length !== 0;
  }

  function loadingLockingConsoles(): boolean {
    return calculating[RequestTypes.LOCKING_CONSOLES];
  }

  function showConsoleOnRequestMessage(): boolean {
    if (loadingLockingConsoles()) {
      return false;
    }

    if (profileEntry() || rangeOfApplicationRoof) {
      if (!minimumProfileDataEntered) {
        return false;
      }
    }

    return !suitableLockingConsolesPresent();
  }

  function profileEntry(): boolean {
    return typeOfProfileDataInput.value === TabOptions.OPTION_PROFILE_ENTRY;
  }

  function showMoreProfileDataNeededEmptyState(): boolean {
    return (
      (profileEntry() || rangeOfApplicationRoof) && !minimumProfileDataEntered
    );
  }

  return (
    <ConsoleResults
      showConsoleSelectionHint={
        suitableLockingConsolesPresent() ||
        loadingLockingConsoles() ||
        showMoreProfileDataNeededEmptyState()
      }
      showMoreProfileDataNeededEmptyState={showMoreProfileDataNeededEmptyState()}
      loading={loadingLockingConsoles()}
      moreProfileDataNeeded={showMoreProfileDataNeededEmptyState()}
      label="LOCKING_CONSOLE_SELECTION_HEADER"
      content={[
        <>
          {!showMoreProfileDataNeededEmptyState() &&
            !loadingLockingConsoles() &&
            suitableLockingConsoles.map(cs => (
              <ConsoleItem
                key={cs.artNr}
                name={cs.name}
                selected={
                  cs.lockingConsoleId ===
                  highlightedLockingConsole?.lockingConsoleId
                }
                onClick={() => dispatch(setHighlightedLockingConsole(cs))}
                deleteOption={
                  suitableLockingConsoles.length > 1
                    ? () => dispatch(deSelectLockingConsole())
                    : () => dispatch(deSelectLockingConsole())
                }
                confirmed={
                  cs.lockingConsoleId ===
                  selectedLockingConsole?.lockingConsoleId
                }
              />
            ))}
        </>,
      ]}
      message={
        showConsoleOnRequestMessage() && (
          <ConsoleRequestMessage helpMessage="HELP_TEXT_NO_CONSOLE_FRAME_ASSEMBLY_LOCKING_CONSOLE" />
        )
      }
    />
  );
}

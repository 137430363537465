import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import { IntlAction, updateIntl } from 'react-intl-redux';
import { IState } from './store';
import {
  Locale,
  Pages,
  ProjectScopes,
  WindowTemplate,
  WindowTemplateWithOptionalId,
} from './uiStateReducer';
import { Drive, Hint } from './calculationResultReducer';
import {
  getConsoleDocuments,
  getDefaultConsole,
  getDriveDocuments,
  getLockingConsoleDocument,
  getLockingConsoleDocuments,
  getLockingDriveDocuments,
  getNRWGConsoleSet,
  getNrwgManualDocuments,
  ResetDocuments,
  resetDocuments,
  setConsoleDocuments,
  SetConsoleDocuments,
  SetDriveDocumentsAction,
  setLockingConsoleDocuments,
  SetLockingConsoleDocuments,
  storeNRWGConsoleSetCandidates,
  StoreNRWGConsoleSetCandidatesAction,
} from './staticDataActions';
import {
  getAerodynamicDesmoking,
  getGeomtricDesmoking,
  ResetDesmoking,
  resetDesmoking,
  UpdateGeometricDesmokingData,
} from './desmokingResultActions';

import { ConsoleSet } from './staticDataReducer';
import {
  BaseProfile,
  ExchangeProfile,
  FrameProfile,
  SashProfile,
  SystemSeries,
} from './profileDataReducer';
import {
  getBaseProfileResult,
  getExchangeProfileResult,
  getFrameProfileResult,
  getSashProfileResult,
  getSystemSeriesResult,
  UpdateBaseProfilesAction,
} from './profileDataActions';
import {
  Project,
  ShallowProject,
  ShallowWindow,
  Window,
} from './projectsReducer';
import {
  changeCalculationParameter,
  changeParametersState,
  ChangeParametersState as ChangeParametersStateAction,
  recalculate,
} from './parametersActions';
import {
  deleteCall,
  fetchAndStore,
  fetchFromServer,
  postAndStore,
  putAndStoreWithDelay,
  RequestTypes,
  translateServerParameterValuesToFrontendParameterValues,
  wrapCalculationParameters,
} from './httpClient';
import { resetCalculationResults } from './calculationResultActions';
import {
  getOrResetWindDeflectors,
  getPerformanceClasses,
  getRanges,
  getTestSearchOptions,
  getValidation,
  UpdatePerformanceClasses,
  updatePerformanceClasses,
  UpdateRanges,
  updateRanges,
  UpdateValidation,
  updateValidation,
} from './nrwgActions';
import { getMarkedDrive, getSelectedWindow } from '../hooks/selectorHooks';
import { WindDeflector } from './nrwgReducer';
import {
  getProjects,
  storeUpdatedProjectData,
  updateAcceptedHintsOnServer,
  UpdateProjects,
  updateProjectSearchStringAndFetchProjects,
} from './projectsActions';
import { setLanguage } from '../google_analytics/events';
import { ReactNode } from 'react';
import { LoggedInUser } from './authenticationReducer';
import { AdminState } from './admin/adminStore';
import { LockingConsole } from './admin/adminFacadeReducer';
import { ValueKey } from './valueKey';
import { TabOptions } from './constants';

export interface ShowDialog extends Action<'SHOW_DIALOG'> {
  dialog: ReactNode;
}
export interface CalculatingAction extends Action<'CALCULATING'> {
  requestType: RequestTypes;
}

export interface FinishedCalculation extends Action<'FINISHED_CALCULATION'> {
  requestType: RequestTypes;
}

export interface UpdateExpand extends Action<'UPDATE_EXPAND'> {
  value: boolean;
}

export interface StoreUserAction extends Action<'STORE_USER_ACTION'> {
  user: LoggedInUser | undefined;
}

export interface SetProjectScope extends Action<'SET_PROJECT_SCOPE'> {
  scope: ProjectScopes;
}

export interface StoreSelectedProjectAction
  extends Action<'SET_SELECTED_PROJECT'> {
  project: Project | undefined;
}

export interface StoreSelectedWindow extends Action<'SET_SELECTED_WINDOW'> {
  window: Window | undefined;
}

export interface SetSelectedSeriesAction extends Action<'SET_SELECTED_SERIES'> {
  selectedSeries: SystemSeries;
}

export interface SetSelectedSashProfileAction
  extends Action<'SET_SELECTED_SASH_PROFILE'> {
  selectedSashProfile: SashProfile | undefined;
}

export interface SetSelectedFrameProfileAction
  extends Action<'SET_SELECTED_FRAME_PROFILE'> {
  selectedFrameProfile: FrameProfile | undefined;
}

export interface SetSelectedExchangeProfileAction
  extends Action<'SET_SELECTED_EXCHANGE_PROFILE'> {
  selectedExchangeProfile: ExchangeProfile | undefined;
}

export interface SetSelectedBaseProfileAction
  extends Action<'SET_SELECTED_BASE_PROFILE'> {
  selectedBaseProfile: BaseProfile | undefined;
}

export interface UpdateOverlayActive extends Action<'UPDATE_OVERLAY_ACTIVE'> {
  value: boolean;
}

export interface SetActivePage extends Action<'SET_ACTIVE_PAGE'> {
  page: number;
}

export interface UpdateDownloadStatus extends Action<'UPDATE_DOWNLOAD_STATUS'> {
  value: boolean;
}

interface UpdateLocaleAction extends Action<'UPDATE_LOCALE'> {
  locale: Locale;
}

export interface StoreMarkedDriveAction extends Action<'SET_MARKED_DRIVE'> {
  name: string | undefined;
  list: 'suitableDrives' | 'partlyQualifiedDrives' | undefined;
}

export interface SetPerformanceClassesOpen
  extends Action<'SET_PERFORMANCE_CLASSES_OPEN'> {
  open: boolean;
}

export interface SetSelectedConsoleSetAction
  extends Action<'SET_SELECTED_CONSOLE_SET'> {
  selectedConsoleSets: ConsoleSet[] | undefined;
}

export interface SetSelectedLockingConsoleAction
  extends Action<'SET_SELECTED_LOCKING_CONSOLE'> {
  selectedLockingConsole: LockingConsole | undefined;
}

export interface PreSelectedConsoleSetAction
  extends Action<'PRE_SELECTED_CONSOLE_SET'> {
  consoleSet: ConsoleSet | undefined;
  consoleSetSection: number;
}

type ClearPreSelectedConsoleSetAction =
  Action<'CLEAR_PRE_SELECTED_CONSOLE_SET'>;

export interface StoreSelectedNRWGConsoleSetAction
  extends Action<'STORE_SELECTED_NRWG_CONSOLE_SET'> {
  selectedNRWGConsoleSet: ConsoleSet | undefined;
}

export interface SetHighlightedConsoleSetAction
  extends Action<'SET_HIGHLIGHTED_CONSOLE_SET'> {
  highlightedConsoleSet: ConsoleSet | undefined;
  highlightedConsoleSetConsolesSection: number;
}

export interface SetHighlightedLockingConsoleAction
  extends Action<'SET_HIGHLIGHTED_LOCKING_CONSOLE'> {
  highlightedLockingConsole: LockingConsole | undefined;
}

export interface RemovePreselectedConsoleSetAction
  extends Action<'REMOVE_PRESELECTED_CONSOLE_SET'> {
  consoleSetSection: number;
}

export interface SetSelectedSystemSeriesAction
  extends Action<'SET_SELECTED_SYSTEM_SERIES'> {
  systemSeries: SystemSeries | undefined;
}

export interface UpdateSingleEdit extends Action<'UPDATE_SINGLE_EDIT'> {
  value: boolean;
  valueKey?: string;
}

export interface InputFieldFocus extends Action<'UPDATE_INPUT_FIELD_FOCUS'> {
  valueKey?: string;
}

export interface SetProjectNavigationOpen
  extends Action<'SET_PROJECT_NAVIGATION_OPEN'> {
  open: boolean;
}

export interface AddTemplate extends Action<'ADD_WINDOW_TEMPLATE'> {
  template: WindowTemplateWithOptionalId;
}

export interface DeleteTemplate extends Action<'DELETE_WINDOW_TEMPLATE'> {
  template: WindowTemplateWithOptionalId;
}

export interface UpdateTemplatesAction
  extends Action<'UPDATE_TEMPLATES_ACTION'> {
  templates: WindowTemplateWithOptionalId[];
}

export interface UpdateTemplateAction extends Action<'UPDATE_TEMPLATE_ACTION'> {
  template: WindowTemplateWithOptionalId;
}

export function showDialog(dialog: ReactNode): ShowDialog {
  return {
    type: 'SHOW_DIALOG',
    dialog: dialog,
  };
}

export interface UpdateInputFromLogikal
  extends Action<'UPDATE_INPUT_FROM_LOGIKAL'> {
  inputFromLogikal: boolean;
}

export function addTemplate(
  newTemplate: WindowTemplateWithOptionalId,
): AddTemplate {
  return {
    type: 'ADD_WINDOW_TEMPLATE',
    template: newTemplate,
  };
}

export function deleteTemplate(
  windowTemplate: WindowTemplateWithOptionalId,
): DeleteTemplate {
  return {
    type: 'DELETE_WINDOW_TEMPLATE',
    template: windowTemplate,
  };
}

function fetchWindow(projectId: number, windowId: number) {
  return fetchFromServer<Window>(RequestTypes.WINDOW, undefined, undefined, {
    id: [projectId, windowId],
  }) as unknown as Promise<Window>;
}

function createNewTemplate(
  projectId: number,
  shallowWindow: ShallowWindow,
): Promise<WindowTemplateWithOptionalId> {
  return fetchWindow(projectId, shallowWindow.id!).then(window => {
    return {
      calculationParameters: window.calculationParameters,
      nrwg: shallowWindow.nrwg,
      rwa: shallowWindow.rwa,
      name: '',
      description: '',
    };
  });
}

export function createTemplateOnServer(
  name: string,
  description: string,
  window: ShallowWindow,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<IState, any, AnyAction>,
    getState: () => IState,
  ): Promise<void> => {
    const newTemplate = await createNewTemplate(
      getState().ui.selectedProject!.id,
      window,
    ).then(template => {
      template.name = name;
      template.description = description;
      return template;
    });

    await postAndStore(
      RequestTypes.TEMPLATES,
      (createdTemplate: WindowTemplateWithOptionalId | undefined) => {
        if (!createdTemplate) {
          return;
        }
        wrapCalculationParameters([createdTemplate.calculationParameters]);
        dispatch(addTemplate(createdTemplate));
      },
      newTemplate,
    );
  };
}

export function updateProfiles(
  markedSeries: SystemSeries,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<IState, void, UpdateBaseProfilesAction>,
  ): Promise<void> => {
    dispatch(
      changeCalculationParameter(
        ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
        TabOptions.OPTION_PROFILE_ENTRY,
      ),
    );
    dispatch(getSashProfileResult(markedSeries));
    dispatch(getFrameProfileResult(markedSeries));
    dispatch(getExchangeProfileResult(markedSeries));
    dispatch(getBaseProfileResult(markedSeries));
  };
}

export const setSelectedSeries: ActionCreator<SetSelectedSeriesAction> = (
  selectedSeries: SystemSeries,
) => {
  return {
    type: 'SET_SELECTED_SERIES',
    selectedSeries: selectedSeries,
  };
};

export const setSelectedSashProfile: ActionCreator<
  SetSelectedSashProfileAction
> = (selectedSashProfile: SashProfile | undefined) => {
  return {
    type: 'SET_SELECTED_SASH_PROFILE',
    selectedSashProfile: selectedSashProfile,
  };
};

export const setSelectedFrameProfile: ActionCreator<
  SetSelectedFrameProfileAction
> = (selectedFrameProfile: FrameProfile | undefined) => {
  return {
    type: 'SET_SELECTED_FRAME_PROFILE',
    selectedFrameProfile: selectedFrameProfile,
  };
};

export const setSelectedExchangeProfile: ActionCreator<
  SetSelectedExchangeProfileAction
> = (selectedExchangeProfile: ExchangeProfile | undefined) => {
  return {
    type: 'SET_SELECTED_EXCHANGE_PROFILE',
    selectedExchangeProfile: selectedExchangeProfile,
  };
};

export const setSelectedBaseProfile: ActionCreator<
  SetSelectedBaseProfileAction
> = (selectedBaseProfile: BaseProfile | undefined) => {
  return {
    type: 'SET_SELECTED_BASE_PROFILE',
    selectedBaseProfile: selectedBaseProfile,
  };
};

export function setActivePage(page: number): SetActivePage {
  return {
    type: 'SET_ACTIVE_PAGE',
    page: page,
  };
}

export function updateLocaleAction(locale: Locale): UpdateLocaleAction {
  return {
    type: 'UPDATE_LOCALE',
    locale: locale,
  };
}

export function storeSelectedProject(
  project: Project | undefined,
): StoreSelectedProjectAction {
  return {
    type: 'SET_SELECTED_PROJECT',
    project: project,
  };
}

export function setProjectScope(scope: ProjectScopes): SetProjectScope {
  return {
    type: 'SET_PROJECT_SCOPE',
    scope: scope,
  };
}

export function fetchAndSelectedWindow(
  id: number | undefined,
): ThunkAction<Promise<void>, IState, void, StoreSelectedWindow> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    if (!id) {
      dispatch(storeSelectedWindow(undefined));
      return;
    }
    await fetchAndStore(
      RequestTypes.WINDOW,
      async (window: Window) => {
        wrapCalculationParameters([window.calculationParameters]);
        await dispatch(storeSelectedWindow(window));
      },
      getState,
      { id: [getState().ui.selectedProject!.id, id] },
    );
  };
}

export function storeSelectedWindow(
  window: Window | undefined,
): StoreSelectedWindow {
  return {
    type: 'SET_SELECTED_WINDOW',
    window: window,
  };
}

export function setProjectNavigationOpen(
  open: boolean,
): SetProjectNavigationOpen {
  return {
    type: 'SET_PROJECT_NAVIGATION_OPEN',
    open: open,
  };
}

export function updateTemplateOnServer(
  template: WindowTemplateWithOptionalId,
): ThunkAction<Promise<void>, IState, void, UpdateTemplateAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(calculating(RequestTypes.UPDATE_TEMPLATE));

    await putAndStoreWithDelay(
      RequestTypes.UPDATE_TEMPLATE,
      (updatedTemplate: WindowTemplateWithOptionalId | undefined) => {
        if (!updatedTemplate) {
          return;
        }
        wrapCalculationParameters([updatedTemplate.calculationParameters]);
        dispatch(updateTemplate(updatedTemplate));
        dispatch(finishedCalculation(RequestTypes.UPDATE_TEMPLATE));
      },
      template,
      template.id!,
    );
  };
}

export function deleteTemplateOnServer(
  template: WindowTemplate,
): ThunkAction<Promise<void>, IState, void, DeleteTemplate> {
  return async (dispatch: Dispatch): Promise<void> => {
    await deleteCall({
      requestType: RequestTypes.UPDATE_TEMPLATE,
      id: template.id!,
    }).then(() => {
      dispatch(deleteTemplate(template));
    });
  };
}

export function switchLocaleAction(
  locale: Locale,
  usedInAdmin: boolean,
): ThunkAction<Promise<void>, IState | AdminState, void, IntlAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    if (!usedInAdmin) {
      setLanguage(locale);
    }
    dispatch(updateLocaleAction(locale));
    const skipFetchingResources = window.location.search === '?showResources=1';
    const messages = skipFetchingResources
      ? { DUMMY: 'DUMMY' }
      : await fetchTranslations(locale);

    if (Object.keys(messages).length === 0) {
      return;
    }

    // @ts-ignore
    dispatch(updateIntl({ locale, messages, missingTranslation: 'ignore' }));
  };
}

export function setProjectScopeFetchProjects(
  scope: ProjectScopes,
): ThunkAction<Promise<void>, IState, void, UpdateProjects> {
  return async (
    dispatch: ThunkDispatch<IState, void, SetProjectScope>,
  ): Promise<void> => {
    dispatch(setProjectScope(scope));
    dispatch(getProjects(0));
  };
}

async function fetchTranslations(
  locale: Locale,
): Promise<{ [index: string]: string }> {
  return window
    .fetch(`/translations/${locale}.json?${Math.random()}`)
    .then(r => r.json())
    .catch(() => {
      return {};
    });
}

export const calculating = (requestType: any): CalculatingAction => {
  return {
    type: 'CALCULATING',
    requestType: requestType,
  };
};

export const finishedCalculation = (
  defaultConsole: RequestTypes,
): FinishedCalculation => {
  return {
    type: 'FINISHED_CALCULATION',
    requestType: defaultConsole,
  };
};

export function updateHintAcceptedStatus(
  hint: Hint,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch): Promise<void> => {
    dispatch(toggleAcceptedHint(hint));
    dispatch(updateAcceptedHintsOnServer());
  };
}

interface ToggleAcceptedHint extends Action<'TOGGLE_ACCEPTED_HINT'> {
  hint: Hint;
}

function toggleAcceptedHint(hint: Hint): ToggleAcceptedHint {
  return { type: 'TOGGLE_ACCEPTED_HINT', hint: hint };
}

interface StoreAcceptedHints extends Action<'STORE_ACCEPTED_HINTS'> {
  hints: Hint[];
}

function storeAcceptedHints(hints: Hint[]): StoreAcceptedHints {
  return { type: 'STORE_ACCEPTED_HINTS', hints: hints };
}

export function selectDriveAndSaveAction(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      | StoreMarkedDriveAction
      | UpdateGeometricDesmokingData
      | SetSelectedConsoleSetAction
      | ResetDesmoking
      | SetPerformanceClassesOpen
      | StoreSelectedProjectAction
    >,
    getState: () => IState,
  ): Promise<void> => {
    const window = getSelectedWindow(getState())!;
    const project = getState().ui.selectedProject!;

    postAndStore<Project>(
      RequestTypes.SELECT_DRIVE,
      async project => {
        dispatch(storeSelectedProject(project!));
        const window = getSelectedWindow(getState())!;
        await dispatch(fetchAndSelectedWindow(window.id));
        if (window.nrwg) {
          dispatch(getNRWGConsoleSet());
          if (!window.windDeflector) {
            dispatch(getOrResetWindDeflectors());
          }

          const lockingConsoles = getState().staticData.suitableLockingConsoles;

          if (lockingConsoles.length === 1) {
            dispatch(selectLockingConsole(lockingConsoles[0]));
          }
        }
      },
      {
        drive: getMarkedDrive(getState())!,
        acceptedHints: getState().calculationResult?.generalHints,
        locale: getState().intl.locale,
      },
      [project.id, window.id!],
      translateServerParameterValuesToFrontendParameterValues,
    );
  };
}

export function locallyResetSelectedConsoleSet(
  dispatch: ThunkDispatch<IState, void, AnyAction>,
) {
  dispatch(setConsoleDocuments([]));
  dispatch(storeSelectedConsoleSet(undefined));
  dispatch(storeSelectedNRWGConsoleSet(undefined));
  dispatch(recalculate({ keepSelectedDrive: true }));
}

export function locallyResetSelectedLockingConsole(
  dispatch: ThunkDispatch<IState, void, AnyAction>,
) {
  dispatch(setLockingConsoleDocuments([]));
  dispatch(storeSelectedLockingConsole(undefined));
}

export function deselectDriveAndSaveAction(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      | StoreMarkedDriveAction
      | UpdateGeometricDesmokingData
      | SetSelectedConsoleSetAction
      | ResetDesmoking
      | SetPerformanceClassesOpen
      | SetConsoleDocuments
      | StoreSelectedProjectAction
    >,
    getState: () => IState,
  ): Promise<void> => {
    const window = getSelectedWindow(getState())!;
    const project = getState().ui.selectedProject!;

    dispatch(switchPage(Pages.CALCULATION));

    if (window && project) {
      await postAndStore<Project>(
        RequestTypes.DESELECT_DRIVE,
        project => {
          dispatch(storeSelectedProject(project!));
          dispatch(fetchAndSelectedWindow(window.id));
        },
        {},
        [project.id, window.id!],
        translateServerParameterValuesToFrontendParameterValues,
      );
    }

    locallyResetSelectedConsoleSet(dispatch);
    locallyResetSelectedLockingConsole(dispatch);
  };
}

export function markDriveAction(
  drive: Drive | undefined,
): ThunkAction<Promise<void>, IState, void, SetDriveDocumentsAction> {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      | SetDriveDocumentsAction
      | StoreMarkedDriveAction
      | UpdateGeometricDesmokingData
      | SetSelectedConsoleSetAction
      | ResetDesmoking
      | SetPerformanceClassesOpen
    >,
    getState: () => IState,
  ): Promise<void> => {
    const suitableName = getState().calculationResult.suitableDrives.find(
      d => d === drive,
    )?.name;
    const partlyQualifiedIndex =
      getState().calculationResult.partlyQualifiedDrives.find(
        d => d === drive,
      )?.name;

    if (suitableName) {
      dispatch(storeMarkedDriveAction(suitableName, 'suitableDrives'));
    } else if (partlyQualifiedIndex) {
      dispatch(
        storeMarkedDriveAction(partlyQualifiedIndex, 'partlyQualifiedDrives'),
      );
    } else {
      dispatch(storeMarkedDriveAction(undefined, undefined));
    }

    const selectedWindow = getSelectedWindow(getState());
    const resultLockingDrive = selectedWindow?.selectedDrive
      ? selectedWindow?.selectedDrive?.lockingDriveInformation
      : getMarkedDrive(getState())?.lockingDriveInformation;

    if (
      drive?.driveId !==
      (selectedWindow?.selectedDrive || getMarkedDrive(getState()))?.driveId
    ) {
      dispatch(resetConsoleSets());
      dispatch(resetLockingConsoles());
    }

    if (drive) {
      await dispatch(getDriveDocuments(drive.itemNumber));

      if (!selectedWindow?.nrwg) {
        dispatch(getDefaultConsole());
      }

      if (selectedWindow?.nrwg && !selectedWindow.locked) {
        dispatch(getNRWGConsoleSet());
        dispatch(getOrResetWindDeflectors());
      }

      if (selectedWindow?.locked) {
        dispatch(getConsoleDocuments([selectedWindow.consoleSetItemNumber!]));
        dispatch(getNrwgManualDocuments());
      }

      if (resultLockingDrive) {
        dispatch(getLockingDriveDocuments(resultLockingDrive.articleNumber));
        if (resultLockingDrive.lockingConsoleArticleNumber) {
          dispatch(
            getLockingConsoleDocuments(
              resultLockingDrive.lockingConsoleArticleNumber,
            ),
          );
        }
      }
    } else {
      dispatch(resetDesmoking());
    }
  };
}

export function resetConsoleSets(): ThunkAction<
  Promise<void>,
  IState,
  void,
  | SetSelectedConsoleSetAction
  | StoreSelectedNRWGConsoleSetAction
  | PreSelectedConsoleSetAction
> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(deSelectConsoleSet());
    if (
      getState().staticData.suitableConsoleSets[0] !== undefined &&
      getState().staticData.suitableConsoleSets[0].length === 1
    ) {
      dispatch(
        preSelectConsoleSet(getState().staticData.suitableConsoleSets[0][0], 0),
      );
    }
  };
}

export function resetLockingConsoles(): ThunkAction<
  Promise<void>,
  IState,
  void,
  SetSelectedLockingConsoleAction | StoreSelectedNRWGConsoleSetAction
> {
  return async (dispatch): Promise<void> => {
    dispatch(deSelectLockingConsole());
  };
}

export function preSelectConsoleSet(
  consoleSet: ConsoleSet,
  consoleSetIndex: number,
): PreSelectedConsoleSetAction {
  return {
    type: 'PRE_SELECTED_CONSOLE_SET',
    consoleSet: consoleSet,
    consoleSetSection: consoleSetIndex,
  };
}

export function clearPreSelectedConsoleSet(): ClearPreSelectedConsoleSetAction {
  return {
    type: 'CLEAR_PRE_SELECTED_CONSOLE_SET',
  };
}

async function updateLocalSelectedConsoleSetData(
  dispatch: ThunkDispatch<
    IState,
    void,
    | SetSelectedConsoleSetAction
    | SetConsoleDocuments
    | Action<'CLEAR_PRE_SELECTED_CONSOLE_SET'>
  >,
  consoleSets: ConsoleSet[],
) {
  await dispatch(getConsoleDocuments(consoleSets.map(c => c.itemNumber)));

  dispatch(storeSelectedConsoleSet(consoleSets));
}

async function updateLocalSelectedLockingConsoleData(
  dispatch: ThunkDispatch<
    IState,
    void,
    | SetSelectedLockingConsoleAction
    | SetConsoleDocuments
    | Action<'CLEAR_PRE_SELECTED_LOCKING_CONSOLE'>
  >,
  lockingConsole: LockingConsole,
) {
  await dispatch(getLockingConsoleDocument(lockingConsole.artNr));

  dispatch(storeSelectedLockingConsole(lockingConsole));
}

export function selectConsoleSet(
  consoleSets: ConsoleSet[],
  options?: { recalculateDrives?: boolean; skipStoreOnServer?: boolean },
): ThunkAction<
  Promise<void>,
  IState,
  void,
  | SetSelectedConsoleSetAction
  | SetConsoleDocuments
  | ClearPreSelectedConsoleSetAction
  | StoreSelectedProjectAction
> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(clearPreSelectedConsoleSet());
    await updateLocalSelectedConsoleSetData(dispatch, consoleSets);

    const window = getSelectedWindow(getState());

    if (window?.selectedDrive && !options?.skipStoreOnServer) {
      const project = getState().ui.selectedProject!;
      await postAndStore<Project>(
        RequestTypes.SELECT_CONSOLE_SET,
        project => {
          dispatch(storeSelectedProject(project!));
          dispatch(fetchAndSelectedWindow(window.id));
          if (options?.recalculateDrives) {
            dispatch(recalculate({ keepSelectedDrive: true }));
          }
          dispatch(getAerodynamicDesmoking());
          dispatch(getGeomtricDesmoking());
        },
        {
          ids: consoleSets?.map(c => c.id) ?? [],
        },
        [project.id, window.id!],
        translateServerParameterValuesToFrontendParameterValues,
      );
    } else if (window?.selectedDrive && options?.skipStoreOnServer) {
      if (options?.recalculateDrives) {
        dispatch(recalculate({ keepSelectedDrive: true }));
      }
      dispatch(getAerodynamicDesmoking());
      dispatch(getGeomtricDesmoking());
    } else {
      if (options?.recalculateDrives) {
        await dispatch(recalculate({ keepSelectedDrive: true }));
      }
    }
  };
}

export function selectLockingConsole(
  lockingConsole: LockingConsole,
  options?: { recalculateDrives?: boolean; skipStoreOnServer?: boolean },
): ThunkAction<
  Promise<void>,
  IState,
  void,
  | SetSelectedLockingConsoleAction
  | SetLockingConsoleDocuments
  | StoreSelectedProjectAction
> {
  return async (dispatch, getState): Promise<void> => {
    await updateLocalSelectedLockingConsoleData(dispatch, lockingConsole);

    const window = getSelectedWindow(getState());

    if (window && !options?.skipStoreOnServer) {
      const project = getState().ui.selectedProject!;
      await postAndStore<Project>(
        RequestTypes.SELECT_LOCKING_CONSOLE,
        project => {
          // dispatch(storeSelectedProject(project!));
          dispatch(fetchAndSelectedWindow(window.id));
        },
        {
          id: lockingConsole.lockingConsoleId,
        },
        [project.id, window.id!],
        translateServerParameterValuesToFrontendParameterValues,
      );
    }
  };
}

export function deSelectConsoleSet(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch, getState): Promise<void> => {
    const window = getSelectedWindow(getState());
    locallyResetSelectedConsoleSet(dispatch);
    if (window?.selectedDrive) {
      const project = getState().ui.selectedProject!;
      await deleteCall({
        requestType: RequestTypes.SELECT_CONSOLE_SET,
        id: [project.id, window.id!],
        handleResult: (project: Project) => {
          storeUpdatedProjectData(project, dispatch);
          dispatch(fetchAndSelectedWindow(window.id));
          dispatch(getAerodynamicDesmoking());
        },
        resultTransformCallback:
          translateServerParameterValuesToFrontendParameterValues,
      });
    }
  };
}

export function deSelectLockingConsole(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch, getState): Promise<void> => {
    const window = getSelectedWindow(getState());
    locallyResetSelectedLockingConsole(dispatch);
    if (window) {
      const project = getState().ui.selectedProject!;
      await deleteCall({
        requestType: RequestTypes.SELECT_LOCKING_CONSOLE,
        id: [project.id, window.id!],
        handleResult: (project: Project) => {
          storeUpdatedProjectData(project, dispatch);
          dispatch(fetchAndSelectedWindow(window.id));
        },
        resultTransformCallback:
          translateServerParameterValuesToFrontendParameterValues,
      });
    }
  };
}

export function selectWindDeflector(
  windDeflector: WindDeflector,
): ThunkAction<Promise<void>, IState, void, AnyAction> {
  return async (dispatch, getState): Promise<void> => {
    const window = getSelectedWindow(getState())!;
    const project = getState().ui.selectedProject!;

    postAndStore<Project>(
      RequestTypes.SELECT_WIND_DEFLECTOR,
      project => {
        dispatch(storeSelectedProject(project!));
        dispatch(fetchAndSelectedWindow(window.id));
      },

      windDeflector,
      [project.id, window.id!],
      translateServerParameterValuesToFrontendParameterValues,
    );
  };
}

export function deselectWindDeflector(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch, getState): Promise<void> => {
    const window = getSelectedWindow(getState())!;
    const project = getState().ui.selectedProject!;

    postAndStore<Project>(
      RequestTypes.DESELECT_WIND_DEFLECTOR,
      project => {
        dispatch(storeSelectedProject(project!));
        dispatch(fetchAndSelectedWindow(window.id));
      },

      {},
      [project.id, window.id!],
      translateServerParameterValuesToFrontendParameterValues,
    );
  };
}

export const switchPage = (page: Pages) => {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      SetActivePage | StoreSelectedProjectAction
    >,
  ) => {
    dispatch(setActivePage(page));

    if (page === Pages.PROJECTS_MANAGER) {
      dispatch(storeSelectedProject(undefined));
      dispatch(updateProjectSearchStringAndFetchProjects('', 0));
    }
  };
};

export function switchSelectedWindow(shallowWindow: ShallowWindow) {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      | StoreSelectedWindow
      | ChangeParametersStateAction
      | UpdateSingleEdit
      | SetPerformanceClassesOpen
      | SetActivePage
      | UpdateValidation
      | UpdateRanges
      | ResetDocuments
      | SetSelectedConsoleSetAction
      | StoreSelectedNRWGConsoleSetAction
      | StoreAcceptedHints
      | StoreNRWGConsoleSetCandidatesAction
    >,
    getState: () => IState,
  ) => {
    if (getState().ui.selectedWindow?.id === shallowWindow?.id) {
      return;
    }

    dispatch(resetDocuments());
    if (!shallowWindow) {
      dispatch(resetConsoleSets());
      dispatch(resetLockingConsoles());
    }

    if (shallowWindow) {
      await dispatch(fetchAndSelectedWindow(shallowWindow.id));
    }

    dispatch(updateSingleEdit(false));
    dispatch(resetCalculationResults());
    dispatch(getSystemSeriesResult());
    dispatch(setActivePage(Pages.CALCULATION));
    dispatch(updateValidation(undefined));
    dispatch(updateRanges(undefined));
    dispatch(storeNRWGConsoleSetCandidates([]));
    dispatch(updateRanges(undefined));

    const window = getState().ui.selectedWindow;

    if (window) {
      dispatch(changeParametersState(window.calculationParameters));
      dispatch(storeAcceptedHints(window.acceptedHints));
      if (window.consoleSets) {
        updateLocalSelectedConsoleSetData(dispatch, window.consoleSets);
      } else {
        locallyResetSelectedConsoleSet(dispatch);
      }
      if (window.nrwg) {
        dispatch(storeSelectedNRWGConsoleSet(window.consoleSets?.[0]));
      }
      if (!window.locked) {
        dispatch(recalculate());
        dispatch(getTestSearchOptions());
        dispatch(getRanges());
        dispatch(getValidation());
        dispatch(getPerformanceClasses());
        dispatch(setPerformanceClassesOpen(true));
      } else {
        dispatch(getDriveDocuments(window.selectedDrive!.itemNumber));
        dispatch(
          getConsoleDocuments(window.consoleSets!.map(c => c.itemNumber)),
        );
        dispatch(getNrwgManualDocuments());
      }
    }

    if ((window?.nrwg && window.locked) || window?.selectedDrive) {
      dispatch(switchPage(Pages.SELECTED_PRODUCTS_NRWG));
    }
  };
}

export const resetSelectedWindowCalculation = () => {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      | StoreSelectedWindow
      | ChangeParametersStateAction
      | UpdateSingleEdit
      | SetPerformanceClassesOpen
      | UpdatePerformanceClasses
      | StoreSelectedNRWGConsoleSetAction
      | StoreMarkedDriveAction
    >,
  ) => {
    dispatch(storeMarkedDriveAction(undefined, undefined));
    dispatch(storeSelectedNRWGConsoleSet(undefined));
    dispatch(deselectDriveAndSaveAction());
    dispatch(resetCalculationResults());
    dispatch(getSystemSeriesResult());
    dispatch(recalculate());
    dispatch(getTestSearchOptions());
    dispatch(getRanges());
    dispatch(getValidation());
    dispatch(updatePerformanceClasses(undefined));
    dispatch(getPerformanceClasses());
    dispatch(storeSelectedNRWGConsoleSet(undefined));
  };
};

export function selectProject(project: ShallowProject) {
  return async (
    dispatch: ThunkDispatch<
      IState,
      void,
      StoreSelectedProjectAction | StoreSelectedWindow
    >,
    getState: () => IState,
  ) => {
    fetchAndStore(
      RequestTypes.PROJECT,
      (project: Project) => {
        dispatch(storeSelectedProject(project));
        dispatch(storeSelectedWindow(undefined));
        dispatch(switchSelectedWindow(project.buildingAreas![0].windows[0]));
      },
      getState,
      { id: project.id },
    );
  };
}

export function updateTemplates(
  templates: WindowTemplateWithOptionalId[],
): UpdateTemplatesAction {
  return {
    type: 'UPDATE_TEMPLATES_ACTION',
    templates: templates,
  };
}

export function updateTemplate(
  template: WindowTemplateWithOptionalId,
): UpdateTemplateAction {
  return {
    type: 'UPDATE_TEMPLATE_ACTION',
    template: template,
  };
}

export function getTemplates(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(calculating(RequestTypes.TEMPLATES));

    fetchAndStore(
      RequestTypes.TEMPLATES,
      (templates: WindowTemplateWithOptionalId[]) => {
        wrapCalculationParameters(templates.map(t => t.calculationParameters));
        dispatch(updateTemplates(templates));
      },
      getState,
    ).finally(() => dispatch(finishedCalculation(RequestTypes.PROJECTS)));
  };
}

export function storeMarkedDriveAction(
  name: string | undefined,
  list: 'suitableDrives' | 'partlyQualifiedDrives' | undefined,
): StoreMarkedDriveAction {
  return {
    type: 'SET_MARKED_DRIVE',
    name: name,
    list,
  };
}

export function storeSelectedConsoleSet(
  selectedConsoleSet: ConsoleSet[] | undefined,
): SetSelectedConsoleSetAction {
  return {
    type: 'SET_SELECTED_CONSOLE_SET',
    selectedConsoleSets: selectedConsoleSet,
  };
}

export function storeSelectedLockingConsole(
  selectedLockingConsole: LockingConsole | undefined,
): SetSelectedLockingConsoleAction {
  return {
    type: 'SET_SELECTED_LOCKING_CONSOLE',
    selectedLockingConsole: selectedLockingConsole,
  };
}

export function storeSelectedNRWGConsoleSet(
  selectedConsoleSet: ConsoleSet | undefined,
): StoreSelectedNRWGConsoleSetAction {
  return {
    type: 'STORE_SELECTED_NRWG_CONSOLE_SET',
    selectedNRWGConsoleSet: selectedConsoleSet,
  };
}

export function setHighlightedConsoleSet(
  highlightedConsoleSet: ConsoleSet | undefined,
  highlightedConsoleSetConsolesSection: number,
): SetHighlightedConsoleSetAction {
  return {
    type: 'SET_HIGHLIGHTED_CONSOLE_SET',
    highlightedConsoleSet: highlightedConsoleSet,
    highlightedConsoleSetConsolesSection: highlightedConsoleSetConsolesSection,
  };
}

export function setHighlightedLockingConsole(
  highlightedLockingConsole: LockingConsole | undefined,
): SetHighlightedLockingConsoleAction {
  return {
    type: 'SET_HIGHLIGHTED_LOCKING_CONSOLE',
    highlightedLockingConsole: highlightedLockingConsole,
  };
}

export function removePreselectedConsoleSet(
  consoleSetSection: number,
): RemovePreselectedConsoleSetAction {
  return {
    type: 'REMOVE_PRESELECTED_CONSOLE_SET',
    consoleSetSection: consoleSetSection,
  };
}

export function setSelectedSystemSeries(
  systemSeries: SystemSeries | undefined,
): SetSelectedSystemSeriesAction {
  return {
    type: 'SET_SELECTED_SYSTEM_SERIES',
    systemSeries: systemSeries,
  };
}

export const setPerformanceClassesOpen = (
  open: boolean,
): SetPerformanceClassesOpen => {
  return {
    type: 'SET_PERFORMANCE_CLASSES_OPEN',
    open: open,
  };
};

export const updateExpand = (value: boolean): UpdateExpand => {
  return {
    type: 'UPDATE_EXPAND',
    value: value,
  };
};

export function storeUser(user: LoggedInUser | undefined): StoreUserAction {
  return {
    type: 'STORE_USER_ACTION',
    user: user,
  };
}

export const updateSingleEdit = (
  value: boolean,
  valueKey?: string,
): UpdateSingleEdit => {
  return {
    type: 'UPDATE_SINGLE_EDIT',
    value: value,
    valueKey: valueKey,
  };
};

export const updateDownloadStatus = (value: boolean): UpdateDownloadStatus => {
  return {
    type: 'UPDATE_DOWNLOAD_STATUS',
    value: value,
  };
};

export const updateOverlayActive = (value: boolean): UpdateOverlayActive => {
  return {
    type: 'UPDATE_OVERLAY_ACTIVE',
    value: value,
  };
};

export const updateInputfieldFocus = (valueKey?: string): InputFieldFocus => {
  return {
    type: 'UPDATE_INPUT_FIELD_FOCUS',
    valueKey: valueKey,
  };
};

export function updateInputFromLogikal(
  inputFromLogikal: boolean,
): UpdateInputFromLogikal {
  return {
    type: 'UPDATE_INPUT_FROM_LOGIKAL',
    inputFromLogikal: inputFromLogikal,
  };
}

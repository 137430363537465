import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export function ResultsColumnHeader(props: { id: string }): ReactElement {
  return (
    <div className="console-results__title">
      <FormattedMessage id={props.id} />
    </div>
  );
}

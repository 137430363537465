import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import SimpleInputField, {
  AdminInputFieldNumber,
} from '../../elements/AdminInputField';
import TextArea from '../../elements/TextArea';
import { Switch } from '../../elements/Switch';
import './SeriesDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { z } from 'zod';
import {
  BasicProfile,
  ExchangeProfile,
  FrameProfileFacade,
  SashProfileFacade,
  Series,
  System,
} from '../../redux/admin/adminFacadeReducer';
import {
  changeSeries,
  createSeries,
  updateBasicProfile,
  updateExchangeProfile,
  updateFrameProfileFacade,
  updateSashProfileFacade,
} from '../../redux/admin/adminFacadeActions';
import {
  MenuPlacement,
  SelectField,
  SelectOption,
} from '../../elements/SelectField';
import { Material, MATERIAL_OPTIONS, UiConstants } from '../constants';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import { RangeOfApplication } from '../../redux/constants';
import { findById } from './helpers';
import { useSeries } from '../../hooks/selectorHooks';
import {
  FrameProfileRoof,
  SashProfileRoof,
} from '../../redux/admin/adminRoofReducer';
import {
  updateFrameProfileRoof,
  updateSashProfileRoof,
} from '../../redux/admin/adminRoofActions';
import AdminRadioButton from '../../elements/RadioButton';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import { useForm } from 'react-hook-form';
import { InputFieldNumber } from '../../elements/InputField';
import { zodResolver } from '@hookform/resolvers/zod';

interface AdminSeriesDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
  selectedSeries: Series | undefined;
  rangeOfApplication: RangeOfApplication;
}

const AdminSeriesDialog: FC<React.PropsWithChildren<AdminSeriesDialogProps>> = (
  props: AdminSeriesDialogProps,
) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SeriesForm>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(props.selectedSeries),
  });

  const allSystems = useSelector<AdminState, System[]>(
    state => state.adminFacade.systems,
  );

  const oeffnungsrichtungAuswaerts = watch('oeffnungsrichtungAuswaerts');
  const oeffnungsrichtungEinwaerts = watch('oeffnungsrichtungEinwaerts');
  const system = watch('system');
  const info = watch('info');
  const material = watch('material');
  const bautiefe = watch('bautiefe');
  const laengeEckverbinder = watch('laengeEckverbinder');
  const b2Auswaerts = watch('b2Auswaerts');
  const b2Einwaerts = watch('b2Einwaerts');
  const lichteabzugsmassAuswaerts = watch('lichteabzugsmassAuswaerts');
  const lichteabzugsmassEinwaerts = watch('lichteabzugsmassEinwaerts');
  const asAbstandAufgesetzteBaender = watch('asAbstandAufgesetzteBaender');
  const fluegelaufschlag = watch('fluegelaufschlag');
  const lockingDriveStroke = watch('lockingDriveStroke');
  const profileWeightPerMeter = watch('profileWeightPerMeter');
  const profileHeight = watch('profileHeight');
  const active = watch('active');
  const preview = watch('preview');
  const verriegelungMoeglich = watch('verriegelungMoeglich');

  useEffect(() => {
    reset(getDefaultValues(props.selectedSeries));
  }, [props.selectedSeries, reset]);

  function getDefaultValues(
    selectedSeries: Series | undefined,
  ): Partial<Series> {
    return {
      id: selectedSeries?.id,
      name: selectedSeries?.name || '',
      info: selectedSeries?.info || '',
      active: selectedSeries?.active || false,
      preview: selectedSeries?.preview || false,
      verriegelungMoeglich: selectedSeries?.verriegelungMoeglich || false,
      shortCode: selectedSeries?.shortCode || '',
      system: selectedSeries?.system,
      material: selectedSeries?.material || MATERIAL_OPTIONS[0].value,
      bautiefe: selectedSeries?.bautiefe,
      laengeEckverbinder: selectedSeries?.laengeEckverbinder,
      b2Auswaerts: selectedSeries?.b2Auswaerts || null,
      b2Einwaerts: selectedSeries?.b2Einwaerts || null,
      lichteabzugsmassAuswaerts:
        selectedSeries?.lichteabzugsmassAuswaerts || null,
      lichteabzugsmassEinwaerts:
        selectedSeries?.lichteabzugsmassEinwaerts || null,
      fluegelaufschlag: selectedSeries?.fluegelaufschlag,
      lockingDriveStroke: selectedSeries?.lockingDriveStroke || 38,
      profileWeightPerMeter: selectedSeries?.profileWeightPerMeter || null,
      profileHeight: selectedSeries?.profileHeight || null,
      asAbstandAufgesetzteBaender:
        selectedSeries?.asAbstandAufgesetzteBaender || null,
      oeffnungsrichtungEinwaerts:
        selectedSeries?.oeffnungsrichtungEinwaerts || false,
      oeffnungsrichtungAuswaerts:
        selectedSeries?.oeffnungsrichtungAuswaerts || false,
      rangeOfApplication: props.rangeOfApplication,
    };
  }

  function setSystemAndEditActiveStatus(system: System): void {
    if (!system.active) {
      setValue('active', false);
    }
    setValue('system', system);
  }

  function materialSelectOption(
    value: string,
  ): SelectOption<string> | undefined {
    return MATERIAL_OPTIONS.find(entry => entry.value === value);
  }

  function getActivationNotPossibleMessage(): string {
    function getSuffix(): string {
      if (system) {
        return 'System ' + system.name + ' muss aktiv sein.';
      } else {
        return 'Vorher System auswählen.';
      }
    }

    return `Aktivierung nicht möglich. ${getSuffix()}`;
  }

  function onSubmit(data: SeriesForm) {
    if (props.selectedSeries?.id) {
      dispatch(
        changeSeries(
          { id: props.selectedSeries.id!, ...data },
          props.rangeOfApplication,
        ),
      );
    } else {
      dispatch(createSeries(data));
    }
    props.setDialogIsShown(false);
  }

  return (
    <Dialog
      cancelAction={() => reset(getDefaultValues(props.selectedSeries))}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedSeries === undefined
          ? 'Neue Serie anlegen'
          : `Serie ${props.selectedSeries.name} bearbeiten`
      }
      componentClass="full-view-dialog"
      key={props.selectedSeries?.id}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          reset(getDefaultValues(props.selectedSeries));
          props.setDialogIsShown(false);
        },
        saveAction: handleSubmit(onSubmit, (errors: any) => {
          console.log(errors);
        }),
        primaryActionLabelText:
          props.selectedSeries === undefined ? 'Anlegen' : 'Speichern',
      }}
    >
      <FormLayout additionalClass="series-data-view-dialog">
        <SimpleInputField
          label="Name *"
          placeholder="Name der Serie"
          additionalClass="series-data-view-dialog__name"
          {...register('name')}
          errorMessage={errors.name?.message}
        />
        <TextArea
          label="Information"
          additionalClass="series-data-view-dialog__info"
          placeholder="Informationen zur Serie"
          onChange={(value: string) => setValue('info', value)}
          value={info}
        />

        <SelectField
          additionalClass="series-data-view-dialog__system"
          label="System *"
          value={
            system
              ? {
                  value: system.id.toString(),
                  label: system.name,
                }
              : undefined
          }
          action={newValue => {
            setSystemAndEditActiveStatus(findById(+newValue.value, allSystems));
          }}
          options={allSystems.map(system => ({
            label: system.name,
            value: system.id.toString(),
          }))}
          name="project-country"
          searchable={true}
          placeholder="z.B. Schüco"
          menuPlacement={MenuPlacement.BOTTOM}
          errorMessage={errors.system?.message}
        />
        <SelectField
          additionalClass="series-data-view-dialog__material"
          key={props.selectedSeries?.material}
          label="Material *"
          value={material ? materialSelectOption(material) : undefined}
          action={newValue => {
            setValue('material', newValue.value as Material);
          }}
          options={MATERIAL_OPTIONS}
          name="series-material"
          searchable={true}
          placeholder="z.B. Aluminium"
          menuPlacement={MenuPlacement.BOTTOM}
          errorMessage={errors.material?.message}
        />

        <SimpleInputField
          additionalClass="series-data-view-dialog__short-name"
          label="Kürzel *"
          placeholder="z.B. SC für Schüco"
          {...register('shortCode')}
          errorMessage={errors.shortCode?.message}
        />
        <AdminInputFieldNumber
          additionalClass="series-data-view-dialog__building-depth"
          label="Bautiefe (mm) *"
          placeholder="z.B. 42"
          {...register('bautiefe')}
          value={bautiefe}
          onChange={value => setValue('bautiefe', value!)}
          errorMessage={errors.bautiefe?.message}
        />
        <InputFieldNumber
          additionalClass="series-data-view-dialog__edge-length-connection"
          label="Länge der Eckverbindung (mm) *"
          placeholder="z.B. 42"
          value={laengeEckverbinder}
          onChange={value => setValue('laengeEckverbinder', value!)}
          errorMessage={errors.laengeEckverbinder?.message}
        />

        <div className="series-data-view-dialog__opening-type">
          <FormLayoutSubgroupTitle>Öffnungsrichtung</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={oeffnungsrichtungEinwaerts && !oeffnungsrichtungAuswaerts}
            name="einwärts"
            onChange={() => {
              setValue('oeffnungsrichtungEinwaerts', true);
              setValue('oeffnungsrichtungAuswaerts', false);
            }}
          />
          <AdminRadioButton
            checked={oeffnungsrichtungAuswaerts && !oeffnungsrichtungEinwaerts}
            name="auswärts"
            onChange={() => {
              setValue('oeffnungsrichtungAuswaerts', true);
              setValue('oeffnungsrichtungEinwaerts', false);
            }}
          />
          <AdminRadioButton
            checked={oeffnungsrichtungEinwaerts && oeffnungsrichtungAuswaerts}
            name="einwärts und auswärts"
            onChange={() => {
              setValue('oeffnungsrichtungEinwaerts', true);
              setValue('oeffnungsrichtungAuswaerts', true);
            }}
          />
          {errors.oeffnungsrichtungAuswaerts && (
            <div className="input-field__error">
              {errors.oeffnungsrichtungAuswaerts.message}
            </div>
          )}
        </div>

        <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__distance-to-hinge">
          <FormLayoutSubgroupTitle>
            Abstand Rahmenaußenkante zum Drehpunkt, b2 (mm)
          </FormLayoutSubgroupTitle>
          {oeffnungsrichtungAuswaerts && (
            <InputFieldNumber
              label={`auswärts${
                props.rangeOfApplication === RangeOfApplication.ROOF ? ' *' : ''
              }`}
              placeholder="z.B. 42"
              value={b2Auswaerts}
              onChange={value => setValue('b2Auswaerts', value!)}
              errorMessage={errors.b2Auswaerts?.message}
            />
          )}
          {oeffnungsrichtungEinwaerts && (
            <InputFieldNumber
              label={`einwärts${
                props.rangeOfApplication === RangeOfApplication.ROOF ? ' *' : ''
              }`}
              placeholder="z.B. 42"
              value={b2Einwaerts}
              onChange={value => setValue('b2Einwaerts', value!)}
              errorMessage={errors.b2Einwaerts?.message}
            />
          )}
        </div>
        <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__exhaust-dimension">
          <FormLayoutSubgroupTitle>
            Lichteabzugsmaß (mm)
          </FormLayoutSubgroupTitle>
          {oeffnungsrichtungAuswaerts && (
            <InputFieldNumber
              label="auswärts *"
              placeholder="z.B. 42"
              value={lichteabzugsmassAuswaerts}
              onChange={value => setValue('lichteabzugsmassAuswaerts', value!)}
              errorMessage={errors.lichteabzugsmassAuswaerts?.message}
            />
          )}
          {oeffnungsrichtungEinwaerts && (
            <InputFieldNumber
              label="einwärts *"
              placeholder="z.B. 42"
              value={lichteabzugsmassEinwaerts}
              onChange={value => setValue('lichteabzugsmassEinwaerts', value!)}
              errorMessage={errors.lichteabzugsmassEinwaerts?.message}
            />
          )}
        </div>

        <div className="form-layout__sub-group series-data-view-dialog__as-distance">
          <div className="form-layout__sub-group-title">
            Abstand Flügelkante zu Drehpunkt (As) (mm)
          </div>
          <InputFieldNumber
            label="aufgesetzte Bänder"
            placeholder="z.B. 42"
            value={asAbstandAufgesetzteBaender}
            onChange={value => setValue('asAbstandAufgesetzteBaender', value!)}
            errorMessage={errors.asAbstandAufgesetzteBaender?.message}
          />
        </div>
        <div className="form-layout__sub-group form-layout__sub-group--3 series-data-view-dialog__sash-addition">
          <FormLayoutSubgroupTitle>Weitere</FormLayoutSubgroupTitle>
          <InputFieldNumber
            additionalClass=""
            label="Flügelaufschlag (mm) *"
            placeholder="z.B. 42,2"
            value={fluegelaufschlag}
            onChange={value => setValue('fluegelaufschlag', value!)}
            errorMessage={errors.fluegelaufschlag?.message}
          />
          <InputFieldNumber
            additionalClass=""
            label="Verriegelhungshub (mm)*"
            placeholder="z.B. 42,2"
            value={lockingDriveStroke}
            onChange={value => setValue('lockingDriveStroke', value!)}
            errorMessage={errors.lockingDriveStroke?.message}
          />
        </div>

        {material === Material.STAHL && (
          <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__steel-weight-calculation-values">
            <FormLayoutSubgroupTitle>
              Für Fluegelgewichtsberechnung
            </FormLayoutSubgroupTitle>
            <InputFieldNumber
              label="Profilgewicht pro laufendem Meter *"
              placeholder="z.B. 42"
              value={profileWeightPerMeter}
              onChange={value => setValue('profileWeightPerMeter', value!)}
              errorMessage={errors.profileWeightPerMeter?.message}
            />
            <InputFieldNumber
              label="Flügelprofilhöhe (fh) *"
              placeholder="z.B. 42"
              value={profileHeight}
              onChange={value => setValue('profileHeight', value!)}
              errorMessage={errors.profileHeight?.message}
            />
          </div>
        )}

        <div className="series-data-view-dialog__active-switch">
          <Switch
            labelText={
              system?.active ? 'aktiv' : getActivationNotPossibleMessage()
            }
            turnedOn={active}
            onChange={value => setValue('active', value)}
            disabled={!system?.active}
          />
        </div>
        <div className="series-data-view-dialog__preview-switch">
          <Switch
            labelText="Preview"
            turnedOn={preview}
            onChange={value => setValue('preview', value)}
          />
        </div>

        <div className="series-data-view-dialog__locking-available">
          <Switch
            labelText={'Verriegelung möglich'}
            turnedOn={verriegelungMoeglich}
            onChange={value => setValue('verriegelungMoeglich', value)}
          />
        </div>
      </FormLayout>
    </Dialog>
  );
};

const SeriesDataView: FC<
  React.PropsWithChildren<{
    rangeOfApplication: RangeOfApplication;
  }>
> = props => {
  const allSeries = useSeries(props.rangeOfApplication);
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [selectedSeriesID, setSelectedSeriesID] = useState<undefined | number>(
    undefined,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    allSeries,
    searchString,
    ['name', 'shortCode'],
    (s: Series) => [s.system.name],
  );

  const selectedSeries =
    allSeries.find(series => series.id === selectedSeriesID) || undefined;

  const allFrameProfilesFacade = useSelector<AdminState, FrameProfileFacade[]>(
    state => state.adminFacade.frameProfiles,
  );
  const allSashProfilesFacade = useSelector<AdminState, SashProfileFacade[]>(
    state => state.adminFacade.sashProfiles,
  );
  const allFrameProfilesRoof = useSelector<AdminState, FrameProfileRoof[]>(
    state => state.adminRoof.frameProfiles,
  );
  const allSashProfilesRoof = useSelector<AdminState, SashProfileRoof[]>(
    state => state.adminRoof.sashProfiles,
  );
  const allExchangeProfiles = useSelector<AdminState, ExchangeProfile[]>(
    state => state.adminFacade.exchangeProfiles,
  );
  const allBasicProfiles = useSelector<AdminState, BasicProfile[]>(
    state => state.adminFacade.basicProfiles,
  );

  function updateFacadeProfiles(series: Series): void {
    allFrameProfilesFacade.forEach(frameProfileFacade => {
      if (frameProfileFacade.seriesId === series.id) {
        dispatch(
          updateFrameProfileFacade({ ...frameProfileFacade, active: false }),
        );
      }
    });
    allSashProfilesFacade.forEach(sashProfileFacade => {
      if (sashProfileFacade.seriesId === series.id) {
        dispatch(
          updateSashProfileFacade({ ...sashProfileFacade, active: false }),
        );
      }
    });
  }

  function updateRoofProfiles(series: Series): void {
    allFrameProfilesRoof.forEach(frameProfileRoof => {
      if (frameProfileRoof.seriesId === series.id) {
        dispatch(
          updateFrameProfileRoof({ ...frameProfileRoof, active: false }),
        );
      }
    });
    allSashProfilesRoof.forEach(sashProfileRoof => {
      if (sashProfileRoof.seriesId === series.id) {
        dispatch(updateSashProfileRoof({ ...sashProfileRoof, active: false }));
      }
    });
  }

  function switchActiveAction(series: Series): void {
    if (series.active) {
      alert('Alle Profile der Serie ' + series.name + ' wurden deaktiviert.');
    }
    if (props.rangeOfApplication === RangeOfApplication.FACADE) {
      updateFacadeProfiles(series);
    }
    if (props.rangeOfApplication === RangeOfApplication.ROOF) {
      updateRoofProfiles(series);
    }

    allBasicProfiles.forEach(basicProfile => {
      if (basicProfile.seriesId === series.id) {
        dispatch(updateBasicProfile({ ...basicProfile, active: false }));
      }
    });
    allExchangeProfiles.forEach(exchangeProfile => {
      if (exchangeProfile.seriesId === series.id) {
        dispatch(updateExchangeProfile({ ...exchangeProfile, active: false }));
      }
    });

    dispatch(
      changeSeries(
        { ...series, active: !series.active },
        props.rangeOfApplication,
      ),
    );
  }

  function enableEditSystem(s: Series): void {
    setDialogIsShown(true);
    setSelectedSeriesID(s.id);
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedSeriesID(undefined);
  }

  function getCurrentTableContent(): Series[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <div>
      <AdminSeriesDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
        selectedSeries={selectedSeries}
        rangeOfApplication={props.rangeOfApplication}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Serien</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText={'Serie suchen...'}
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <Table subNavigation={false}>
        <TableHeader>
          <th>Name</th>
          <th>Kürzel</th>
          <th>Info</th>
          <th>Bautiefe</th>
          <th>System</th>
          <th>Aktiv</th>
          <th>Aktion</th>
        </TableHeader>
        <tbody>
          {getCurrentTableContent().map(series => (
            <TableRow key={series.name}>
              <td>{series.name}</td>
              <td>{series.shortCode}</td>
              <td>{series.info}</td>
              <td>{series.bautiefe}</td>
              <td>{series.system.name}</td>
              <td>
                <Switch
                  key={series.id}
                  turnedOn={series.active}
                  onChange={(b: boolean) => switchActiveAction(series)}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    enableEditSystem(series);
                  }}
                >
                  {UiConstants.EDIT}
                </button>
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </div>
  );
};

export default SeriesDataView;

const openingDirection = z
  .object({
    oeffnungsrichtungEinwaerts: z.boolean(),
    oeffnungsrichtungAuswaerts: z.boolean(),
  })
  .refine(
    data => {
      return data.oeffnungsrichtungAuswaerts || data.oeffnungsrichtungEinwaerts;
    },
    {
      message: 'Mindestens eine Öffnungsrichtung muss ausgewählt werden',
      path: ['oeffnungsrichtungAuswaerts'],
    },
  );
const rangeOfApplication = z.object({
  rangeOfApplication: z.nativeEnum(RangeOfApplication),
});

const exhaustDimensionSchema = openingDirection
  .and(
    z.object({
      lichteabzugsmassAuswaerts: z.number().nullable(),
      lichteabzugsmassEinwaerts: z.number().nullable(),
    }),
  )
  .refine(
    data => {
      if (data.oeffnungsrichtungAuswaerts) {
        return data.lichteabzugsmassAuswaerts;
      }
      return true;
    },
    {
      message: 'Lichteabzugsmass Auswaerts muss ausgefüllt werden',
      path: ['lichteabzugsmassAuswaerts'],
    },
  )
  .refine(
    data => {
      if (data.oeffnungsrichtungEinwaerts) {
        return data.lichteabzugsmassEinwaerts;
      }
      return true;
    },
    {
      message: 'Lichteabzugsmass Einwaerts muss ausgefüllt werden',
      path: ['lichteabzugsmassEinwaerts'],
    },
  );

const b2Schema = openingDirection
  .and(
    z.object({
      b2Auswaerts: z.number().nullable(),
      b2Einwaerts: z.number().nullable(),
    }),
  )
  .and(rangeOfApplication)
  .refine(
    data => {
      if (
        data.rangeOfApplication === RangeOfApplication.ROOF &&
        data.oeffnungsrichtungEinwaerts
      ) {
        return data.b2Einwaerts;
      }
      return true;
    },
    { path: ['b2Einwaerts'], message: 'b2Einwaerts muss ausgefüllt werden' },
  )
  .refine(
    data => {
      if (
        data.rangeOfApplication === RangeOfApplication.ROOF &&
        data.oeffnungsrichtungAuswaerts
      ) {
        return data.b2Auswaerts !== undefined;
      }
      return true;
    },
    { path: ['b2Auswaerts'], message: 'b2Auswaerts muss ausgefüllt werden' },
  );

const asAbstandAufgesetzteBaenderSchema = z
  .object({
    asAbstandAufgesetzteBaender: z.number().nullable(),
  })
  .and(rangeOfApplication)
  .refine(
    data => {
      if (data.rangeOfApplication === RangeOfApplication.ROOF) {
        return data.asAbstandAufgesetzteBaender;
      }
      return true;
    },
    {
      path: ['asAbstandAufgesetzteBaender'],
      message: 'aufgesetzte Bänder muss ausgefüllt werden',
    },
  );

const baseSchema = z
  .object({
    name: z.string().min(1, 'Name muss ausgefüllt werden'),
    system: z
      .any()
      .transform(value => value as System)
      .refine(value => value, {
        message: 'System muss ausgefüllt werden',
      }),
    shortCode: z.string().min(1, 'Kürzel muss ausgefüllt werden'),
    bautiefe: z.number({ message: 'Bautiefe muss ausgefüllt werden' }),
    laengeEckverbinder: z.number({
      message: 'Länge der Eckverbindung muss ausgefüllt werden',
    }),
    active: z.boolean(),
    preview: z.boolean(),
    asAbstandAufgesetzteBaender: z.number().nullable(),
    b2Einwaerts: z.number().nullable(),
    b2Auswaerts: z.number().nullable(),
    fluegelaufschlag: z.number({
      message: 'Flügelaufschlag muss ausgefüllt werden',
    }),
    material: z.nativeEnum(Material),
    info: z.string(),

    verriegelungMoeglich: z.boolean(),
    profileHeight: z.number().nullable(),
    profileWeightPerMeter: z.number().nullable(),

    lockingDriveStroke: z.number(),
  })
  .refine(
    data => {
      if (data.material === Material.STAHL) {
        return data.profileHeight;
      }
      return true;
    },
    {
      message: 'Flügelprofilhöhe muss ausgefüllt werden',
      path: ['profileHeight'],
    },
  )
  .refine(
    data => {
      if (data.material === Material.STAHL) {
        return data.profileWeightPerMeter;
      }
      return true;
    },
    {
      message: 'Profilgewicht pro laufendem Meter muss ausgefüllt werden',
      path: ['profileWeightPerMeter'],
    },
  );

const schema = baseSchema
  .and(exhaustDimensionSchema)
  .and(b2Schema)
  .and(asAbstandAufgesetzteBaenderSchema);

type SeriesForm = z.infer<typeof schema>;

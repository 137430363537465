import { AnyAction, Reducer } from 'redux';
import { Hint } from './calculationResultReducer';
import { ConsoleSet } from './staticDataReducer';
import {
  BaseProfile,
  ExchangeProfile,
  FrameProfile,
  SashProfile,
  SystemSeries,
} from './profileDataReducer';
import { Project, Window } from './projectsReducer';
import { ValueKey } from './valueKey';
import { RequestTypes } from './httpClient';
import { ParametersState } from './parametersReducer';
import { LockingConsole, replaceElement } from './admin/adminFacadeReducer';
import { ReactNode } from 'react';
import _ from 'lodash';

export enum Locale {
  DE = 'de',
  EN = 'en',
  PL = 'pl',
  CS = 'cs',
  DA = 'da',
  ES = 'es',
  ET = 'et',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
  PT = 'pt',
  RU = 'ru',
  SV = 'sv',
  TR = 'tr',
  LT = 'lt',
  LV = 'lv',
}

export enum ProjectScopes {
  USER = 'USER',
  COMPANY = 'COMPANY',
  ALL = 'ALL',
}

export enum Pages {
  CALCULATION,
  SELECTED_PRODUCTS,
  SELECTED_PRODUCTS_NRWG,
  CONSOLE_CALCULATION,
  PROJECTS_MANAGER,
  TEMPLATES_MANAGER,
  DOCUMENTS_AREA,
}

export interface Calculating {
  [index: string]: boolean;
}

export interface WindowTemplate extends WindowTemplateWithOptionalId {
  id: number;
}

export interface WindowTemplateWithOptionalId {
  id?: number;
  name: string;
  description: string;
  calculationParameters: ParametersState;
  nrwg: boolean;
  rwa: boolean;
}

export interface UIState {
  preselectedConsoleSets: ConsoleSet[];
  highlightedConsoleSetConsolesSection: number;
  acceptedHints: Hint[];
  selectedNRWGConsoleSet: ConsoleSet | undefined;
  calculating: Calculating;
  markedDriveName: string | undefined;
  expanded: boolean;
  singleEdit: { single: boolean; valueKey?: ValueKey };
  focusedField: ValueKey;
  downloadActive: boolean;
  activePage: number;
  selectedConsoleSets: ConsoleSet[] | undefined;
  selectedLockingConsole: LockingConsole | undefined;
  highlightedConsoleSet: ConsoleSet | undefined;
  highlightedLockingConsole: LockingConsole | undefined;
  overlayIsActive: boolean;
  selectedProject: Project | undefined;
  selectedWindow: Window | undefined;
  projectNavigationOpen: number;
  performanceClassesOpen: boolean;
  templatesForCurrentUser: WindowTemplate[];
  projectScope: ProjectScopes;
  alpha: boolean;
  dialog: ReactNode;
  inputFromLogikal: boolean;
}

export const INITIAL_STATE: UIState = {
  preselectedConsoleSets: [],
  highlightedConsoleSetConsolesSection: 0,
  acceptedHints: [],
  highlightedConsoleSet: undefined,
  highlightedLockingConsole: undefined,
  selectedConsoleSets: [],
  selectedLockingConsole: undefined,
  selectedNRWGConsoleSet: undefined,
  selectedWindow: undefined,
  calculating: { [RequestTypes.CALCULATION]: true },
  markedDriveName: undefined,
  expanded: false,
  singleEdit: { single: false },
  focusedField: ValueKey.VALUEKEY_NONE,
  downloadActive: false,
  activePage: 0,
  overlayIsActive: false,
  selectedProject: undefined,
  projectNavigationOpen: 0,
  performanceClassesOpen: true,
  projectScope: ProjectScopes.USER,
  alpha: false,
  templatesForCurrentUser: [],
  dialog: undefined,
  inputFromLogikal: false,
};

const uiStateReducer: Reducer = (state: UIState, action: AnyAction) => {
  if (state === undefined) {
    return {
      ...INITIAL_STATE,
      alpha: !!window.location.search.match(/alpha/),
      //following is for admin development
      // alpha: true || !!window.location.search.match(/alpha/),
    };
  }

  switch (action.type) {
    case 'SHOW_DIALOG': {
      return {
        ...state,
        dialog: action.dialog,
      };
    }

    case 'SET_USER_IS_AUTHENTICATED': {
      return { ...state, userIsAuthenticated: action.userIsAuthenticated };
    }

    case 'SET_USER_IS_AUTHORIZED': {
      return { ...state, userIsAuthorized: action.userIsAuthorized };
    }

    case 'ADD_WINDOW_TEMPLATE': {
      return {
        ...state,
        templatesForCurrentUser: [
          action.template,
          ...(state.templatesForCurrentUser || []),
        ],
      };
    }

    case 'UPDATE_TEMPLATES_ACTION': {
      return {
        ...state,
        templatesForCurrentUser: action.templates,
      };
    }

    case 'UPDATE_TEMPLATE_ACTION': {
      return {
        ...state,
        templatesForCurrentUser: replaceElement(
          state.templatesForCurrentUser,
          action.template,
        ),
      };
    }

    case 'DELETE_WINDOW_TEMPLATE': {
      return {
        ...state,
        templatesForCurrentUser: state.templatesForCurrentUser.filter(
          t => t.id !== action.template.id,
        ),
      };
    }

    case 'UPDATE_LOCALE':
      return {
        ...state,
        locale: action.locale,
      };

    case 'UPDATE_OVERLAY_ACTIVE':
      return {
        ...state,
        overlayIsActive: action.value,
      };

    case 'CALCULATING':
      return {
        ...state,
        calculating: { ...state.calculating, [action.requestType]: true },
      };

    case 'FINISHED_CALCULATION':
      return {
        ...state,
        calculating: { ...state.calculating, [action.requestType]: false },
      };

    case 'SET_MARKED_DRIVE': {
      return {
        ...state,
        markedDriveName: action.name,
        markedDriveList: action.list,
      };
    }
    case 'UPDATE_EXPAND':
      return {
        ...state,
        expanded: action.value,
      };

    case 'UPDATE_SINGLE_EDIT':
      return {
        ...state,
        singleEdit: { single: action.value, valueKey: action.valueKey },
      };

    case 'UPDATE_DOWNLOAD_STATUS':
      return {
        ...state,
        downloadActive: action.value,
      };

    case 'UPDATE_INPUT_FIELD_FOCUS':
      return {
        ...state,
        focusedField: action.valueKey,
      };

    case 'SET_ACTIVE_PAGE':
      return {
        ...state,
        activePage: action.page,
      };

    case 'SET_SELECTED_CONSOLE_SET':
      return {
        ...state,
        selectedConsoleSets: action.selectedConsoleSets,
      };

    case 'SET_SELECTED_LOCKING_CONSOLE':
      return {
        ...state,
        selectedLockingConsole: action.selectedLockingConsole,
      };

    case 'STORE_SELECTED_NRWG_CONSOLE_SET':
      return {
        ...state,
        selectedNRWGConsoleSet: action.selectedNRWGConsoleSet,
      };

    case 'SET_HIGHLIGHTED_CONSOLE_SET':
      return {
        ...state,
        highlightedConsoleSet: action.highlightedConsoleSet,
        highlightedConsoleSetConsolesSection:
          action.highlightedConsoleSetConsolesSection,
      };
    case 'SET_HIGHLIGHTED_LOCKING_CONSOLE':
      return {
        ...state,
        highlightedLockingConsole: action.highlightedLockingConsole,
      };
    case 'SET_MARKED_SERIES':
      return {
        ...state,
        markedSeries: action.markedSeries,
      };
    case 'SET_MARKED_SASH_PROFILE':
      return {
        ...state,
        markedSashProfile: action.markedSashProfile,
      };
    case 'SET_MARKED_FRAME_PROFILE':
      return {
        ...state,
        markedFrameProfile: action.markedFrameProfile,
      };
    case 'SET_MARKED_EXCHANGE_PROFILE':
      return {
        ...state,
        markedExchangeProfile: action.markedExchangeProfile,
      };
    case 'SET_MARKED_BASE_PROFILE':
      return {
        ...state,
        markedBaseProfile: action.markedBaseProfile,
      };
    case 'SET_SELECTED_SERIES':
      return {
        ...state,
        selectedSeries: action.selectedSeries,
      };
    case 'SET_SELECTED_SASH_PROFILE':
      return {
        ...state,
        selectedSashProfile: action.selectedSashProfile,
      };
    case 'SET_SELECTED_FRAME_PROFILE':
      return {
        ...state,
        selectedFrameProfile: action.selectedFrameProfile,
      };
    case 'SET_SELECTED_EXCHANGE_PROFILE':
      return {
        ...state,
        selectedExchangeProfile: action.selectedExchangeProfile,
      };
    case 'SET_SELECTED_BASE_PROFILE':
      return {
        ...state,
        selectedBaseProfile: action.selectedBaseProfile,
      };
    case 'SET_SELECTED_PROJECT': {
      return {
        ...state,
        selectedProject: action.project,
      };
    }
    case 'SET_SELECTED_WINDOW':
      return { ...state, selectedWindow: action.window };
    case 'SET_PROJECT_NAVIGATION_OPEN':
      return {
        ...state,
        projectNavigationOpen: action.open
          ? state.projectNavigationOpen + 1
          : 0,
      };
    case 'SET_PERFORMANCE_CLASSES_OPEN':
      return {
        ...state,
        performanceClassesOpen: action.open,
      };
    case 'SET_PROJECT_SCOPE':
      return {
        ...state,
        projectScope: action.scope,
      };
    case 'TOGGLE_ACCEPTED_HINT': {
      const newState = _.cloneDeep(state);
      const acceptedHint = newState.acceptedHints.find(
        hint => hint.type === action.hint.type,
      );

      if (acceptedHint) {
        newState.acceptedHints = newState.acceptedHints.filter(
          hint => hint.type !== action.hint.type,
        );
      } else {
        const actionHint = _.cloneDeep(action.hint);
        actionHint.accepted = true;
        newState.acceptedHints.push(actionHint);
      }

      return newState;
    }
    case 'STORE_ACCEPTED_HINTS': {
      const newState = _.cloneDeep(state);

      newState.acceptedHints = action.hints;

      return newState;
    }
    case 'PRE_SELECTED_CONSOLE_SET': {
      const newState = _.cloneDeep(state);

      newState.preselectedConsoleSets[action.consoleSetSection] =
        action.consoleSet;

      return newState;
    }

    case 'CLEAR_PRE_SELECTED_CONSOLE_SET': {
      const newState = _.cloneDeep(state);

      newState.preselectedConsoleSets = [];

      return newState;
    }
    case 'REMOVE_PRESELECTED_CONSOLE_SET': {
      const newState = _.cloneDeep(state);

      newState.preselectedConsoleSets.splice(action.consoleSetSection, 1);

      return newState;
    }

    case 'UPDATE_INPUT_FROM_LOGIKAL': {
      return { ...state, inputFromLogikal: action.inputFromLogikal };
    }

    default:
      return state;
  }
};

export default uiStateReducer;

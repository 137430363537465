import LANGUAGELIST_DE from '../country_lists/de.json';
import LANGUAGELIST_EN from '../country_lists/en.json';
import LANGUAGELIST_PL from '../country_lists/pl.json';
import LANGUAGELIST_CS from '../country_lists/cs.json';
import LANGUAGELIST_DA from '../country_lists/da.json';
import LANGUAGELIST_ES from '../country_lists/es.json';
import LANGUAGELIST_ET from '../country_lists/et.json';
import LANGUAGELIST_FR from '../country_lists/fr.json';
import LANGUAGELIST_IT from '../country_lists/it.json';
import LANGUAGELIST_NL from '../country_lists/nl.json';
import LANGUAGELIST_PT from '../country_lists/pt.json';
import LANGUAGELIST_RU from '../country_lists/ru.json';
import LANGUAGELIST_SV from '../country_lists/sv.json';
import LANGUAGELIST_TR from '../country_lists/tr.json';
import LANGUAGELIST_LT from '../country_lists/lt.json';
import LANGUAGELIST_LV from '../country_lists/lv.json';

export const HINT_CONFIRMATION_LABELS: [string, string] = [
  'RETRACT_HINT_CONFIRMATION',
  'HINT_CONFIRMATION',
];

export enum TabOptions {
  OPTION_SASHWEIGHT = 'SASHWEIGHT',
  OPTION_THICKNESS = 'THICKNESS',
  OPTION_PERCENT_TO_HINGE = 'PERCENT',
  OPTION_MILLIMETRE = 'MILLIMETRE',
  OPTION_PROFILE_ENTRY = 'PROFILE_ENTRY',
  OPTION_FREE_ENTRY = 'FREE_ENTRY',
  OPTION_OPENING_ANGLE = 'OPENING_ANGLE',
  OPTION_OPENING_STROKE = 'OPENING_STROKE',
}

export enum NrwgManualArticleNumbers {
  FACADE = '99.860.29',
  ROOF = '99.860.30',
}

export interface CountryOption {
  alpha2: string;
  name: string;
}

export const LANGUAGE_LISTS = {
  de: LANGUAGELIST_DE,
  en: LANGUAGELIST_EN,
  pl: LANGUAGELIST_PL,
  cs: LANGUAGELIST_CS,
  da: LANGUAGELIST_DA,
  es: LANGUAGELIST_ES,
  et: LANGUAGELIST_ET,
  fr: LANGUAGELIST_FR,
  it: LANGUAGELIST_IT,
  nl: LANGUAGELIST_NL,
  pt: LANGUAGELIST_PT,
  ru: LANGUAGELIST_RU,
  sv: LANGUAGELIST_SV,
  tr: LANGUAGELIST_TR,
  lt: LANGUAGELIST_LT,
  lv: LANGUAGELIST_LV,
};

export enum RangeOfApplication {
  FACADE = 'FACADE',
  ROOF = 'ROOF',
}

export enum LockingDrive {
  VLD = 'VLD',
  FRA_11 = 'FRA_11',
  WITHOUT_LOCKING_DRIVE = 'WITHOUT_LOCKING_DRIVE',
}

export enum OpeningType {
  FENSTER_OEFFNUNGSART_DREH = 'FENSTER_OEFFNUNGSART_DREH',
  FENSTER_OEFFNUNGSART_KIPP = 'FENSTER_OEFFNUNGSART_KIPP',
  FENSTER_OEFFNUNGSART_KLAPP = 'FENSTER_OEFFNUNGSART_KLAPP',
  FENSTER_OEFFNUNGSART_SENK_KLAPP = 'FENSTER_OEFFNUNGSART_SENK_KLAPP',
}

export enum OpeningSide {
  OPENING_SIDE_LEFT = 'OPENING_SIDE_LEFT',
  OPENING_SIDE_RIGHT = 'OPENING_SIDE_RIGHT',
}

export const OpeningTypes = Object.keys(OpeningType).filter(
  o => !o.includes('SENK_KLAPP'),
);

export enum OpeningDirection {
  FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS = 'FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS',
  FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS = 'FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS',
}

export enum GeometricArea {
  ONLY_RECTANGLE = 'ONLY_RECTANGLE',
  TWO_TRIANGLES_RECTANGLE = 'TWO_TRIANGLES_RECTANGLE',
  ONE_TRIANGLES_RECTANGLE = 'ONE_TRIANGLES_RECTANGLE',
}

export enum AssemblyType {
  ASSEMBLY_TYPE_FRAME = 'ASSEMBLY_TYPE_FRAME',
  ASSEMBLY_TYPE_SASH = 'ASSEMBLY_TYPE_SASH',
}

export enum AssemblyOnFrame {
  INTEGRATED = 'INTEGRATED',
  ON_TOP = 'ON_TOP',
}

export enum DrivePosition {
  ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE = 'ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE',
  ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH = 'ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH',
  ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE = 'ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE',
}

export enum Voltage {
  ANTRIEB_SPANNUNG_24V = 'ANTRIEB_SPANNUNG_24V',
  ANTRIEB_SPANNUNG_230V = 'ANTRIEB_SPANNUNG_230V',
}

export enum DriveType {
  ANTRIEB_TYP_KETTE = 'ANTRIEB_TYP_KETTE',
  ANTRIEB_TYP_ZAHNSTANGE = 'ANTRIEB_TYP_ZAHNSTANGE',
}

export interface DriveSeries {
  id: number;
  name: string;
  driveType: DriveType;
}

export enum Application {
  SINGLE_FLAP = 'einzelklappe',
  DOUBLE_FLAP_SADDLE_ROOF_BOTTOM_HUNG_BOTTOM_HUNG = 'doubleFlapSaddleRoofBottomHungBottomHung',
  SINGLE_FLAP_TOP_HUNG = 'einzelklappeklapp',
  DOUBLE_FLAP_PENT_ROOF_BOTTOM_TOP_HUNG = 'doubleFlapPentRoofBottomTopHung',
  DOUBLE_FLAP_BARREL_ROOF = 'doppelklappetonnendach',
  TOP_HUNG = 'klapp',
  BOTTOM_HUNG = 'kipp',
  SIDE_HUNG = 'dreh',
  DOUBLE_FLAP_PENT_ROOF_SIDE_HUNG_SIDE_HUNG = 'doubleFlapSideHungSideHungPentRoof',
  DOUBLE_FLAP_SADDLE_ROOF_SIDE_HUNG_SIDE_HUNG = 'doubleFlapSideHungSideHungSaddleRoof',
}

export enum SideWindConsideration {
  WITH_CONSIDERATION = 'WITH_CONSIDERATION',
}

export enum AERODYNAMIC_CASE {
  AERODYNAMIC_CASE_2560 = 'AERODYNAMIC_CASE_2560',
  AERODYNAMIC_CASE_2560_OHNE_WLW = 'AERODYNAMIC_CASE_2560_OHNE_WLW',
  AERODYNAMIC_CASE_0015 = 'AERODYNAMIC_CASE_0015',
  AERODYNAMIC_CASE_1630 = 'AERODYNAMIC_CASE_1630',
  AERODYNAMIC_CASE_0230 = 'AERODYNAMIC_CASE_0230',
  AERODYNAMIC_CASE_0215WLW3 = 'AERODYNAMIC_CASE_0215WLW3',
  AERODYNAMIC_CASE_0224WLW3 = 'AERODYNAMIC_CASE_0224WLW3',
  AERODYNAMIC_CASE_1630WLW3 = 'AERODYNAMIC_CASE_1630WLW3',
  AERODYNAMIC_CASE_3150WLW3 = 'AERODYNAMIC_CASE_3150WLW3',
  AERODYNAMIC_CASE_0015OWLW = 'AERODYNAMIC_CASE_0015OWLW',
  AERODYNAMIC_CASE_1630OWLW = 'AERODYNAMIC_CASE_1630OWLW',
  AERODYNAMIC_CASE_0030OWLW = 'AERODYNAMIC_CASE_0030OWLW',
  AERODYNAMIC_CASE_0020WLW = 'AERODYNAMIC_CASE_0020WLW',
  AERODYNAMIC_CASE_0020OWLW = 'AERODYNAMIC_CASE_0020OWLW',
  AERODYNAMIC_CASE_0020WLWBLE1200 = 'AERODYNAMIC_CASE_0020WLWBLE1200',
  AERODYNAMIC_CASE_0020WLWBS1200 = 'AERODYNAMIC_CASE_0020WLWBS1200',
}

export const AERODYNAMIC_CASES_WITHOUT_WIND_DEFLECTOR = [
  AERODYNAMIC_CASE.AERODYNAMIC_CASE_2560_OHNE_WLW,
  AERODYNAMIC_CASE.AERODYNAMIC_CASE_0015OWLW,
  AERODYNAMIC_CASE.AERODYNAMIC_CASE_0030OWLW,
  AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630OWLW,
  AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020OWLW,
];

export const BOTTOM_HUNG_APPLICATIONS = [
  Application.SINGLE_FLAP,
  Application.DOUBLE_FLAP_SADDLE_ROOF_BOTTOM_HUNG_BOTTOM_HUNG,
  Application.DOUBLE_FLAP_PENT_ROOF_BOTTOM_TOP_HUNG,
  Application.DOUBLE_FLAP_BARREL_ROOF,
  Application.BOTTOM_HUNG,
];
export const TOP_HUNG_APPLICATIONS = [
  Application.TOP_HUNG,
  Application.SINGLE_FLAP_TOP_HUNG,
];
export const DOUBLE_FLAP_APPLICATIONS = [
  Application.DOUBLE_FLAP_SADDLE_ROOF_BOTTOM_HUNG_BOTTOM_HUNG,
  Application.DOUBLE_FLAP_PENT_ROOF_BOTTOM_TOP_HUNG,
  Application.DOUBLE_FLAP_BARREL_ROOF,
  Application.DOUBLE_FLAP_PENT_ROOF_SIDE_HUNG_SIDE_HUNG,
  Application.DOUBLE_FLAP_SADDLE_ROOF_SIDE_HUNG_SIDE_HUNG,
];

export const SIDE_HUNG_APPLICATIONS = [
  Application.SIDE_HUNG,
  Application.DOUBLE_FLAP_PENT_ROOF_SIDE_HUNG_SIDE_HUNG,
  Application.DOUBLE_FLAP_SADDLE_ROOF_SIDE_HUNG_SIDE_HUNG,
];
export const APPLICATIONS_BY_OPENING_TYPE: {
  [key in OpeningType | string]: Application[];
} = {
  [OpeningType.FENSTER_OEFFNUNGSART_KIPP]: BOTTOM_HUNG_APPLICATIONS,
  [OpeningType.FENSTER_OEFFNUNGSART_KLAPP]: TOP_HUNG_APPLICATIONS,
  [OpeningType.FENSTER_OEFFNUNGSART_DREH]: SIDE_HUNG_APPLICATIONS,
};

export enum RevealStructure {
  CIRCUMFERENTIAL = 'CIRCUMFERENTIAL',
  DIFFERENT = 'DIFFERENT',
}

export enum FillType {
  GLASS_FILL = 'GLASS_FILL',
  SANDWICH_FILL = 'SANDWICH_FILL',
}

export enum RevealDepth {
  NO_REVEAL = 'NO_REVEAL',
  WITH_REVEAL = 'WITH_REVEAL',
}

export type ParameterEnum = RevealDepth | FillType;

export enum NrwgTestTypes {
  UNTESTED_NO_CERT = 'UNTESTED_NO_CERT',
  TESTED_NO_CERT = 'TESTED_NO_CERT',
  TESTED_AND_CERT = 'TESTED_AND_CERT',
}

export enum PositionTypes {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

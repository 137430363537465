import React, { FC, useState } from 'react';
import SearchField from '../../elements/SearchField';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import {
  containingInSearchString,
  seriesNameAndOpeningTypeFits,
} from '../general/helpers';
import { EXISTING_DRIVE_SERIES_ROOF, UiConstants } from '../constants';
import Pagination from '../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { SeriesENMaxStrokeRoof } from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import _ from 'lodash';
import '../facade/MaxStrokesDataView.scss';
import { InputFieldNumber } from '../../elements/InputField';
import {
  changeMaxStrokesRoof,
  updateEditedSeriesENMaxStrokesRoof,
} from '../../redux/admin/adminRoofActions';
import { AnyAction } from 'redux';
import { DriveSeries } from '../../redux/constants';

const RoofMaxStrokesDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const allDriveSeries = useSelector<AdminState, DriveSeries[]>(
    s => s.adminFacade.driveSeries,
  );
  const editedSeriesEnMaxStrokesRoof = useSelector<
    AdminState,
    SeriesENMaxStrokeRoof[] | undefined
  >(state => state.adminRoof.editedSeriesENMaxStrokesRoof);

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  function searchByMultipleSubstrings(): { driveSeries: string }[] {
    const splittedSearchString = searchString.split(/\s+/);

    return EXISTING_DRIVE_SERIES_ROOF.filter(driveSeries =>
      containingInSearchString(driveSeries, splittedSearchString, [
        driveSeries.driveSeries,
      ]),
    );
  }

  function getCurrentTableContent(): { driveSeries: string }[] {
    return searchByMultipleSubstrings().slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function getMaxStrokeValue(
    art: string,
    voltage: string,
    openingType: string,
  ): number | null {
    const entry = editedSeriesEnMaxStrokesRoof?.filter(
      stroke =>
        seriesNameAndOpeningTypeFits(stroke, openingType, art) &&
        stroke.voltage === voltage,
    )[0];

    if (!entry) {
      return 0;
    }
    return entry.maxOpenStroke!;
  }

  function setMaxStrokeValue(
    art: string,
    voltage: string,
    openingType: string,
    newValue: number | null,
  ): void {
    if (editedSeriesEnMaxStrokesRoof) {
      const newState: SeriesENMaxStrokeRoof[] = _.cloneDeep(
        editedSeriesEnMaxStrokesRoof,
      );
      const indexToUpdate = _.findIndex(
        newState,
        stroke =>
          seriesNameAndOpeningTypeFits(stroke, openingType, art) &&
          stroke.voltage === voltage,
      );
      // newState[indexToUpdate].maxOpenStroke = newValue!;
      if (indexToUpdate === -1) {
        const driveSeries = allDriveSeries.find(
          series => series.name === art.toUpperCase(),
        )!;
        newState.push({
          driveSeries: driveSeries,
          voltage: voltage,
          openingType: openingType,
          maxOpenStroke: newValue!,
        });
      } else {
        newState[indexToUpdate].maxOpenStroke = newValue!;
      }

      dispatch(updateEditedSeriesENMaxStrokesRoof(newState));
    }
  }

  return (
    <>
      <div className="sub-header">
        <div className="sub-header__title"> Antriebsarten</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Antriebsart suchen..."
          small={true}
        />
        <button
          className="sub-header__button"
          onClick={() =>
            dispatch(changeMaxStrokesRoof(editedSeriesEnMaxStrokesRoof!))
          }
        >
          {UiConstants.SAVE}
        </button>
      </div>

      <div className="max-stroke-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Antriebsart</th>
            <th>Kipp 24V</th>
            <th>Klapp/Senkklapp 24V</th>
            <th>Dreh 24V</th>
            <th>Kipp 230V</th>
            <th>Klapp/Senkklapp 230V</th>
            <th>Dreh 230V</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map((driveSeries, index) => (
              <TableRow key={index}>
                <td>{driveSeries.driveSeries}</td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_24V',
                      'FENSTER_OEFFNUNGSART_KIPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_24V',
                        'FENSTER_OEFFNUNGSART_KIPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_24V',
                      'FENSTER_OEFFNUNGSART_KLAPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_24V',
                        'FENSTER_OEFFNUNGSART_KLAPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_24V',
                      'FENSTER_OEFFNUNGSART_DREH',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_24V',
                        'FENSTER_OEFFNUNGSART_DREH',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_230V',
                      'FENSTER_OEFFNUNGSART_KIPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_230V',
                        'FENSTER_OEFFNUNGSART_KIPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_230V',
                      'FENSTER_OEFFNUNGSART_KLAPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_230V',
                        'FENSTER_OEFFNUNGSART_KLAPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      'ANTRIEB_SPANNUNG_230V',
                      'FENSTER_OEFFNUNGSART_DREH',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        'ANTRIEB_SPANNUNG_230V',
                        'FENSTER_OEFFNUNGSART_DREH',
                        newValue,
                      )
                    }
                  />
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchByMultipleSubstrings().length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

export default RoofMaxStrokesDataView;
